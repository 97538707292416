import { useCallback } from "react";
import useFetch from "./useFetch";
import useFilters from "./useFilters";
import { ElaborationService } from "../services/elaborations/elaborationService";
import { ElaborationsApiResponse } from "../type/elaboration-type";

const useElaborations = () => {

    const { filters } = useFilters({}, [], 1, 1000);

    const [elaborations] = useFetch(useCallback(async () => {
        const response = await (new ElaborationService()).getElaborations();
        return response.getResponseData() as ElaborationsApiResponse;
    }, []));

    const getElaborations = () => {
        const elaborationsNames: any = [];
        elaborations?.elaborations.map((elaboration: any) => {
            elaborationsNames.push({ value: elaboration.id, label: elaboration.name });
        });
        return elaborationsNames;
    };

    return { elaborations, getElaborations };
}

export default useElaborations;