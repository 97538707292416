import React, { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { DashboardService } from '../../../services/dashboard/DashboardService';
import { useDashboard } from '../provider/DashboardProvider';
import { CardActions, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';
import CustomDonutGraph from '../../../components/graphs/CustomDonutGraph';

interface IngredientsListsProps {
    most?: boolean;
}

const IngredientsLists: React.FC<IngredientsListsProps> = ({ most }) => {

    const { filters } = useDashboard();

    const [changeList, setChangeList] = useState(false);
    const [series, setSeries] = useState<{ series: number[], labels: string[] }>({ series: [], labels: [] });

    const [ingredients] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.data_number) return;
        try {
            const service = changeList
                ? (most
                    ? (new DashboardService).getMostSoldIngredients(filters)
                    : (new DashboardService).getLeastSoldIngredients(filters))
                : (most
                    ? (new DashboardService).getMostUsedIngredients(filters)
                    : (new DashboardService).getLeastUsedIngredients(filters));
            const response = await service;
            return response.getResponseData();
        } catch (error) {
            console.error(error);
        }
    }, [filters, changeList]));

    useEffect(() => {
        if (ingredients) {
            setSeries({
                series: Object.values(ingredients)?.map((item: any) => item.count),
                labels: Object.values(ingredients)?.map((item: any) => item.ingredient_name)
            });
        }
    }, [ingredients]);

    if (!ingredients) return null;

    return (
        <>
            <CardHeader className="p-0 d-flex">
                <CardTitle tag="h4" className="h5">
                    Ingredientes{most ? ' más ' : ' menos '} {changeList ? 'vendidos' : 'usados'}
                </CardTitle>
                <CardActions className="d-flex">
                    <Button
                        color="primary" isLight
                        onClick={() => setChangeList((prevOrder: any) => !prevOrder)}
                        title={`Listar ${changeList ? 'usados' : 'vendidos'}`}
                    >
                        <Icon icon="SwapHoriz" size='lg' />
                    </Button>
                </CardActions>
            </CardHeader>

            <CustomDonutGraph series={series.series} labels={series.labels} />
        </>
    );
};

export default IngredientsLists;