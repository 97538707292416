import React, { useCallback, useState } from 'react';
import { toast } from "react-toastify";
import { CardFooter, CardTitle } from "../../../components/bootstrap/Card";
import Modal, { ModalHeader, ModalBody } from "../../../components/bootstrap/Modal";
import { PointOfSale } from "../../../components/icon/material-icons";
import Button from "../../../components/bootstrap/Button";
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import Spinner from '../../../components/bootstrap/Spinner';
import { EditSaleFieldsModel, SaleDish } from '../../../type/sales-type';
import useFetch from '../../../hooks/useFetch';
import { DishService } from '../../../services/dishes/dishService';
import { DishesApiResponse } from '../../../type/dish-type';
import CreateSaleDishes from './CreateSaleDishes';
import { SalesService } from '../../../services/sales/salesService';
import useHandleErrors from '../../../hooks/useHandleErrors';

interface CreateSaleModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    refetch: any;
}

const schema = yup.object({
    tpvSaleId: yup.string().min(1, 'Demasido corto').max(50, 'Demasiado largo').required('El código TPV es obligatorio'),
    taxPercentage: yup.number().required('El porcentaje de impuestos es obligatorio').min(0, 'El porcentaje mínimo es 0').max(100, 'El porcentaje máximo es 100'),
    saleDate: yup.date().required('La fecha de la venta es obligatoria'),
    company: yup.string(),
});

const CreateSaleModal: React.FC<CreateSaleModalProps> = ({ isOpen, setIsOpen, refetch }) => {

    const { handleErrors } = useHandleErrors();

    const [isLoading, setIsLoading] = useState(false);
    const [saleDishes, setSaleDishes] = useState<SaleDish[]>([]);

    const [dishesData] = useFetch(useCallback(async () => {
        const response = await (new DishService()).getDishes();
        return response.getResponseData() as DishesApiResponse;
    }, []));

    /**
     * Function to handle the update of a sale.
     * 
     * @param {EditSaleFieldsModel} values - Values to update the sale.
     */
    const handleCreateSale = async (values: EditSaleFieldsModel) => {
        setIsLoading(true);
        try {
            let response = (await (new SalesService).createSale(values)).getResponseData();
            if (response.success) {
                toast.success('Venta creada correctamente');
                refetch();
                setIsOpen(false);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar la venta');
        } finally {
            setIsLoading(false);
        }
    };

    /** 
     * Function to generate a random sale id.
     * 
     * @returns {string} Random sale id.
     */
    const generateRandomSaleId = () => {
        return Math.random().toString(36).substring(2, 15).toUpperCase() + Math.random().toString(36).substring(2, 15).toUpperCase();
    };

    const formik = useFormik<EditSaleFieldsModel>({
        initialValues: {
            saleId: '',
            tpvSaleId: generateRandomSaleId(),
            taxPercentage: 10,
            saleDate: moment().format('YYYY-MM-DD'),
            saleDishes: [],
            company: '',
        },
        validationSchema: schema,
        onSubmit: (values) => {
            values.saleDishes = saleDishes;
            if (formikDishes.values.dishId !== '' && formikDishes.values.quantity !== 0 && formikDishes.values.price !== 0) {
                values.saleDishes.push(formikDishes.values);
            }
            handleCreateSale(values);
        }
    });

    const formikDishes = useFormik<SaleDish>({
        initialValues: {
            id: '',
            dishId: '',
            quantity: 1,
            price: 0,
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.dishId) errors.dishId = 'El plato es obligatorio';
            if (values.price < 0) errors.price = 'El precio mínimo es 0€';
            return errors;
        },
        onSubmit: (values) => {
            setSaleDishes([...saleDishes, values]);
            formikDishes.resetForm();
        },
    });

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} size='md' titleId='Nueva venta'>
            <ModalHeader setIsOpen={setIsOpen} className='ms-2 p-4 gap-4'>
                <PointOfSale fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                <CardTitle className="fs-3">Crear Venta</CardTitle>
            </ModalHeader>
            <hr className="mt-0" />
            <ModalBody className='px-4'>
                <form onSubmit={formik.handleSubmit} autoComplete='off'>
                    <CreateSaleDishes formik={formikDishes} dishesData={dishesData} dishesList={saleDishes} setDishesList={setSaleDishes} />

                    <CardFooter className="d-flex justify-content-center">
                        <Button type="submit" size='lg' color='primary'>
                            {isLoading ? <Spinner isSmall /> : 'Crear'}
                        </Button>
                    </CardFooter>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default CreateSaleModal;