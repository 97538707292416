import React, { useEffect } from 'react';
import { CardBody, CardTitle } from '../../../components/bootstrap/Card';
import { FixNumber } from '../../../utils/fixNumber';

interface DishInfoProps {
    data: any;
}

const DishInfo: React.FC<DishInfoProps> = ({ data }) => {

    const [mergedData, setMergedData] = React.useState<any[]>([]);

    useEffect(() => {
        if (data) {
            const newIngredients = data?.dishHasIngredient?.map((i: any) => {
                return {
                    id: i.ingredient.id,
                    name: i.ingredient.name,
                    quantity: i.quantity,
                    measureUnit: i.measureUnit
                }
            });
            const newElaborations = data?.dishHasElaboration?.map((e: any) => {
                return {
                    id: e.elaborations.id,
                    name: e.elaborations.name,
                    quantity: e.quantity,
                    measureUnit: e.measureUnit
                }
            });
            setMergedData(newIngredients.concat(newElaborations));
        }
    }, [data]);

    return (
        <>
            <CardTitle>Información</CardTitle>

            <CardBody className='px-0 pt-5'>
                <div className="row border rounded mx-3 p-3 fs-5 g-3">
                    <h5 className="mt-2">Precios:</h5>
                    <span className="col-md-12">Último precio: <span className='py-1 px-2 bg-warning rounded'>{FixNumber(data?.lastPrice, 2)} €</span></span>
                    <span className="col-md-12">Precio medio: <span className='py-1 px-2 bg-warning rounded'>{FixNumber(data?.averagePrice, 2)} €</span></span>
                    <span className="col-md-12">P.M. ponderado: <span className='py-1 px-2 bg-warning rounded'>{FixNumber(data?.weightedAveragePrice, 2)} €</span></span>
                </div>

                <div className="row border rounded mx-3 p-3 fs-5 g-3 mt-4">
                    <h5 className="mt-2">General:</h5>
                    <span className='col-md-12'>Código TPV: {data?.tpvCode}</span>
                    <span className="col-md-12">Unidad de medida: {data?.measureUnit}</span>
                </div>

                <div className="row border rounded mx-3 p-3 fs-5 g-3 mt-4">
                    <h5 className="mt-2">Ingredientes ({mergedData?.length}):</h5>
                    <ul className="fs-5 ps-4">
                        {mergedData?.map((i: any) => (
                            <li key={i.id}>
                                <span>{i.name}: <span className='text-muted'>{i.quantity} {i.measureUnit}</span></span>
                            </li>
                        ))}
                    </ul>
                </div>
            </CardBody>
        </>
    );
};

export default DishInfo;