import { FC, Fragment, useState } from "react"
import { FilterOptions } from "../../../../hooks/useFilters"
import useCompanies from "../../../../hooks/useCompanies"
import Icon from "../../../../components/icon/Icon"
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput"
import Button from "../../../../components/bootstrap/Button"
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../../components/bootstrap/OffCanvas"
import { userIsSuperAdmin } from "../../../../utils/userIsSuperAdmin"
import FormGroup from "../../../../components/bootstrap/forms/FormGroup"
import Select from "../../../../components/bootstrap/forms/Select"

interface ElaborationsFiltersProps {
    filters: FilterOptions
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
}

const ElaborationsFilters: FC<ElaborationsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getCompanyList } = useCompanies();

    const [filterMenu, setFilterMenu] = useState(false);

    const handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };
    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_array || ''} />

            {/* <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='dishFilters'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='dishFilters'>Filtros de Elaboraciones</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        {userIsSuperAdmin() &&
                            <FormGroup label='Organización:' className="col-md-12">
                                <Select
                                    id='company'
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })}
                                    value={filters.filter_filters?.company || ''}
                                    placeholder='Elegir organización ...'
                                    ariaLabel='Default select example'
                                />
                            </FormGroup>
                        }
                    </div>
                </OffCanvasBody>
            </OffCanvas> */}
        </Fragment>
    )
}

export default ElaborationsFilters;