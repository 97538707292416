import { Fragment, useCallback, useContext } from "react";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { ElaborationsApiResponse } from "../../../type/elaboration-type";
import { ElaborationService } from "../../../services/elaborations/elaborationService";
import { toast } from "react-toastify";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import moment from "moment";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import { CustomTable } from "../../../components/table/CustomTable";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { userIsSuperAdmin } from "../../../utils/userIsSuperAdmin";
import ErrorMessage from "../../../components/ErrorMessage";
import ElaborationsFilters from "./elaborations-options/ElaborationsFilters";
import { elaborationMenu } from "../../../menu";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import { FixNumber } from "../../../utils/fixNumber";

const ElaborationList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();
    const elaborationService = new ElaborationService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await elaborationService.getElaborations(filters);
        return response.getResponseData() as ElaborationsApiResponse;
    }, [filters]));

    /**
     * Delete an elaboration with the given ID.
     *
     * @param {string} id - The ID of the elaboration to be deleted.
     */
    const deleteElaboration = async (id: string) => {
        let response = (await elaborationService.deleteElaboration(id)).getResponseData();
        if (response.success) {
            toast.success("Elaboración eliminada correctamente");
            updateFilters({ ...filters });
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardTitle>Listado de Elaboraciones</CardTitle>
                        {userCan("create", "elaborations") &&
                            <>
                                <SubheaderSeparator />
                                <Button
                                    color="light" icon="Add" isLight
                                    onClick={() => { navigate("create") }}
                                >
                                    Añadir Elaboración
                                </Button>
                            </>
                        }
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <ElaborationsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={false}>
                    {error && <ErrorMessage />}

                    {(data && data.elaborations)
                        ? (
                            <CustomTable
                                data={data?.elaborations ? data.elaborations : null}
                                pagination={true}
                                paginationData={{
                                    pageSize: filters.limit,
                                    currentPage: filters.page,
                                    pageCount: (data as ElaborationsApiResponse) ? data.lastPage : 1,
                                    totalCount: data.totalRegisters,
                                    handlePagination: updatePage,
                                    handlePerPage: updatePageSize,
                                }}
                                defaultLimit={filters.limit || 50}
                                defaultOrder={filters.filter_order || undefined}
                                className={"table table-hover"}
                                columns={[
                                    {
                                        name: "Nombre",
                                        keyValue: "name",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <Link to={`${elaborationMenu.elaborations.path}/${element.id}/view`} style={{ textDecoration: 'none' }}>
                                                    <div className="d-flex justify-content-center text-center fw-bold">
                                                        {element.name}
                                                    </div>
                                                </Link>
                                            );
                                        },
                                    },
                                    {
                                        name: "Descripción",
                                        keyValue: "description",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                    },
                                    {
                                        name: "Cantidad",
                                        keyValue: "processing_quantity",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {(element.processingQuantity)} {element.measureUnit}
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Precio medio (P.M)",
                                        keyValue: "average_price",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {FixNumber(element.averagePrice)} €
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "P.M ponderado",
                                        keyValue: "weighted_average_price",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {FixNumber(element.weightedAveragePrice)} €
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Último precio",
                                        keyValue: "last_price",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {FixNumber(element.lastPrice)} €
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Usuario",
                                        keyValue: "user",
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {`${element.user?.name} ${element.user?.lastName || ''}` ?? "N/A"}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Organización",
                                        keyValue: "company",
                                        isVisible: userIsSuperAdmin(),
                                        sortable: false,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <span>
                                                    {element?.company?.name ?? "N/A"}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        name: "Fecha de creación",
                                        keyValue: "created_at",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <div className={"text-center"}>
                                                    <span className={"text-muted"}>
                                                        {moment(element.createdAt.date).format("DD-MM-YYYY") ?? "N/A"}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
                                ]}
                                actions={[
                                    {
                                        title: "Editar",
                                        buttonType: "icon",
                                        iconColor: "text-info",
                                        iconPath: "/media/icons/duotune/general/gen055.svg",
                                        additionalClasses: "text-primary",
                                        description: "Editar elaboración",
                                        hide: () => !userCan('edit', 'elaborations'),
                                        callback: (item: any) => { navigate(`${item.id}/edit`) },
                                    },
                                    {
                                        title: "Eliminar",
                                        buttonType: "icon",
                                        iconColor: "text-danger",
                                        iconPath: "/media/icons/duotune/general/gen027.svg",
                                        additionalClasses: "text-danger",
                                        description: "Eliminar elaboración",
                                        hide: () => !userCan('delete', 'elaborations'),
                                        callback: (item: any) => {
                                            handleConfirmationAlert({
                                                title: "Eliminar elaboración",
                                                text: "¿Estás seguro de que deseas eliminar la elaboración?",
                                                icon: "warning",
                                                onConfirm: () => { deleteElaboration(item.id) },
                                            });
                                        },
                                    },
                                ]}
                            />
                        )
                        : !error && <Loader />
                    }
                </Card>
            </Page>
        </Fragment>
    )
}

export default ElaborationList;