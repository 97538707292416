import classNames from "classnames";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import Card, { CardBody } from "../../components/bootstrap/Card";
import useDarkMode from "../../hooks/useDarkMode";
import Page from "../../layout/Page/Page";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";

interface LoginContainerProps {
	children: ReactNode;
}

export const LoginContainer: FC<LoginContainerProps> = ({ children }) => {

	const { darkModeStatus } = useDarkMode();

	return (
		<PageWrapper
			title={'Login'}
			className='bg-dark'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card /* className='shadow-3d-dark' */ data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none fw-bold display-2 justify-content-center d-flex',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<img src='/logo.png' height={150} alt="Logo de Onakasita" className="logo" />
									</Link>
								</div>

								<div className='text-center h4 text-muted mb-5'>Panel de Administración</div>

								{children}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	)
}