import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { PrivilegeContext } from '../../components/priviledge/PriviledgeProvider';
import useCompanies from '../../hooks/useCompanies';
import Button from '../../components/bootstrap/Button';
import { CardBody, CardTitle, CardFooter } from '../../components/bootstrap/Card';
import Spinner from '../../components/bootstrap/Spinner';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Select from '../../components/bootstrap/forms/Select';
import SearchableSelect from '../../components/SearchableSelect';
import useFetch from '../../hooks/useFetch';
import { SupplierService } from '../../services/suppliers/suppliersService';
import { SuppliersApiResponse } from '../../type/supplier-type';
import { DeliveryNote, DeliveryNoteLine } from '../../type/deliveryNote-type';
import DeliveryNoteLines from './components/DeliveryNoteLines';
import useFilters from '../../hooks/useFilters';
import { toast } from 'react-toastify';
import useIngredientSuppliers from '../../hooks/useIngredientSuppliers';
import classNames from 'classnames';

interface DeliveryNoteFormProps {
    isLoading: boolean;
    submit: Function;
    data?: any;
}

interface DNFilters {
    supplier: string;
}

const initialFilters: DNFilters = {
    supplier: '',
};

const schema = yup.object({
    supplierId: yup.string().required('El proveedor es obligatorio'),
    deliveryNoteNumber: yup.string().required('El número de albarán es obligatorio').max(50, 'No puede tener más de 50 caracteres'),
    deliveryNoteDate: yup.string().required('La fecha es obligatoria'),
    comments: yup.string(),
    company: yup.string(),
});

const DeliveryNoteForm: FC<DeliveryNoteFormProps> = ({ isLoading, submit, data }) => {

    const { userCan } = useContext(PrivilegeContext);
    const { getCompanyList } = useCompanies();
    const { getIngredientSuppliersList } = useIngredientSuppliers();
    const mode = data ? 'Editar' : 'Crear';

    const { filters, updateFilters } = useFilters(initialFilters, [], 1, 1000);

    const [deliveryNoteLinesList, setDeliveryNoteLinesList] = useState<any>([]);
    const [defaultSupplier, setDefaultSupplier] = useState<any>(null);

    const [suppliersData] = useFetch(useCallback(async () => {
        const response = await (new SupplierService()).getSuppliers();
        return response.getResponseData() as SuppliersApiResponse;
    }, []));

    const _handleAddLine = (values: any) => {
        if (deliveryNoteLinesList.map((ingredient: any) => ingredient.ingredientId).includes(values.ingredientId)) {
            toast.error('El ingrediente ya existe');
            return;
        }
        if (values.quantity < 0) {
            toast.error('La cantidad mínima es 0');
            return;
        }
        setDeliveryNoteLinesList([...deliveryNoteLinesList, values]);
        formikLines.resetForm();
        formikLines.setFieldValue('discount', formik.values.discount); // Keep discount value in line form
    };

    const formik = useFormik({
        initialValues: {
            id: data?.id || '',
            supplierId: data?.supplierId || '',
            deliveryNoteNumber: data?.deliveryNoteNumber || '',
            deliveryNoteDate: data?.deliveryNoteDate || '',
            deliveryNoteLines: data?.deliveryNotelines || [],
            comments: data?.comments || '',
            company: data?.company || '',
            discount: 0,
        },
        validationSchema: schema,
        onSubmit: (values) => {
            values.deliveryNoteLines = deliveryNoteLinesList;
            if (formikLines.values.ingredientId !== '' && formikLines.values.quantity !== 0 && formikLines.values.price !== 0) {
                values.deliveryNoteLines.push(formikLines.values);
            }
            submit(values);
        },
    });

    const formikLines = useFormik<DeliveryNoteLine>({
        initialValues: {
            id: '',
            ingredientId: '',
            quantity: 0,
            hasUnits: false,
            measureUnit: '',
            discount: formik.values.discount || 0,
            price: 0,
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.ingredientId) errors.ingredientId = 'El ingrediente es obligatorio';
            if (!values.quantity) errors.quantity = 'La cantidad es obligatoria';
            if (!values.price) errors.price = 'El precio es obligatorio';
            return errors;
        },
        onSubmit: (values) => { _handleAddLine(values) },
    });

    const verifyClass = (inputFieldID: keyof DeliveryNote) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof DeliveryNote) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    useEffect(() => {
        if (data?.supplierId !== '' && suppliersData) {
            setDefaultSupplier({ value: data?.supplierId, label: suppliersData?.suppliers?.find((category: any) => category.id === data?.supplierId)?.name });
        }
    }, [data?.supplierId, suppliersData]);

    useEffect(() => {
        if (data?.deliveryNoteLines) {
            setDeliveryNoteLinesList(data.deliveryNoteLines);
        }
    }, [data]);

    useEffect(() => {
        if (formik.values.supplierId !== '') {
            formikLines.resetForm();
            updateFilters({ supplier: formik.values.supplierId });
        }
    }, [formik.values.supplierId]);
    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody className='row g-3 p-4'>
                    <CardTitle>Datos</CardTitle>
                    {userCan('admin_company', 'companies') && (
                        <FormGroup label='Organización' className='col-md-3'>
                            <Select id='company' onChange={formik.handleChange} className={verifyClass('company')}
                                value={formik.values.company || ''} ariaLabel='Default select example'
                                placeholder='Elegir organización ...'
                                list={getCompanyList()}
                            />
                            {showErrors('company')}
                        </FormGroup>
                    )}

                    <FormGroup requiredInputLabel label='Proveedor' className='col-md-3' key={defaultSupplier?.value}>
                        <SearchableSelect
                            isSearchable
                            name={'supplierId'}
                            defaultValue={defaultSupplier}
                            options={getIngredientSuppliersList()}
                            onChange={(e: any) => {
                                setDefaultSupplier(e);
                                formik.setFieldValue('supplierId', e?.value)
                            }}
                            placeholder="un proveedor "
                        />
                        {showErrors('supplierId')}
                    </FormGroup>

                    <FormGroup requiredInputLabel label='Número de albarán' className='col-md-3'>
                        <Input id='deliveryNoteNumber' required onChange={formik.handleChange} value={formik.values.deliveryNoteNumber || ''}
                            onBlur={formik.handleBlur} className={verifyClass('deliveryNoteNumber')} />
                        {showErrors('deliveryNoteNumber')}
                    </FormGroup>

                    <FormGroup requiredInputLabel label='Fecha' className='col-md-2'>
                        <Input type='date' id='deliveryNoteDate' required onChange={formik.handleChange} value={formik.values.deliveryNoteDate || ''}
                            onBlur={formik.handleBlur} className={verifyClass('deliveryNoteDate')} />
                        {showErrors('deliveryNoteDate')}
                    </FormGroup>

                    <FormGroup label='Descuento (%)' className={classNames('col-md-2', { 'd-none': mode === 'Editar' })}>
                        <Input
                            type='number' id='discount'
                            value={formik.values.discount || ''}
                            onChange={(e: any) => {
                                formik.handleChange(e);
                                formikLines.setFieldValue('discount', e.target.value);
                            }}
                            onBlur={formik.handleBlur}
                            min={0} max={100}
                            className='50'
                        />
                    </FormGroup>

                    {formik.values.supplierId !== '' && <DeliveryNoteLines formik={formikLines} filters={filters} deliveryNoteLinesList={deliveryNoteLinesList} setDeliveryNoteLinesList={setDeliveryNoteLinesList} />}

                    <FormGroup label='Comentarios' className='col-md-12'>
                        <textarea
                            id='comments' onChange={formik.handleChange} value={formik.values.comments} rows={3}
                            onBlur={formik.handleBlur} className={'form-control ' + verifyClass('comments')}
                        />
                        {showErrors('comments')}
                    </FormGroup>
                </CardBody>
                <CardFooter className="d-flex justify-content-center">
                    <Button type="submit" size='lg' color='primary'>
                        {isLoading ? <Spinner isSmall /> : `${mode} Albarán`}
                    </Button>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default DeliveryNoteForm;