import { FC } from "react";
import Input from "../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../components/SearchableSelect";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { CardTitle } from "../../../components/bootstrap/Card";
import FormLabel from "../../../components/bootstrap/forms/FormLabel";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import { SaleDish } from "../../../type/sales-type";

interface EditSaleDishesListProps {
    formik: any;
    dishesData: any;
    dishesList: any;
    setDishesList: any;
}

const EditSaleDishes: FC<EditSaleDishesListProps> = ({ formik, dishesData, dishesList, setDishesList }) => {

    const newDishesList = [...dishesList];

    const getDishes = () => {
        const dishesNames: any = [];
        dishesData?.dishes.map((d: any) => {
            dishesNames.push({
                value: d.id,
                label: d.name,
            });
        });
        return dishesNames;
    };

    const verifyClass = (inputFieldID: keyof SaleDish) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof SaleDish) => {
        const error = formik.touched[inputFieldID] && formik.errors[inputFieldID];
        return (error ? <div className="invalid-feedback">{String(error)}</div> : <></>);
    };

    return (
        <>
            <CardTitle className="mt-5 mb-4">Elementos del ticket</CardTitle>

            {dishesList.length > 0 && (
                <div className="rounded p-4" style={{ border: '1px solid lightgrey' }}>

                    <div className="row">
                        <FormLabel label='Plato' cols={5} required />
                        <FormLabel label='Precio' cols={2} required />
                        <FormLabel label='' cols={2} />
                    </div>

                    {dishesList.map((dish: any, index: number) => {
                        const dishSelected = getDishes().filter((i: any) => i.value === dish.dishId);
                        const defaultValue = dishSelected[0] ? { value: dishSelected[0].value, label: dishSelected[0].label } : null;

                        return (
                            <div className="row mb-3" key={dish.id}>
                                <div className="col-md-5" key={defaultValue?.value}>
                                    <SearchableSelect
                                        isSearchable
                                        name={dish.dishId + index.toString()}
                                        defaultValue={defaultValue}
                                        options={getDishes()}
                                        onChange={(e: any) => {
                                            newDishesList[index].dishId = e.value;
                                            setDishesList(newDishesList);
                                        }}
                                        placeholder={"un plato "}
                                    />
                                </div>

                                <div className="col-md-2">
                                    <Input
                                        id={dish.price.toString() + index.toString()}
                                        step={0.001}
                                        value={dish.price}
                                        onChange={(e: any) => {
                                            if (e.target.value < 0) return;
                                            if (isNaN(e.target.value) && e.target.value !== '.') return;
                                            formik.handleChange(e);
                                            newDishesList[index].price = e.target.value;
                                            setDishesList(newDishesList);
                                        }}
                                        min={0}
                                    />
                                </div>

                                <div className="col-md-2 d-flex justify-content-end">
                                    <Button
                                        color='dark'
                                        isLight
                                        icon='Delete'
                                        title="Eliminar elemento"
                                        onClick={() => {
                                            handleConfirmationAlert({
                                                title: "Eliminar elemento",
                                                text: "¿Estás seguro de que deseas eliminar este elemento del ticket?",
                                                icon: "warning",
                                                onConfirm: () => {
                                                    const newDishesListData = dishesList.filter((l: any) => l !== dishesList[index]);
                                                    setDishesList(newDishesListData);
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}

            <div className="row mt-2">
                <FormGroup label="Plato" requiredInputLabel className="col-md-5">
                    <SearchableSelect
                        isSearchable
                        name="dishId"
                        defaultValue={null}
                        options={getDishes()}
                        onChange={(e: any) => { formik.setFieldValue('dishId', e.value) }}
                        placeholder={"un plato "}
                    />
                    {showErrors('dishId')}
                </FormGroup>

                <FormGroup label="Precio (€)" requiredInputLabel className="col-md-2">
                    <Input
                        id="price"
                        step={0.001}
                        value={formik.values.price}
                        onChange={(e: any) => {
                            if (e.target.value < 0) return;
                            if (isNaN(e.target.value) && e.target.value !== '.') return;
                            formik.handleChange(e)
                        }}
                        min={0}
                        className={verifyClass('price')}
                    />
                    {showErrors('price')}
                </FormGroup>

                <div className="col-md-12 d-flex justify-content-center mt-4">
                    <Button type="submit" icon="Add" color='primary' isLight title="Añadir plato" onClick={formik.handleSubmit} />
                </div>
            </div>
        </>
    )
}

export default EditSaleDishes;