import { useCallback, useContext, useEffect, useState } from 'react';
import { CompanyService } from '../services/companies/companyService';
import { CompanyApiResponse } from '../type/company-type';
import { userIsSuperAdmin } from '../utils/userIsSuperAdmin';
import { PrivilegeContext } from '../components/priviledge/PriviledgeProvider';
import { UserService } from '../services/users/userService';

//------------------------------------------------------------
/**
* 
* EN: Hook to manage fetching and transformation logic for company data.
* ES: Hook para gestionar la lógica de obtención y transformación de datos de empresa.
*
* @returns 
*/
//------------------------------------------------------------
const useCompanies = () => {

  const { userCan } = useContext(PrivilegeContext);
  const [companies, setCompanies] = useState<any[]>([]);

  const fetchCompanies = useCallback(async () => {
    if (!userCan('list', 'companies') && !userIsSuperAdmin()) return;

    try {
      const companyService = new CompanyService();
      const response = await companyService.listCompanies();
      const fetchedCompaniesData = response.getResponseData() as CompanyApiResponse;

      if (fetchedCompaniesData?.data) {
        const companies = fetchedCompaniesData.data.companies.map((company: { id: any; name: any; }) => ({
          value: company.id,
          label: company.name
        }));
        setCompanies(companies);
      }
    } catch (error) {
      console.log('Error fetching companies:', error);
    }
  }, []);

  const getUserCompany = useCallback(async (userId: string) => {
    if (!userCan('get', 'user') && !userIsSuperAdmin()) return;

    try {
      const service = new UserService();
      const response = await service.getUserById(userId);

      if (response.getResponseData()?.data) {
        return response.getResponseData()?.data?.userRoles[0]?.company?.id
      }
    } catch (error) {
      console.log('Error fetching user company:', error);
    }
  }, []);


  useEffect(() => {
    fetchCompanies();
  }, []);

  const getCompanyList = () => {
    return companies;
  }

  return { fetchCompanies, getCompanyList, getUserCompany };
}

export default useCompanies;