import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { ingredientMenu } from "../../../menu";
import { Inventory2 } from "../../../components/icon/material-icons";
import { IngredientService } from "../../../services/ingredients/ingredientService";
import IngredientForm from "../IngredientForm";
import Card, { CardBody, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import useHandleErrors from "../../../hooks/useHandleErrors";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";

const IngredientCreate: FC = () => {

	const { handleErrors } = useHandleErrors();
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const handleCreation = async (values: any) => {
		try {
			setLoading(true);
			let response = await (await (new IngredientService()).createIngredient(values)).getResponseData();
			if (response.success) {
				toast.success(response.message);
				navigate(ingredientMenu.ingredients.path, { replace: true })
			} else {
				handleErrors(response);
			}
		} catch (error: any) {
			toast.error('Error al crear el ingrediente');
		} finally {
			setLoading(false);
		}
	};

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<Card className="col-md-9 m-auto">
					<CardHeader borderSize={1} className="d-flex justify-content-start">
						<Inventory2 fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
						<CardTitle className="fs-3 mb-0 ms-3">Crear Ingrediente</CardTitle>
					</CardHeader>
					<CardBody>
						<IngredientForm submit={handleCreation} isLoading={loading} />
					</CardBody>
				</Card>
			</Page>
		</Fragment>
	)
}

export default IngredientCreate;