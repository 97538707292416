import { FC, useCallback, useState } from "react";
import Input from "../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../components/SearchableSelect";
import Button from "../../../components/bootstrap/Button";
import { ElaborationIngredient, ElaborationsApiResponse } from "../../../type/elaboration-type";
import Select from "../../../components/bootstrap/forms/Select";
import { IngredientService } from "../../../services/ingredients/ingredientService";
import { Ingredient } from "../../../type/ingredient-type";
import useFetch from "../../../hooks/useFetch";
import { CardTitle } from "../../../components/bootstrap/Card";
import FormLabel from "../../../components/bootstrap/forms/FormLabel";
import { toast } from "react-toastify";
import useIngredients from "../../../hooks/useIngredients";
import useElaborations from "../../../hooks/useElaborations";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import { ElaborationService } from "../../../services/elaborations/elaborationService";

interface ElaborationIngredientsProps {
    ingredientsList: any;
    setIngredientsList: any;
    formik: any;
}

const ElaborationIngredients: FC<ElaborationIngredientsProps> = ({ ingredientsList, setIngredientsList, formik }) => {

    const { getIngredients, getIngredientsLimited, getMeasurementUnits } = useIngredients();
    const { getElaborations } = useElaborations();

    const newIngredientsList = [...ingredientsList];
    const [isElaboration, setIsElaboration] = useState(false);

    const [elaborations] = useFetch(useCallback(async () => {
        const response = await (new ElaborationService()).getElaborations();
        return response.getResponseData() as ElaborationsApiResponse;
    }, []));

    const [ingredients] = useFetch(useCallback(async () => {
        const response = await (new IngredientService()).getIngredients();
        return response.getResponseData() as Ingredient;
    }, []));

    const _handleDeleteIngredient = (newIngredientsListData: any) => {
        setIngredientsList(newIngredientsListData);
    };

    const getIngMeasureUnit = (ingredientId: string) => {
        const ingredient = ingredients?.ingredients.find((ingredient: any) => ingredient.id === ingredientId);
        formik.setFieldValue('unitMeasureType', ingredient?.unitMeasureType);
    };

    const getElabMeasureUnit = (elaborationId: string) => {
        const elaboration = elaborations?.elaborations.find((elaboration: any) => elaboration.id === elaborationId);
        switch (elaboration?.measureUnit) {
            case 'kg':
            case 'g':
            case 'mg':
                formik.setFieldValue('unitMeasureType', 'mass');
                break;
            case 'L':
            case 'mL':
                formik.setFieldValue('unitMeasureType', 'volume');
                break;
            case 'u':
                formik.setFieldValue('unitMeasureType', 'unit');
                break;
            default:
                return;
        }
    };

    const verifyClass = (inputFieldID: keyof ElaborationIngredient) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof ElaborationIngredient) => {
        const error = formik.touched[inputFieldID] && formik.errors[inputFieldID];
        return (error ? <div className="invalid-feedback">{String(error)}</div> : <></>);
    };

    return (
        <>
            <CardTitle className="mt-5 mb-4">Ingredientes</CardTitle>

            {ingredientsList.length > 0 && (
                <div className="row">
                    <FormLabel label='Ingrediente' cols={5} required />
                    <FormLabel label='Cantidad' cols={3} required />
                    <FormLabel label='Unidad de medida' cols={3} required />
                </div>
            )}

            {ingredientsList.map((ingredient: any, index: number) => {
                const list = ingredient.type === 'elaboration' ? getElaborations() : getIngredients();
                const id = ingredient.type === 'elaboration' ? ingredient.childElaborationId : ingredient.ingredientId;
                const ingredientSelected = list.filter((i: any) => i.value === id);
                const defaultValue = ingredientSelected[0] ? { value: ingredientSelected[0].value, label: ingredientSelected[0].label } : null;

                return (
                    <div className="row mb-3" key={ingredient.childElaborationId + '' + ingredient.ingredientId}>
                        <div className="col-md-5" key={defaultValue?.value}>
                            <SearchableSelect
                                isSearchable
                                name={ingredient.name}
                                defaultValue={defaultValue}
                                options={list}
                                onChange={(e: any) => {
                                    if (!ingredientsList.map((ingredient: any) => ingredient.ingredientId).includes(e.value) && !ingredientsList.map((ingredient: any) => ingredient.childElaborationId).includes(e.value)) {
                                        newIngredientsList[index].name = e.value;
                                        setIngredientsList(newIngredientsList);
                                    } else {
                                        toast.error('El ingrediente ya existe');
                                    }
                                }}
                                placeholder="un ingrediente "
                            />
                        </div>

                        <div className="col-md-3">
                            <Input
                                id={ingredient.quantity.toString()}
                                step={0.001}
                                value={ingredient.quantity}
                                onChange={(e: any) => {
                                    if (e.target.value < 0) return;
                                    newIngredientsList[index].quantity = e.target.value;
                                    setIngredientsList(newIngredientsList);
                                }}
                                min={0}
                                className="w-75"
                            />
                        </div>

                        <div className="col-md-3">
                            <Select
                                id={ingredient.measureUnit}
                                value={ingredient.measureUnit}
                                onChange={(e: any) => {
                                    newIngredientsList[index].measureUnit = e.target.value;
                                    setIngredientsList(newIngredientsList);
                                }}
                                list={getMeasurementUnits(ingredient.unitMeasureType)}
                                ariaLabel="Unidad de medida"
                            />
                        </div>

                        <div className="col-md-1 d-flex justify-content-end p-0">
                            <Button
                                color='dark'
                                isLight
                                icon='Delete'
                                title="Eliminar ingrediente"
                                onClick={() => {
                                    const newIngredientsListData = ingredientsList.filter((ingredient: any) => ingredient !== ingredientsList[index]);
                                    _handleDeleteIngredient(newIngredientsListData);
                                }}
                            />
                        </div>
                    </div>
                )
            })}

            <form onSubmit={formik.handleSubmit} autoComplete="off" className="row mt-2">
                <FormGroup label="Tipo" requiredInputLabel className={"col-md-3"}>
                    <Select
                        id="type"
                        name="type"
                        defaultValue={undefined}
                        value={formik.values.type}
                        onChange={(e: any) => {
                            formik.setFieldValue('type', e.target.value);
                            setIsElaboration(e.target.value === 'elaboration');
                        }}
                        list={[{ value: '', label: 'Selecciona uno ...' }, { value: 'ingredient', label: 'Ingrediente' }, { value: 'elaboration', label: 'Elaboración' }]}
                        ariaLabel="Tipo de ingrediente"
                    />
                </FormGroup>

                {formik.values.type != '' && (
                    <>
                        <FormGroup label={isElaboration ? 'Elaboración' : 'Ingrediente'} requiredInputLabel className="col-md-4">
                            <SearchableSelect
                                isSearchable
                                name="ingredientId"
                                defaultValue={null}
                                options={isElaboration ? getElaborations() : getIngredients()}
                                onChange={(e: any) => {
                                    isElaboration
                                        ? formik.setFieldValue('childElaborationId', e.value) && getElabMeasureUnit(e.value)
                                        : formik.setFieldValue('ingredientId', e.value) && getIngMeasureUnit(e.value);
                                }}
                                placeholder={isElaboration ? "una elaboración " : "un ingrediente "}
                            />
                            {showErrors('ingredientId')}
                        </FormGroup>

                        <FormGroup label="Cantidad" requiredInputLabel className="col-md-2">
                            <Input
                                id="quantity"
                                step={0.001}
                                value={formik.values.quantity}
                                onChange={formik.handleChange}
                                min={0}
                                className={verifyClass('quantity')}
                            />
                            {showErrors('quantity')}
                        </FormGroup>

                        <FormGroup label="Unidad de medida" requiredInputLabel className="col-md-3">
                            <Select
                                id="measureUnit"
                                name="measureUnit"
                                value={formik.values.measureUnit}
                                onChange={formik.handleChange}
                                list={getMeasurementUnits(formik.values.unitMeasureType)}
                                ariaLabel="Unidad de medida"
                                className={verifyClass('measureUnit')}
                            />
                            {showErrors('measureUnit')}
                        </FormGroup>

                        <div className="col-md-12 d-flex justify-content-center mt-4">
                            <Button type="submit" icon="Add" color='primary' isLight title={`Añadir ${isElaboration ? 'elaboración' : 'ingrediente'}`} onClick={formik.handleSubmit} />
                        </div>
                    </>
                )}
            </form>
        </>
    )
}

export default ElaborationIngredients;