import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import Input from '../../../../components/bootstrap/forms/Input'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Select from '../../../../components/bootstrap/forms/Select'
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput'
import Icon from '../../../../components/icon/Icon'

interface ICompanyFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: number, hardReset: boolean) => void
    filters: any
}

const CompanyFilters: FC<ICompanyFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const [filterMenu, setFilterMenu] = useState(false)

    const handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}></CustomSearchInput>

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'> Filtros de Organizaciones</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <FormGroup id='filter1' label='Estado de Organización' className='col-md-12'>
                            <Select onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })} value={filters.filter_filters?.active?.toString() || '3'}
                                id='active-select' ariaLabel='Default select example' placeholder='Estado...'
                                list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activa', text: 'Activa' }, { value: 0, label: 'Desactivada', text: 'Desactivada' }]}
                            />
                        </FormGroup>
                    </div>

                    <div className='col-12'>
                        <Button className='mt-4' color="storybook" isLight onClick={() => resetFilters(50, true)}>
                            Resetear
                        </Button>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default CompanyFilters;