import React, { useCallback, useEffect, useState } from 'react';
import { CardTitle } from '../../../components/bootstrap/Card';
import useFetch from '../../../hooks/useFetch';
import { IngredientService } from '../../../services/ingredients/ingredientService';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useFilters from '../../../hooks/useFilters';
import ReactApexChart from 'react-apexcharts';
import IngredientCostsFilters from './IngredientCostsFilters';
import IngredientLastPricesList from './IngredientLastPricesList';
import SpinnerLogo from '../../../components/bootstrap/SpinnerLogo';
import { FixNumber } from '../../../utils/fixNumber';
import useToast from "@chakra-ui/toast";

interface IngredientChartsProps {
    data: any;
}

interface IChartsFilters {
    id: string;
    date: {
        from: string;
        to: string;
        mode: string;
    }
    supplier_id: string[];
    range: number;
    show_by: string;
}

const initialFilters: IChartsFilters = {
    id: "",
    date: {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        mode: "days",
    },
    supplier_id: [],
    range: 10,
    show_by: 'weightedAveragePrice'
}

const IngredientCharts: React.FC<IngredientChartsProps> = ({ data }) => {

    const { id = "", tabURL = "" } = useParams<{ id: string, tabURL: string }>();
    const { filters, updateFilters, updatePage, updatePageSize, updateFilterOrder, resetFilters } = useFilters(initialFilters, [], 1, 1000);
    const ingredientService = new IngredientService();

    const [chartSuppliersArray, setChartSuppliersArray] = useState<any[]>([]);
    const [chartCostsArray, setChartCostsArray] = useState<any[]>([]);
    const [chartDatesArray, setChartDatesArray] = useState<any[]>([]);

    const [graphData, loadingGraph, graphError] = useFetch(useCallback(async () => {
        if (filters.filter_filters?.id === "") return;
        const response = await ingredientService.getIngredientCostsGraph(filters);
        return response.getResponseData();
    }, [filters]));

    const [lastPurchasesData, loadingLastPurchases] = useFetch(useCallback(async () => {
        if (filters.filter_filters?.id === "") return;
        const response = await ingredientService.getLastPurchases(id as string, filters.filter_filters?.range, filters);
        return response.getResponseData();
    }, [filters]));

    useEffect(() => {
        if (id) updateFilters({ id });
    }, [id]);

    useEffect(() => {
        if (graphData) {
            // Group data by supplier
            const dataGrouped = graphData?.reduce((acc: any, curr: any) => {
                const { supplier, ...rest } = curr;
                if (!acc[supplier.id]) {
                    acc[supplier.id] = [];
                }
                acc[supplier.id].push(rest);
                return acc;
            }, {});
            const groupedArray = Object.entries(dataGrouped).map(([key, value]) => {
                return { supplier: key, data: value } as { supplier: string, data: any[] };
            });

            //  Get the suppliers to show in the graph
            const suppliersArray = graphData?.map((item: any) => { return { name: item.supplier.name, id: item.supplier.id } });
            const uniqueSuppliersArray = suppliersArray.filter((item: any, index: number) => suppliersArray.findIndex((supplier: any) => supplier.id === item.id) === index);
            setChartSuppliersArray(uniqueSuppliersArray);

            // Get the dates to show in the graph
            setChartDatesArray(groupedArray[0]?.data?.map((item: any) => item.date?.date));

            // Get the costs to show in the graph grouped by supplier and ordered by date
            const costsArray = groupedArray.map((item: any) => {
                return {
                    id: item.supplier,
                    data: item.data.map((cost: any) => cost.cost)
                }
            });
            setChartCostsArray(costsArray);
        }
    }, [graphData]);

    return (
        <>
            <SpinnerLogo loading={loadingGraph || loadingLastPurchases} />

            {graphData && (
                <div className='row g-3 ps-5'>
                    <div className='col-md-12 d-flex justify-content-between align-items-center'>
                        <CardTitle>Gráfica de los precios</CardTitle>
                        <IngredientCostsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                    </div>

                    <div className='col-md-12' style={{ width: '100%' }} key={chartCostsArray[0]}>
                        <ReactApexChart
                            options={{
                                chart: {
                                    id: data?.name,
                                    type: 'area',
                                    toolbar: {
                                        show: true,
                                        autoSelected: 'zoom',
                                    },
                                    animations: {
                                        enabled: true,
                                    }
                                },
                                legend: {
                                    show: true,
                                    customLegendItems: chartSuppliersArray.map((supplier: any) => {
                                        return supplier.name;
                                    }) || [],
                                    position: 'top'
                                },
                                dataLabels: {
                                    enabled: false,
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 3
                                },
                                xaxis: {
                                    type: 'datetime',
                                    categories: chartDatesArray || [],
                                },
                                yaxis: {
                                    tooltip: {
                                        enabled: true
                                    },
                                    labels: {
                                        formatter: function (value: any) {
                                            return FixNumber(value, 4) + ` €`
                                        }
                                    }
                                },
                                tooltip: {
                                    x: {
                                        format: 'dd/MM/yy',
                                    },
                                    y: {
                                        title: {
                                            // use chartSuppliersArray to get the supplier name
                                            formatter: function () {
                                                return chartSuppliersArray.map((supplier: any) => {
                                                    return supplier.names;
                                                    // put an undefined value to avoid the title name
                                                }).join('');
                                            }
                                        },
                                        formatter: function (value: any) {
                                            return FixNumber(value, 4) + ` €`
                                        }
                                    },
                                },
                                noData: {
                                    text: 'No hay precios para este ingrediente'
                                }
                            }}
                            series={chartCostsArray}
                            type="area"
                            height={450}
                        />
                    </div>
                </div>
            )}

            {lastPurchasesData && (
                <div className='row mt-5 ps-5'>
                    <IngredientLastPricesList
                        data={lastPurchasesData}
                        filters={filters} updatePage={updatePage} updatePageSize={updatePageSize} updateFilterOrder={updateFilterOrder}
                    />
                </div>
            )}
        </>
    );
};

export default IngredientCharts;