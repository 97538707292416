import { useCallback } from "react";
import { IngredientService } from "../services/ingredients/ingredientService";
import { IngredientsApiResponse } from "../type/ingredient-type";
import useFetch from "./useFetch";
import useFilters from "./useFilters";
import { MEASUREMENT_UNITS } from "../utils/ingredients/ingredientsUtils";

const useIngredients = () => {

    const { filters, updatePageSize } = useFilters({}, [], 1, 9999999);

    const [ingredients] = useFetch(useCallback(async () => {
        const response = await (new IngredientService()).getIngredients(filters);
        return response.getResponseData() as IngredientsApiResponse;
    }, [filters]));

    const fetchEntity = useCallback(async () => {
        const response = await (new IngredientService()).getIngredients(filters);
        const responseData = response.getResponseData();

        const ingredientsNames = responseData?.data?.ingredients?.map((ingredient: any) => ({
            value: ingredient.id,
            label: ingredient.name,
        }));

        return ingredientsNames;
    }, [filters]);

    const [ingredientsListed] = useFetch(fetchEntity);

    const updateFilterLimit = (newLimit: number) => {
        updatePageSize({ value: newLimit });
    };

    const getIngredients = () => {
        const ingredientsNames: any = [];
        ingredients?.ingredients.map((ingredient: any) => {
            ingredientsNames.push({ value: ingredient.id, label: ingredient.name });
        });
        return ingredientsNames;
    };

    const getIngredientsLimited = (limit?: number) => {
        updatePageSize({ value: limit || 50 });
        return ingredientsListed;
    };

    const getMeasurementUnits = (unitType: string | undefined) => {
        if (!unitType) return MEASUREMENT_UNITS;
        if (unitType === 'mass') return MEASUREMENT_UNITS.filter(u => u.value === 'kg' || u.value === 'g' || u.value === 'mg');
        if (unitType === 'volume') return MEASUREMENT_UNITS.filter(u => u.value === 'L' || u.value === 'mL');
        if (unitType === 'unit') return MEASUREMENT_UNITS.filter(u => u.value === 'u');
    };

    return { ingredients, getIngredients, getIngredientsLimited, getMeasurementUnits, updateFilterLimit };
}

export default useIngredients;