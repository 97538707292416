import { RestServiceConnection } from '../restServiceConnection';

const COMPANIES_ENDPOINT = '/companies';

export class CompanyService extends RestServiceConnection {

    createCompany = async (company: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/create',
            data: company,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    editCompany = async (company: FormData, hasImg: boolean) => {
        const companyObj: any = {};
        company.forEach((value, key) => (companyObj[key] = value));
        companyObj['logo'] = null;

        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/edit',
            data: hasImg ? company : companyObj,
            headers: {
                "Content-Type": hasImg ? "application/x-www-form-urlencoded" : "application/json"
            }
        }, true);
        return this;
    }

    listCompanies = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/list',
            data: filters,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getCompanyById = async (companyId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/get',
            data: { companyId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleCompantStatus = async (companyId: string, active: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/toggle',
            data: { companyId, active },
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteCompany = async (companyId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/delete',
            data: { companyId }
        }, true);
        return this;
    }

    editOrganizationImg = async (id: string, img: File) => {
        const formData = new FormData();
        formData.append('companyId', id);
        formData.append('logo', img);

        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/add-image',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }, true);
        return this;
    }

    deleteOrganizationImg = async (companyId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: COMPANIES_ENDPOINT + '/delete-image',
            data: { companyId }
        }, true);
        return this;
    }

}