import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { FixNumber } from '../../utils/fixNumber';

interface CustomAreaGraphProps {
    chartId: string;
    series: any[];
    height?: number;
}

const CustomAreaGraph: React.FC<CustomAreaGraphProps> = ({ chartId, series, height }) => {
    return (
        <ReactApexChart
            options={{
                chart: {
                    id: chartId,
                    type: 'area',
                    toolbar: {
                        show: true,
                        autoSelected: 'zoom',
                    },
                    animations: {
                        enabled: true,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                colors: ['#000000'],
                stroke: {
                    curve: 'smooth',
                    width: 3
                },
                xaxis: {
                    type: 'datetime',
                    categories: series[0].data.map((item: any) => item.x),
                },
                yaxis: {
                    tooltip: {
                        enabled: true
                    },
                    labels: {
                        formatter: function (value: any) {
                            return `${FixNumber(value, 2)} €`;
                        }
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy',
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return '';
                            }
                        },
                    },
                },
                noData: { text: 'No hay datos para mostrar' }
            }}
            series={series}
            type="area"
            height={height || 300}
        />
    );
};

export default CustomAreaGraph;