import { FC, useState } from "react";
import Input from "../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../components/SearchableSelect";
import Button from "../../../components/bootstrap/Button";
import Select from "../../../components/bootstrap/forms/Select";
import { CardTitle } from "../../../components/bootstrap/Card";
import FormLabel from "../../../components/bootstrap/forms/FormLabel";
import { DishIngredient } from "../../../type/dish-type";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import useIngredients from "../../../hooks/useIngredients";
import useElaborations from "../../../hooks/useElaborations";
import CustomSearchSelect from "../../../components/customSearchSelect";

interface DishIngredientsProps {
    ingredientsList: any;
    setIngredientsList: any;
    formik: any;
}

const DishIngredients: FC<DishIngredientsProps> = ({ ingredientsList, setIngredientsList, formik }) => {

    const { ingredients, getIngredients, getMeasurementUnits, updateFilterLimit } = useIngredients();
    const { elaborations, getElaborations } = useElaborations();

    const newIngredientsList = [...ingredientsList];
    const [isElaboration, setIsElaboration] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [options, setOptions] = useState<any>([]);

    const getIngMeasureUnit = (ingredientId: string) => {
        const ingredient = ingredients?.ingredients.find((ingredient: any) => ingredient.id === ingredientId);
        formik.setFieldValue('unitMeasureType', ingredient?.unitMeasureType);
        formik.setFieldValue('measureUnit', ingredient?.purchaseUnitMeasure);
    };

    const getElabMeasureUnit = (elaborationId: string) => {
        const elaboration = elaborations?.elaborations.find((elaboration: any) => elaboration.id === elaborationId);
        switch (elaboration?.measureUnit) {
            case 'kg':
            case 'g':
            case 'mg':
                formik.setFieldValue('unitMeasureType', 'mass');
                formik.setFieldValue('measureUnit', elaboration?.measureUnit);
                break;
            case 'L':
            case 'mL':
                formik.setFieldValue('unitMeasureType', 'volume');
                formik.setFieldValue('measureUnit', elaboration?.measureUnit);
                break;
            case 'u':
                formik.setFieldValue('unitMeasureType', 'unit');
                formik.setFieldValue('measureUnit', elaboration?.measureUnit);
                break;
            default:
                return;
        }
    };

    // Rellenar los campos de tipo select con los datos de la API
    /*     const getOptions = async (id?: string) => {
            updateFilterLimit(9999999);
            const options = isElaboration ? getElaborations() : getIngredients();
            options.sort((a: any, b: any) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            });
    
            setOptions(options);
        }; */

    const verifyClass = (inputFieldID: keyof DishIngredient) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof DishIngredient) => {
        const error = formik.touched[inputFieldID] && formik.errors[inputFieldID];
        return (error ? <div className="invalid-feedback">{String(error)}</div> : <></>);
    };

    return (
        <>
            <CardTitle className="mt-5 mb-4">Ingredientes</CardTitle>

            {ingredientsList.length > 0 && (
                <div className="row">
                    <FormLabel label='Ingrediente' cols={4} required />
                    <FormLabel label='Cantidad' cols={2} required />
                    <FormLabel label='Unidad medida' cols={2} required />
                </div>
            )}

            {ingredientsList.map((ingredient: any, index: number) => {
                const list = ingredient.type === 'elaboration' ? getElaborations() : getIngredients();
                const ingredientSelected = list.filter((ingredientName: any) => ingredientName.value === ingredient.typeId);
                const defaultValue = ingredientSelected[0] ? { value: ingredientSelected[0].value, label: ingredientSelected[0].label } : null;

                return (
                    <div className="row mb-3" key={ingredient.typeId}>
                        <div className="col-md-4" key={defaultValue?.value}>
                            <SearchableSelect
                                isSearchable
                                name={ingredient.name}
                                defaultValue={defaultValue}
                                options={list}
                                onChange={(e: any) => {
                                    if (!ingredientsList.map((ingredient: any) => ingredient.typeId).includes(e.value)) {
                                        newIngredientsList[index].name = e.value;
                                        setIngredientsList(newIngredientsList);
                                    }
                                }}
                                placeholder={ingredient.type === 'elaboration' ? "una elaboración " : "un ingrediente "}
                            />
                        </div>

                        <div className="col-md-2">
                            <Input
                                id={ingredient.quantity.toString() + index.toString()}
                                step={0.001}
                                value={ingredient.quantity}
                                onChange={(e: any) => {
                                    if (e.target.value < 0) return;
                                    newIngredientsList[index].quantity = e.target.value;
                                    setIngredientsList(newIngredientsList);
                                }}
                                min={0}
                            />
                        </div>

                        <div className="col-md-2">
                            <Input
                                disabled
                                id={ingredient.measureUnit + index.toString()}
                                value={ingredient.measureUnit}
                                className="w-50"
                            /* onChange={(e: any) => {
                                newIngredientsList[index].measureUnit = e.target.value;
                                setIngredientsList(newIngredientsList);
                            }}
                             list={MEASUREMENT_UNITS}
                            ariaLabel="Unidad de medida" */
                            />
                        </div>

                        <div className="col-md-1 d-flex justify-content-end p-0">
                            <Button
                                color='dark'
                                isLight
                                icon='Delete'
                                title="Eliminar ingrediente"
                                onClick={() => {
                                    const newIngredientsListData = ingredientsList.filter((ingredient: any) => ingredient !== ingredientsList[index]);
                                    setIngredientsList(newIngredientsListData);
                                }}
                            />
                        </div>
                    </div>
                )
            })}

            <form onSubmit={formik.handleSubmit} autoComplete="off" className="row mt-2">
                <FormGroup label="Tipo" requiredInputLabel className={"col-md-3"}>
                    <Select
                        id="type"
                        name="type"
                        defaultValue={undefined}
                        value={formik.values.type}
                        onChange={(e: any) => {
                            formik.setFieldValue('type', e.target.value);
                            setIsElaboration(e.target.value === 'elaboration');
                        }}
                        list={[{ value: '', label: 'Selecciona uno ...' }, { value: 'ingredient', label: 'Ingrediente' }, { value: 'elaboration', label: 'Elaboración' }]}
                        ariaLabel="Tipo de ingrediente"
                    />
                </FormGroup>

                {formik.values.type != '' && (
                    <>
                        <FormGroup label={isElaboration ? 'Elaboración' : 'Ingrediente'} requiredInputLabel className="col-md-4">
                            {/* <CustomSearchSelect
                                id="typeId"
                                defaultValue={null}
                                options={isElaboration ? getElaborations() : getIngredients()}
                                onChange={(e: any) => {
                                    formik.setFieldValue('typeId', e.value);
                                    isElaboration ? getElabMeasureUnit(e.value) : getIngMeasureUnit(e.value);
                                }}
                            /> */}

                            <CustomSearchSelect
                                id='ingredient-select'
                                /* search={search}
                                getOptions={getOptions}
                                options={options} */
                                options={isElaboration ? getElaborations() : getIngredients()}
                                onInputChange={(e: any) => { setSearch(e) }}
                                onChangeSingle={(e: any) => {
                                    formik.setFieldValue('typeId', e.value);
                                    isElaboration ? getElabMeasureUnit(e.value) : getIngMeasureUnit(e.value);
                                }}
                                defaultValue={null}
                            />

                            {showErrors('typeId')}
                        </FormGroup>

                        <FormGroup label="Cantidad" requiredInputLabel className="col-md-2">
                            <Input
                                id="quantity"
                                step={0.001}
                                value={formik.values.quantity}
                                onChange={formik.handleChange}
                                min={0}
                                className={verifyClass('quantity')}
                            />
                            {showErrors('quantity')}
                        </FormGroup>

                        <FormGroup label="Unidad medida" requiredInputLabel className="col-md-2">
                            <Input
                                disabled
                                id="measureUnit"
                                name="measureUnit"
                                value={formik.values.measureUnit}
                                className={verifyClass('measureUnit')}
                            />
                            {showErrors('measureUnit')}
                        </FormGroup>

                        <div className="col-md-12 d-flex justify-content-center mt-4">
                            <Button type="submit" icon="Add" color='primary' isLight title={`Añadir ${isElaboration ? 'elaboración' : 'ingrediente'}`} onClick={formik.handleSubmit} />
                        </div>
                    </>
                )}
            </form>
        </>
    )
}

export default DishIngredients;