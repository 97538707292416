import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/bootstrap/Alert';
import { LoginService } from '../../../services/auth/loginService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { toast } from "react-toastify";
import { PrivilegeContext } from '../../../components/priviledge/PriviledgeProvider';
import { LoginResetPasswordContainer } from './ResetPasswordContainer';
import { LoginResetPasswordForm } from './ResetPasswordForm';

const ResetPassword: FC = () => {

  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);
  const { isAuthenticated, loading, error } = useSelector((state: RootState) => state.auth);

  if (isAuthenticated) {
    setTimeout(() => {
      if (userCan('show_dashboard', 'dashboard')) {
        navigate('/dashboard', { replace: true })
      } else if (userCan('show_agent_dashboard', 'dashboard')) {
        navigate('/agent-statistics', { replace: true })
      } else {
        navigate('/roles', { replace: true })
      }
    }, 100);
  }

  const handleResetPassword = async (userToken: string, password: string, password_confirmation: string) => {
    const response = await (await (new LoginService()).resetForgotPassword(userToken, password, password_confirmation)).getResponseData();

    if (!response.success) {
      toast.error(response.message);
      return;
    }

    if (response.success) {
      try {
        navigate('/login', { replace: true });
        toast.success('Contrasenã actualizada con exito.');
      } catch (error) {
        toast.error('Error updating password. Please try again later.');
        return;
      }
    }
  };

  return (
    <LoginResetPasswordContainer>
      {/* Alert for login errors taking api msg */}
      {error && <Alert color='warning' isLight icon='Warning'> {error} </Alert>}
      {/* Form section to wrapp all formik logic */}
      <LoginResetPasswordForm isLoading={loading} submit={handleResetPassword} errorsBool={error != null} />
    </LoginResetPasswordContainer>
  );
};

export default ResetPassword;