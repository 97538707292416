import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Dish, DishIngredient } from '../../type/dish-type';
import { useFormik } from 'formik';
import { PrivilegeContext } from '../../components/priviledge/PriviledgeProvider';
import useCompanies from '../../hooks/useCompanies';
import Button from '../../components/bootstrap/Button';
import { CardBody, CardTitle, CardFooter } from '../../components/bootstrap/Card';
import Spinner from '../../components/bootstrap/Spinner';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Select from '../../components/bootstrap/forms/Select';
import { MEASUREMENT_UNITS } from '../../utils/ingredients/ingredientsUtils';
import SearchableSelect from '../../components/SearchableSelect';
import useFetch from '../../hooks/useFetch';
import { DishService } from '../../services/dishes/dishService';
import { IngredientCategoriesApiResponse } from '../../type/ingredient-type';
import DishIngredients from './components/DishIngredients';
import { toast } from 'react-toastify';
import useFilters from '../../hooks/useFilters';
import useDishCategories from '../../hooks/useDishCategories';

interface DishFormProps {
    isLoading: boolean;
    submit: Function;
    data?: any;
}

const dishSchema = yup.object({
    dishCategoryId: yup.string().required('La categoría es obligatoria'),
    name: yup.string().min(1, 'Demasido corto').max(100, 'Demasiado largo').required('El nombre es obligatorio'),
    description: yup.string().min(1, 'Demasido corto').max(300, 'Demasiado largo'),
    tpvCode: yup.string().min(1, 'Demasido corto').max(50, 'Demasiado largo').required('El código TPV es obligatorio'),
    measureUnit: yup.string().required('La unidad de medida es obligatoria'),
    company: yup.string(),
});

const DishForm: FC<DishFormProps> = ({ isLoading, submit, data }) => {

    const { userCan } = useContext(PrivilegeContext);
    const { getCompanyList } = useCompanies();
    const { getDishesCategoriesList } = useDishCategories();
    const mode = data ? 'Editar' : 'Crear';

    const { filters } = useFilters({}, [], 1, 1000);

    const [ingredientsList, setIngredientsList] = useState<any>([]);
    const [defaultCategory, setDefaultCategory] = useState<any>(null);

    const [categoriesData] = useFetch(useCallback(async () => {
        const response = await (new DishService()).getDishCategories(filters);
        return response.getResponseData() as IngredientCategoriesApiResponse;
    }, [filters]));

    const _handleSubmit = (values: any) => {
        if (ingredientsList.map((ingredient: any) => ingredient.typeId).includes(values.typeId)) {
            toast.error('No puede haber ingredientes duplicados');
            return;
        }

        values.dishIngredients = ingredientsList;
        // If the ingredient is already in the list, do not add it
        if (ingredientsList.map((ingredient: any) => ingredient.typeId).includes(formikIngredients.values.typeId)) {
            toast.error('El ingrediente ya existe');
            return;
        }

        // If the ingredient is not in the list, add it
        if (formikIngredients.values.type !== '' && formikIngredients.values.typeId !== '') {
            values.dishIngredients.push(formikIngredients.values);
        }
        submit(values);
    };

    const _handleAddIngredient = (values: any) => {
        if (ingredientsList.map((ingredient: any) => ingredient.typeId).includes(values.typeId)) {
            toast.error('El ingrediente ya existe');
            return;
        }
        if (values.quantity < 0) {
            toast.error('La cantidad mínima es 0');
            return;
        }
        setIngredientsList([...ingredientsList, values]);
        formikIngredients.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            dishId: data?.dishId || '',
            dishCategoryId: data?.dishCategoryId || '',
            name: data?.name || '',
            description: data?.description || '',
            tpvCode: data?.tpvCode || '',
            measureUnit: data?.measureUnit || 'g',
            dishIngredients: data?.dishIngredients || [],
            company: data?.company || '',
        },
        validationSchema: dishSchema,
        onSubmit: (values) => { _handleSubmit(values) },
    });

    const formikIngredients = useFormik<DishIngredient>({
        initialValues: {
            id: '',
            type: '',
            typeId: '',
            quantity: 0,
            measureUnit: '',
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.typeId) {
                errors.typeId = 'El ingrediente/elaboración es obligatorio';
            }
            if (!values.quantity) {
                errors.quantity = 'La cantidad es obligatoria';
            }
            if (!values.measureUnit) {
                errors.measureUnit = 'La unidad de medida es obligatoria';
            }
            return errors;
        },
        onSubmit: (values) => { _handleAddIngredient(values) },
    });

    const verifyClass = (inputFieldID: keyof Dish) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof Dish) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    useEffect(() => {
        if (data?.dishCategoryId !== '' && categoriesData) {
            setDefaultCategory({ value: data?.dishCategoryId, label: categoriesData?.dishes?.find((category: any) => category.id === data?.dishCategoryId)?.name });
        }
    }, [data?.dishCategoryId, categoriesData]);

    useEffect(() => {
        if (data?.dishIngredients) {
            setIngredientsList(data.dishIngredients);
        }
    }, [data]);

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody isScrollable={false} className='row g-3 p-5'>
                    <CardTitle>Información general</CardTitle>
                    {userCan('admin_company', 'companies') && (
                        <FormGroup label='Organización' className='col-md-6'>
                            <Select id='company' onChange={formik.handleChange} className={verifyClass('company')}
                                value={formik.values.company || ''} ariaLabel='Default select example'
                                placeholder='Elegir organización ...'
                                list={getCompanyList()}
                            />
                            {showErrors('company')}
                        </FormGroup>
                    )}

                    <FormGroup requiredInputLabel label='Nombre' className='col-md-4'>
                        <Input id='name' required onChange={formik.handleChange} value={formik.values.name || ''}
                            onBlur={formik.handleBlur} className={verifyClass('name')} />
                        {showErrors('name')}
                    </FormGroup>

                    <FormGroup requiredInputLabel label='Código TPV' className='col-md-3'>
                        <Input id='tpvCode' required onChange={formik.handleChange} value={formik.values.tpvCode || ''}
                            onBlur={formik.handleBlur} className={verifyClass('tpvCode')} />
                        {showErrors('tpvCode')}
                    </FormGroup>

                    <FormGroup requiredInputLabel label='Unidad medida' className='col-md-2'>
                        <Select
                            id='measureUnit' onChange={formik.handleChange}
                            value={formik.values.measureUnit} list={MEASUREMENT_UNITS}
                            className={verifyClass('measureUnit')}
                            ariaLabel="measurement unit select" placeholder="Elegir ..."
                        />
                        {showErrors('measureUnit')}
                    </FormGroup>

                    <FormGroup requiredInputLabel label='Categoría' className='col-md-3' key={defaultCategory}>
                        <SearchableSelect
                            isSearchable
                            name={'dishCategoryId'}
                            defaultValue={defaultCategory}
                            options={getDishesCategoriesList()}
                            onChange={(e: any) => {
                                setDefaultCategory(e);
                                formik.setFieldValue('dishCategoryId', e.value)
                            }}
                            placeholder="una categoría "
                        />
                        {showErrors('dishCategoryId')}
                    </FormGroup>

                    <FormGroup label='Descripción' className='col-md-7'>
                        <textarea
                            id='description' onChange={formik.handleChange} value={formik.values.description} rows={3}
                            onBlur={formik.handleBlur} className={'form-control ' + verifyClass('description')}
                        />
                        {showErrors('description')}
                    </FormGroup>

                    <DishIngredients ingredientsList={ingredientsList} setIngredientsList={setIngredientsList} formik={formikIngredients} />
                </CardBody>

                <CardFooter className="d-flex justify-content-center">
                    <Button type="submit" size='lg' color='primary'>
                        {isLoading ? <Spinner isSmall /> : `${mode} Plato`}
                    </Button>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default DishForm;