import { FC, Fragment, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/companyService";
import { Company } from "../../../type/company-type";
import useFetch from "../../../hooks/useFetch";
import CompanyForm from "../CompanyForm";
import { toast } from "react-toastify";
import SpinnerLogo from "../../../components/bootstrap/SpinnerLogo";

const CompanyEdit: FC = () => {

	const navigate = useNavigate();
	const { id = '' } = useParams<{ id: string }>();

	const [data, loading] = useFetch(useCallback(async () => {
		const companyService = new CompanyService();
		const response = await companyService.getCompanyById(id as string);
		return response.getResponseData() as Company;
	}, [id]));

	const handleUpdate = async (values: any) => {
		const formData = new FormData();
		for (let value in values) {
			if (values[value]) {
				formData.append(value, values[value]);
			}
		}
		formData.append("company", id as string)
		let hasImg = formData.get("profile_img") ? true : false;

		try {
			const response = await (await (new CompanyService()).editCompany(formData, hasImg)).getResponseData();
			if (response.success) {
				navigate(-1);
				setTimeout(() => {
					toast.success('Organización editada correctamente');
				}, 100)
			} else {
				response.data.errors.forEach((error: any) => {
					toast.error(error.message);
				});
			}
		} catch (error: any) {
			toast.error(error.message || "Error al editar la organización");
		}
	};

	const getContent = () => {
		<SpinnerLogo loading={loading} />;

		if (data !== null) { return <CompanyForm isLoading={false} submit={handleUpdate} companyData={data} logo={data.logo} /> };
	};

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='primary' isLink icon='ArrowBack' onClick={() => navigate('/organizations', { replace: true })} />
					<SubheaderSeparator />
					<CardTitle>Editar Organización</CardTitle>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<Card stretch={true} className="col-md-8 m-auto">
					<>{getContent()}</>
				</Card>
			</Page>
		</Fragment>
	)
}

export default CompanyEdit;