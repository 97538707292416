import { Fragment, useState } from "react";
import Card, { CardHeader, CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import { adminMenu } from "../../../menu";
import { useNavigate } from "react-router-dom";
import { DishService } from "../../../services/dishes/dishService";
import { toast } from "react-toastify";
import DishForm from "../DishForm";
import { CreateDishFieldsModel } from "../../../type/dish-type";
import Button from "../../../components/bootstrap/Button";
import { SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { SetMeal } from "../../../components/icon/material-icons";
import useHandleErrors from "../../../hooks/useHandleErrors";

const CreateDish = () => {

    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreation = async (values: CreateDishFieldsModel) => {
        try {
            setLoading(true)
            let response = await (await (new DishService()).createDish(values)).getResponseData();
            if (response.success) {
                toast.success(response.message);
                navigate(adminMenu.dishes.path, { replace: true })
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al crear el plato');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Page container='fluid'>
                <Card className="col-md-8 m-auto">
                    <CardHeader borderSize={1} className="d-flex justify-content-start">
                        <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                        <SubheaderSeparator className="mx-3" />
                        <SetMeal fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                        <CardTitle className="fs-3 mb-0 ms-3">Crear Plato</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <DishForm submit={handleCreation} isLoading={loading} />
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    )
}

export default CreateDish;