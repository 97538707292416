import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { CompanyService } from "../../../services/companies/companyService";
import CompanyForm from "../CompanyForm";
import { toast } from "react-toastify";

const CompanyCreate: FC = () => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const handleCreation = async (values: any) => {
		setLoading(true);

		const formData = new FormData();

		for (let value in values) {
			if (values[value]) {
				formData.append(value, values[value]);
			}
		}

		try {
			const response = await (await (new CompanyService()).createCompany(formData)).getResponseData();
			if (response.success) {
				setLoading(false);
				navigate(-1);
				setTimeout(() => {
					toast.success('Organización creada');
				}, 100)
			} else {
				response.data.errors.forEach((error: any) => {
					toast.error(error.message);
				});
			}
		} catch (error: any) {
			toast.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='primary' isLink icon='ArrowBack' onClick={() => navigate('/organizations', { replace: true })} />
					<SubheaderSeparator />
					<CardTitle className="fs-4">Crear Organización</CardTitle>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<Card stretch={true} className="col-md-8 m-auto">
					<CompanyForm submit={handleCreation} isLoading={loading} />
				</Card>
			</Page>
		</Fragment>
	)
}

export default CompanyCreate;