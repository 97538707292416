import { FC, Fragment, useState } from "react"
import { FilterOptions } from "../../../../hooks/useFilters"
import useCompanies from "../../../../hooks/useCompanies"
import Button from "../../../../components/bootstrap/Button"
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput"
import Icon from "../../../../components/icon/Icon"
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../../components/bootstrap/OffCanvas"
import FormGroup from "../../../../components/bootstrap/forms/FormGroup"
import { userIsSuperAdmin } from "../../../../utils/userIsSuperAdmin"
import Select from "../../../../components/bootstrap/forms/Select"
import Input from "../../../../components/bootstrap/forms/Input"
import useDishesCategories from "../../../../hooks/useDishCategories"
import useDishIngredients from "../../../../hooks/useDishIngredients"
import CustomSearchSelect from "../../../../components/customSearchSelect"
import FormLabel from "../../../../components/bootstrap/forms/FormLabel"

interface IIngretientFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const DishesFilters: FC<IIngretientFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const { getDishesCategoriesList } = useDishesCategories();
    const { getDishIngredientsElaborationsList } = useDishIngredients();
    const { getCompanyList } = useCompanies();

    const [filterMenu, setFilterMenu] = useState(false);

    const handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''} />

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='dishFilters'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='dishFilters'>Filtros de Platos</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        {userIsSuperAdmin() &&
                            <FormGroup label='Organización:' className="col-md-12">
                                <Select
                                    id='company'
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })}
                                    value={filters.filter_filters?.company || ''}
                                    placeholder='Elegir organización ...'
                                    ariaLabel='Default select example'
                                />
                            </FormGroup>
                        }

                        <FormGroup id='filter1' label='Estado:'>
                            <Select
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                value={filters.filter_filters?.active?.toString() || 'true'}
                                id='active-select' ariaLabel='Default select example' placeholder='Estado...'
                                list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activo', text: 'Activo' }, { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                            />
                        </FormGroup>

                        <FormGroup label='Categoría:' className="col-md-12">
                            <Select
                                id='dish_category'
                                list={getDishesCategoriesList()}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ dish_category: e.target.value })}
                                value={filters.filter_filters?.dish_category || ''}
                                placeholder='Elegir categoría ...'
                                ariaLabel='Default select example'
                            />
                        </FormGroup>

                        <FormGroup label="Ingredientes:" className="col-md-12">
                            <CustomSearchSelect
                                isMulti
                                isClearable
                                id='user-select'
                                options={getDishIngredientsElaborationsList()}
                                onChange={(e: any) => {
                                    const ingredients = e.map((ingredient: any) => {
                                        return { type: ingredient.type, id: ingredient.value }
                                    });
                                    updateFilters({ ingredients: ingredients });
                                }}
                                defaultValue={getDishIngredientsElaborationsList().find((option: any) => option.value === filters.filter_filters?.ingredients)}
                            />
                        </FormGroup>

                        <div className="col-md-12">
                            <>
                                <div className="row d-flex justify-content-between">
                                    <FormLabel label="Rango de precios" className="col-md-12" />
                                    <FormGroup className=' col-md-5'>
                                        <Input
                                            type='number'
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({
                                                price_range: {
                                                    min: e.target.value,
                                                    max: filters.filter_filters?.price_range?.max ?? 0,
                                                }
                                            })}
                                            value={filters.filter_filters?.price_range?.min ?? ''}
                                            placeholder='Precio mínimo'
                                        />
                                    </FormGroup>

                                    <FormGroup className=' col-md-5'>
                                        <Input
                                            type='number'
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({
                                                price_range: {
                                                    max: e.target.value,
                                                    min: filters.filter_filters?.price_range?.min ?? 0,
                                                }
                                            })}
                                            value={filters.filter_filters?.price_range?.max ?? ''}
                                            placeholder='Precio máximo'
                                        />
                                    </FormGroup>
                                </div>
                            </>
                        </div>

                        <div className='col-12'>
                            <Button className='mt-4' color="storybook" isLight onClick={() => resetFilters(50)}>
                                Resetear
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}

export default DishesFilters;