import { FC, useEffect, useState } from "react";
import CustomSearchSelect from "../../../components/customSearchSelect";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import { toast } from "react-toastify";
import useIngredientSuppliers from "../../../hooks/useIngredientSuppliers";
import Checks from "../../../components/bootstrap/forms/Checks";
import classNames from "classnames";
import Select from "../../../components/bootstrap/forms/Select";
import useIngredients from "../../../hooks/useIngredients";
import FormLabel from "../../../components/bootstrap/forms/FormLabel";

interface IngredientSuppliersFormProps {
    suppliers: any;
    setSuppliers: any;
    supplierHasUnits: boolean;
    setSupplierHasUnits: (value: boolean) => void;
    supplierName: { value: string, label: string };
    setSupplierName: any;
    supplierCode: string;
    setSupplierCode: any;
    supplierQuantity: number;
    setSupplierQuantity: (value: number) => void;
    supplierUM: string;
    setSupplierUM: (value: string) => void;
    unitMeasureType: string | undefined;
}

const IngredientSuppliersForm: FC<IngredientSuppliersFormProps> = ({ suppliers, setSuppliers, supplierHasUnits, setSupplierHasUnits, supplierName, supplierCode, setSupplierName, setSupplierCode, supplierQuantity, setSupplierQuantity, supplierUM, setSupplierUM, unitMeasureType }) => {

    const { getMeasurementUnits } = useIngredients();
    const { getIngredientSuppliersList } = useIngredientSuppliers();

    const [supplierList, setSupplierList] = useState<any[]>([]);

    // Set supplier list when getIngredientSuppliersList changes (when a supplier is added or removed)
    useEffect(() => {
        setSupplierList(getIngredientSuppliersList());
    }, [getIngredientSuppliersList]);

    return (
        <div className="border rounded mt-5 p-3">
            <h5 className="mt-2">Proveedores:</h5>
            <div className="row mb-3">
                <FormGroup label='Nombre' className="col-md-4">
                    <CustomSearchSelect
                        id="supplierName"
                        isClearable
                        options={supplierList}
                        value={supplierName}
                        onChangeSingle={(value: any) => { setSupplierName(value) }}
                    />
                </FormGroup>

                <FormGroup label="Código" className="col-md-2">
                    <Input
                        id="supplierCode"
                        type="text"
                        value={supplierCode}
                        onChange={(e: any) => setSupplierCode(e.target.value)}
                    />
                </FormGroup>

                <FormGroup label="¿Unidades?" className="col-md-1">
                    <Checks
                        id='hasUnits'
                        type="switch"
                        checked={supplierHasUnits}
                        onChange={() => setSupplierHasUnits(!supplierHasUnits)}
                    />
                </FormGroup>

                <FormGroup label="1 unidad equivale a:" className={classNames("col-md-4", { 'd-none': !supplierHasUnits })}>
                    <>
                        <div className="row">
                            <div className="col-md-4">
                                <Input
                                    id="unitEquivalence"
                                    step={0.1}
                                    type="number"
                                    value={supplierQuantity}
                                    onChange={(e: any) => setSupplierQuantity(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <Select
                                    id='visualizationUnitMeasure'
                                    value={supplierUM}
                                    onChange={(e: any) => setSupplierUM(e.target.value)}
                                    list={getMeasurementUnits(unitMeasureType)}
                                    placeholder='Elegir ...' ariaLabel='Elegir Medida...'
                                />
                            </div>
                        </div>
                    </>
                </FormGroup>

                <div className="col-md-1 d-flex align-items-center">
                    <Button
                        icon="Add" isLink title="Añadir"
                        onClick={() => {
                            const hasRequiredFields = () => supplierName && supplierCode;

                            if (!hasRequiredFields()) {
                                toast.error('Debes seleccionar un proveedor y un código');
                                return;
                            }

                            if (supplierHasUnits && (!supplierQuantity || !supplierUM)) {
                                toast.error('Debes seleccionar una cantidad y una unidad de medida');
                                return;
                            }

                            const newSupplier = {
                                id: '',
                                code: supplierCode,
                                supplierId: supplierName.value,
                                supplierName: supplierName.label,
                                hasUnits: supplierHasUnits,
                                equivalentQuantity: supplierQuantity,
                                equivalentUM: supplierUM,
                            };

                            setSuppliers([...suppliers, newSupplier]);

                            // Remove supplier from list (if applicable)
                            if (supplierList.length > 0) {
                                const index = supplierList.findIndex(supplier => supplier.value === supplierName.value);
                                if (index !== -1) {
                                    const newSupplierList = [...supplierList];
                                    newSupplierList.splice(index, 1);
                                    setSupplierList(newSupplierList);
                                }
                            }

                            // Reset form
                            setSupplierName('');
                            setSupplierCode('');
                            setSupplierHasUnits(false);
                            setSupplierQuantity(0);
                            setSupplierUM('');

                            return;

                            /* if (supplierName && supplierCode && !supplierHasUnits) {
                                setSuppliers([...suppliers, { id: '', code: supplierCode, supplierId: supplierName.value, supplierName: supplierName.label, hasUnits: supplierHasUnits, equivalentQuantity: supplierQuantity, equivalentUM: supplierUM }]);

                                // Remove supplier from list
                                const newSupplierList = [...supplierList];
                                const index = newSupplierList.findIndex(supplier => supplier.value === supplierName.value);
                                newSupplierList.splice(index, 1);
                                setSupplierList(newSupplierList);

                                // Reset form
                                setSupplierName('');
                                setSupplierCode('');
                                setSupplierHasUnits(false);
                                setSupplierQuantity(0);
                                setSupplierUM('');
                                return;
                            }
                            
                            toast.error('Debes seleccionar un proveedor y un código'); */
                        }}
                    />
                </div>
            </div>

            <div style={{ borderTop: '1px solid lightgrey' }} /* className={classNames({ 'd-none': suppliers.length > 0 })} */>
                <div className="row mt-4">
                    <FormLabel label="Proveedor" className="col-md-4" />
                    <FormLabel label="Código" className="col-md-2" />
                    <FormLabel label="¿Unidades?" className="col-md-1 p-0" />
                    {/* <FormLabel label="1 unidad equivale a:" className="col-md-4" /> */}
                </div>

                {suppliers.map((s: any, index: number) => {
                    const defaultValue = { value: s.supplierId, label: s.supplierName };

                    return (
                        <div className="row mb-4" key={s.supplierId}>
                            <FormGroup className="col-md-4">
                                <CustomSearchSelect
                                    id={`suppliers[${index}].supplierId`}
                                    options={getIngredientSuppliersList()}
                                    defaultValue={defaultValue}
                                    onChangeSingle={(value: any) => {
                                        const newSuppliers = [...suppliers];
                                        newSuppliers[index].supplierId = value.value;
                                        setSuppliers(newSuppliers);
                                    }}
                                />
                            </FormGroup>

                            <FormGroup className="col-md-2">
                                <Input
                                    id={`suppliers[${index}].code`}
                                    type="text"
                                    value={s.code}
                                    onChange={(e: any) => {
                                        const newSuppliers = [...suppliers];
                                        newSuppliers[index].code = e.target.value;
                                        setSuppliers(newSuppliers);
                                    }}
                                />
                            </FormGroup>

                            {s.hasUnits ? (
                                <>
                                    <FormGroup className="col-md-1">
                                        <Checks
                                            id={`suppliers[${index}].hasUnits`}
                                            type="switch"
                                            checked={s.hasUnits}
                                            onChange={() => {
                                                const newSuppliers = [...suppliers];
                                                newSuppliers[index].hasUnits = !s.hasUnits;
                                                newSuppliers[index].equivalentQuantity = 0;
                                                newSuppliers[index].equivalentUM = '';
                                                setSuppliers(newSuppliers);
                                            }}
                                        />
                                    </FormGroup>

                                    <FormGroup className="col-md-4">
                                        <>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Input
                                                        id={`suppliers[${index}].equivalentQuantity`}
                                                        step={0.1}
                                                        type="number"
                                                        value={s.equivalentQuantity}
                                                        onChange={(e: any) => {
                                                            const newSuppliers = [...suppliers];
                                                            newSuppliers[index].equivalentQuantity = Number(e.target.value);
                                                            setSuppliers(newSuppliers);
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-md-6">
                                                    <Select
                                                        id={`suppliers[${index}].equivalentUM`}
                                                        value={s.equivalentUM}
                                                        onChange={(e: any) => {
                                                            const newSuppliers = [...suppliers];
                                                            newSuppliers[index].equivalentUM = e.target.value;
                                                            setSuppliers(newSuppliers);
                                                        }}
                                                        list={getMeasurementUnits(unitMeasureType)}
                                                        placeholder='Elegir unidad de medida ...' ariaLabel='Elegir Medida...'
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    </FormGroup>
                                </>
                            )
                                : (
                                    <FormGroup className="col-md-1">
                                        <Checks
                                            id={`suppliers[${index}].hasUnits`}
                                            type="switch"
                                            checked={s.hasUnits}
                                            onChange={() => {
                                                const newSuppliers = [...suppliers];
                                                newSuppliers[index].hasUnits = !s.hasUnits;
                                                newSuppliers[index].equivalentQuantity = 1;
                                                unitMeasureType === 'mass' && (newSuppliers[index].equivalentUM = 'g');
                                                unitMeasureType === 'volume' && (newSuppliers[index].equivalentUM = 'L');
                                                unitMeasureType === 'units' && (newSuppliers[index].equivalentUM = 'u');
                                                setSuppliers(newSuppliers);
                                            }}
                                        />
                                    </FormGroup>
                                )
                            }

                            <div className="col-md-1 d-flex align-items-center">
                                <Button
                                    icon="Delete" isLink title="Eliminar"
                                    className="col-md-1"
                                    onClick={() => {
                                        const newSuppliers = [...suppliers];
                                        newSuppliers.splice(index, 1);
                                        setSuppliers(newSuppliers);

                                        // Add supplier to list
                                        const newSupplierList = [...supplierList];
                                        newSupplierList.push({ value: s.supplierId, label: s.supplierName });
                                        setSupplierList(newSupplierList);
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default IngredientSuppliersForm;