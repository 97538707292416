import { Fragment } from "react";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import DishesList from "./dishes-list/DishesList";

export const DishesListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider>
                <DishesList />
            </FiltersProvider>
        </Fragment>
    )
}

export default DishesListWrapper;