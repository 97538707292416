import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import Modal, { ModalBody, ModalHeader } from "../../../components/bootstrap/Modal";
import { Info } from "../../../components/icon/material-icons";
import { SupplierService } from "../../../services/suppliers/suppliersService";
import { Supplier } from "../../../type/supplier-type";
import { CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";

const SupplierView = () => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [data] = useFetch(useCallback(async () => {
        const supplierService = new SupplierService();
        const response = await supplierService.getSupplierById(id as string);
        return response.getResponseData() as Supplier;
    }, [id]));

    if (!data) return null;

    const phoneNum = data?.contactPersonTelephone;

    return (
        <Modal isOpen={true} setIsOpen={() => (true)} size='lg' titleId='Información de proveedor'>
            <ModalHeader className='ms-2 p-4 gap-4'>
                <Info fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                <CardTitle className="fs-3">{data?.name}</CardTitle>
                <Button className='btn-close fs-5 p-4' onClick={() => navigate(-1)} />
            </ModalHeader>
            <hr className="mt-0" />
            <ModalBody className='row px-4 mb-4 mx-4'>
                <h4>Datos del proveedor</h4>
                <span className="fs-5">CIF/NIF: {data?.cifNif}</span>
                <span className="fs-5">Correo electrónico: {data?.email}</span>
                <span className="fs-5">Teléfono: {data?.telephone}</span>
                {data?.telephoneSecondary && <span className="fs-5">Teléfono secundario: {data?.telephoneSecondary}</span>}
                {data?.province && <span className="fs-5 col-auto">Provincia: {data?.province}</span>}
                {data?.population && <span className="fs-5 col-auto">Población: {data?.population}</span>}
                {data?.postalCode && <span className="fs-5 col-auto">C.P: {data?.postalCode}</span>}
                {data?.address && <span className="fs-5 col-auto">Dirección: {data?.address}</span>}

                <div className="border rounded mt-5 p-3 fs-5">
                    <div className="row">
                        <h4>Persona de contacto</h4>
                        <span className="col-md-12">Nombre: {data?.contactPersonName}</span>
                        <span className="col-md-12">{`Teléfono: +${phoneNum?.slice(0, 2)} ${phoneNum?.slice(2, 5)} ${phoneNum?.slice(5, 8)} ${phoneNum?.slice(8)}`}</span>
                        <span className="col-md-12">Cargo: {data?.contactPersonPosition}</span>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default SupplierView;