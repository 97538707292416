import { Fragment, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IngredientService } from "../../../services/ingredients/ingredientService";
import { Ingredient } from "../../../type/ingredient-type";
import useFetch from "../../../hooks/useFetch";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { ingredientMenu } from "../../../menu";
import IngredientInfo from "../components/IngredientInfo";
import IngredientCharts from "../components/IngredientCharts";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";

const IngredientView = () => {

    const { id = "", tabURL = "" } = useParams<{ id: string, tabURL: string }>();
    const navigate = useNavigate();
    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();

    const [data] = useFetch(useCallback(async () => {
        const ingredientService = new IngredientService();
        const response = await ingredientService.getIngredientById(id as string);
        return response.getResponseData() as Ingredient;
    }, [id]));

    const _handleDelete = async () => {
        try {
            const response = await (await (new IngredientService()).deleteIngredient(id)).getResponseData();
            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success("Ingrediente eliminado correctamente");
                }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            handleErrors(error);
        }
    };

    if (!data) return null;

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='primary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle className="fs-4">{data?.name}</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    {userCan('edit', 'ingredients') && <Button color='primary' isLink icon='Edit' onClick={() => navigate(`${ingredientMenu.ingredients.path}/${id}/edit`)} />}
                    {userCan('edit', 'ingredients') && userCan('delete', 'ingredients') && <SubheaderSeparator />}
                    {userCan('delete', 'ingredients') && (
                        <Button
                            color='primary' isLink icon='Delete'
                            onClick={() => {
                                handleConfirmationAlert({
                                    title: "Eliminar ingrediente",
                                    icon: "warning",
                                    onConfirm: _handleDelete
                                })
                            }}
                        />
                    )}
                </SubHeaderRight>
            </SubHeader>

            <Page container='fluid'>
                <div className="row">
                    <div className="col-md-4">
                        <IngredientInfo data={data} />
                    </div>

                    <div className="col-md-8">
                        <IngredientCharts data={data} />
                    </div>
                </div>
            </Page>
        </Fragment>
    )
}

export default IngredientView;