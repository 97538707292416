import { AxiosResponse } from 'axios';
import { FilterOptions } from '../../hooks/useFilters';
import { RestServiceConnection } from '../restServiceConnection';

const DASHBOARD_ENDPOINT = '/dashboard';

export class DashboardService extends RestServiceConnection {

    getTotalSalesGraph = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/total-sales-graph',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getTotalSalesCount = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/total-sales-counter',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getTotalProfitsGraph = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/total-profits-graph',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getTotalProfitsCount = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/total-profits-counter',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getMostProfitableDishes = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/most-profitable-dishes',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getMostSoldDishes = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/most-selling-dishes',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getLeastSoldDishes = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/least-selling-dishes',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getMostSoldIngredients = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/most-selling-ingredients',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getLeastSoldIngredients = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/least-selling-ingredients',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getMostUsedIngredients = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/most-used-ingredients',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getLeastUsedIngredients = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DASHBOARD_ENDPOINT + '/least-used-ingredients',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

}