import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface CustomDonutGraphProps {
    series: number[];
    labels: string[];
    height?: string;
}

const CustomDonutGraph: React.FC<CustomDonutGraphProps> = ({ series, labels, height }) => {
    return (
        <ReactApexChart
            series={series}
            type='donut'
            width={'100%'}
            height={height || '225px'}
            options={{
                labels: labels,
                legend: {
                    show: false,
                    //inverseOrder: true,
                    position: 'right',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '12px',
                    fontWeight: 400,
                    formatter: function (seriesName: string, opts: any) {
                        return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                value: {
                                    fontSize: '16px',
                                },
                                total: {
                                    formatter: function (w: any) {
                                        return w.globals.seriesTotals.reduce((a: number, b: number) => {
                                            return series.length;
                                        }, 0);
                                    },
                                    showAlways: true,
                                    show: true,
                                    fontSize: '16px',
                                }
                            }
                        }
                    }
                },
                fill: {
                    opacity: 0.5,
                },
                colors: [
                    'rgba(108, 117, 125)',
                    'rgba(255, 193, 7)',
                    'rgba(208, 70, 54)',
                    'rgba(0, 123, 255)',
                    'rgba(54, 208, 154)',
                    'rgba(23, 162, 184)',
                    'rgba(255, 99, 132)',
                    'rgba(153, 102, 255)',
                    'rgba(255, 159, 64)',
                    'rgba(75, 192, 192)',
                ],
            }}
        />
    );
};

export default CustomDonutGraph;