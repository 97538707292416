import { useContext } from "react";
import { PrivilegeContext } from "../components/priviledge/PriviledgeProvider";

export default function useHasCompanyPermission() {

    const { userCan } = useContext(PrivilegeContext);

    const hasPermissions = (company?: string) => {
        if (userCan('admin_company', 'companies')) {
            return company ? true : false;
        }
        return true;
    }

    return { hasPermissions };
}