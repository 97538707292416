import React, { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import FormGroup from '../../../../components/bootstrap/forms/FormGroup'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/bootstrap/forms/Select'
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import { FilterOptions } from '../../../../hooks/useFilters'
import Input from '../../../../components/bootstrap/forms/Input'
import useIngredientsCategories from '../../../../hooks/useIngredientsCategories'
import useCompanies from '../../../../hooks/useCompanies'
import { userIsSuperAdmin } from '../../../../utils/userIsSuperAdmin'
import useIngredientSuppliers from '../../../../hooks/useIngredientSuppliers'

interface IIngretientFiltersProps {
  updateFilters: (filters: any) => void
  resetFilters: (limit: any) => void
  filters: FilterOptions
}

const IngretientFilters: FC<IIngretientFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

  const [filterMenu, setFilterMenu] = useState(false)

  const { getIngredientsCategoriesList } = useIngredientsCategories();
  const { getIngredientSuppliersList } = useIngredientSuppliers();
  const { getCompanyList } = useCompanies();

  const handleTextChange = (search: string) => {
    updateFilters({ search_array: search });
  };

  return (
    <Fragment>
      <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
        <Icon icon='Search' size='2x' color='primary' />
      </label>
      <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}></CustomSearchInput>

      <Button color='primary' isLight icon='Filter' onClick={() => {
        setFilterMenu(true)
      }}>
        Filtros
      </Button>

      <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
        <OffCanvasHeader setOpen={setFilterMenu}>
          <OffCanvasTitle id='userFilters'> Filtros de Ingredientes </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            {userIsSuperAdmin() && <div className='col-12'>
              <FormGroup label='Compañia:'>
                <Select id='company' onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })} value={filters.filter_filters?.company || ''} ariaLabel='Default select example' placeholder='Elegir Compañia...' list={getCompanyList()}
                />
              </FormGroup>
            </div>}
            <div className='col-12'>
              <FormGroup label='Proveedor:'>
                <Select id='supplier' onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ supplier: e.target.value })} value={filters.filter_filters?.supplier || ''} ariaLabel='Default select example' placeholder='Elegir proveedor...' list={getIngredientSuppliersList()}
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup label='Categoría:'>
                <Select id='ingredient_category' onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ ingredient_category: e.target.value })} value={filters.filter_filters?.ingredient_category || ''} ariaLabel='Default select example' placeholder='Elegir categoria...' list={getIngredientsCategoriesList()}
                />
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup label='Rango precios medios:' labelClassName='fw-bold' className='row text-center'>
                <FormGroup className=' col-md-6'>
                  <Input
                    type='number'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({
                      average_price_range: {
                        min: e.target.value,
                        max: filters.filter_filters?.average_price_range?.max ?? 0,
                      }
                    })}
                    value={filters.filter_filters?.average_price_range?.min ?? ''}
                    placeholder='Cantidad mínima'
                  />
                </FormGroup>
                <FormGroup className=' col-md-6'>
                  <Input
                    type='number'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({
                      average_price_range: {
                        max: e.target.value,
                        min: filters.filter_filters?.average_price_range?.min ?? 0,
                      }
                    })}
                    value={filters.filter_filters?.average_price_range?.max ?? ''}
                    placeholder='Cantidad máxima'
                  />
                </FormGroup>
              </FormGroup>
            </div>
            <div className='col-12'>
              <FormGroup label='Rango últimos precios:' labelClassName='fw-bold' className='row text-center'>
                <FormGroup className=' col-md-6'>
                  <Input
                    type='number'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({
                      last_price_range: {
                        min: e.target.value,
                        max: filters.filter_filters?.last_price_range?.max ?? 0,
                      }
                    })}
                    value={filters.filter_filters?.last_price_range?.min ?? ''}
                    placeholder='Cantidad mínima'
                  />
                </FormGroup>
                <FormGroup className=' col-md-6'>
                  <Input
                    type='number'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({
                      last_price_range: {
                        max: e.target.value,
                        min: filters.filter_filters?.last_price_range?.min ?? 0,
                      }
                    })}
                    value={filters.filter_filters?.last_price_range?.max ?? ''}
                    placeholder='Cantidad máxima'
                  />
                </FormGroup>
              </FormGroup>
            </div>
          </div>
          <div className='col-12'>
            <Button
              className='mt-4'
              color="storybook"
              isLight
              onClick={() => resetFilters(50)}
            >
              Resetear
            </Button>
          </div>
        </OffCanvasBody>

      </OffCanvas>
    </Fragment>
  )
}

export default IngretientFilters