import { Fragment } from "react";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import SalesList from "./sales-list/SalesList";

export const SalesListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider defaultFilterFilters={{show_by: 'realCost'}}>
                <SalesList />
            </FiltersProvider>
        </Fragment>
    )
}