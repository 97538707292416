import { Fragment, useState } from "react";
import Card, { CardHeader, CardBody, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import { financialMenu } from "../../../menu";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/bootstrap/Button";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { DeliveryNoteService } from "../../../services/delivery-notes/deliveryNoteService";
import { CreateDeliveryNoteFieldsModel } from "../../../type/deliveryNote-type";
import DeliveryNoteForm from "../DeliveryForm";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { Receipt } from "../../../components/icon/material-icons";

const CreateDeliveryNote = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { handleErrors } = useHandleErrors();

    const handleCreation = async (values: CreateDeliveryNoteFieldsModel) => {
        try {
            setLoading(true)
            let response = await (await (new DeliveryNoteService()).createDeliveryNote(values)).getResponseData();
            if (response.success) {
                toast.success(response.message);
                navigate(financialMenu.deliveryNotes.path, { replace: true })
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al crear el albarán');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid'>
                <Card className="col-md-11 m-auto">
                    <CardHeader borderSize={1} className="d-flex justify-content-start">
                        <Receipt fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                        <CardTitle className="fs-3 mb-0 ms-3">Crear Albarán</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <DeliveryNoteForm submit={handleCreation} isLoading={loading} />
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    )
}

export default CreateDeliveryNote;