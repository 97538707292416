import React, { useCallback, useContext, useEffect, useState } from "react";
import useFilters, { FilterOptions, FilterOrders } from "../../../hooks/useFilters";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { changeStorageViewMode } from "../../../redux/dashboardSlice";
import useFetch from "../../../hooks/useFetch";
import { DashboardService } from "../../../services/dashboard/DashboardService";
import { useMain } from "./MainProvider";
import useHandleErrors from "../../../hooks/useHandleErrors";

type DashboardProviderProps = {
    children: React.ReactNode
    defaultFilters?: FilterOptions
    defaultOrders?: FilterOrders,
    defaultPage?: number,
    defaultPageSize?: number,
}

type DashboardContextData = {
    viewMode: 'years' | 'months' | 'weeks' | 'days',
    changeViewMode: (view: 'years' | 'months' | 'weeks' | 'days') => void,
    filters: FilterOptions | any,
    updateFilters: (filters: any) => void,
    updatePage: (page: any) => void,
    updatePageSize: (pageSize: number) => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    resetFilters: () => void,
    dashboardDates: { startDate: string | null, endDate: string | null },
    setDashboardDates: (dates: { startDate: string | null, endDate: string | null }) => void,
    isLoading: boolean,
    setIsLoadingSales: (isLoading: boolean) => void,
    setIsLoadingProfits: (isLoading: boolean) => void,
    totalSales?: any,
    totalProfits?: any,
    totalSalesCounter?: any,
    totalProfitsCounter?: any,
}

const DashboardContext: React.Context<DashboardContextData> = React.createContext<DashboardContextData>({
    viewMode: 'days',
    changeViewMode: (view: 'years' | 'months' | 'weeks' | 'days') => { },
    filters: {} as FilterOptions | any,
    updateFilters: (filters: any) => { },
    updatePage: (page: any) => { },
    updatePageSize: (pageSize: number) => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    resetFilters: () => { },
    dashboardDates: { startDate: null, endDate: null },
    setDashboardDates: (dates: { startDate: string | null, endDate: string | null }) => { },
    isLoading: false,
    setIsLoadingSales: (isLoading: boolean) => { },
    setIsLoadingProfits: (isLoading: boolean) => { },
    totalSales: undefined,
    totalProfits: undefined,
    totalSalesCounter: undefined,
    totalProfitsCounter: undefined,
});

const DashboardProvider: React.FC<DashboardProviderProps> = ({ children, defaultFilters, defaultOrders, defaultPage, defaultPageSize }) => {

    const { filters: mainFilters, updateFilters: updateMainFilters, displayComparedGraphs } = useMain();
    const dispatch = useDispatch();
    const viewMode = useSelector((state: RootState) => state.dashboard.viewMode);
    const { handleErrors } = useHandleErrors();

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters(defaultFilters, defaultOrders, defaultPage, defaultPageSize);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSales, setIsLoadingSales] = useState<boolean | undefined>(undefined);
    const [isLoadingProfits, setIsLoadingProfits] = useState<boolean | undefined>(undefined);
    const [dashboardDates, setDashboardDates] = useState<{ startDate: string | null, endDate: string | null }>({ startDate: null, endDate: null });

    const today = new Date();
    const justAMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const fromDate = `${justAMonthAgo.getFullYear()}-${(justAMonthAgo.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    const toDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [totalSales] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.date?.from || !filters.filter_filters?.date?.to) return;
        setIsLoadingSales(true);

        try {
            const response = await (new DashboardService).getTotalSalesGraph(/* !displayComparedGraphs ? filters : mainFilters */ filters);
            return response.getResponseData();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingSales(false);
        }
    }, [filters]));

    const [totalProfits] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.date?.from || !filters.filter_filters?.date?.to) return;
        setIsLoadingProfits(true);
        try {
            const response = await (new DashboardService).getTotalProfitsGraph(filters);
            return response.getResponseData();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingProfits(false);
        }
    }, [filters]));

    const [totalSalesCounter] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.date?.from || !filters.filter_filters?.date?.to) return;
        const service = (new DashboardService).getTotalSalesCount(filters);
        const response = await service;
        return response.getResponseData();
    }, [filters]));

    const [totalProfitsCounter] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.date?.from || !filters.filter_filters?.date?.to) return;
        const service = (new DashboardService).getTotalProfitsCount(filters);
        const response = await service;
        return response.getResponseData();
    }, [filters]));

    // Change the view mode of the dashboard
    const changeViewMode = (view: 'years' | 'months' | 'weeks' | 'days') => {
        dispatch(changeStorageViewMode(view));
    };

    // Set is loading to false when all the data is loaded
    useEffect(() => {
        if (isLoadingSales === false && isLoadingProfits === false) {
            setIsLoading(false);
        }
    }, [isLoadingSales, isLoadingProfits]);

    // Load the default filters
    useEffect(() => {
        updateFilters({
            date: {
                from: fromDate,
                to: toDate,
                compare: false,
                compare_from: '',
                compare_to: '',
                mode: viewMode,
            },
            data_number: 10,
            show_by: 'realCost'
        });
    }, []);

    // Update the main filters when the date filter is updated and the compared graphs are not displayed yet
    useEffect(() => {
        if (filters.filter_filters?.date?.from && filters.filter_filters?.date?.to && !displayComparedGraphs) {
            updateMainFilters({
                date: {
                    ...mainFilters.filter_filters?.date,
                    from: filters.filter_filters?.date?.from || fromDate,
                    to: filters.filter_filters?.date?.to || toDate,
                    mode: filters.filter_filters?.date?.mode || viewMode,
                    compare_from: filters.filter_filters?.date?.from || fromDate,
                    compare_to: filters.filter_filters?.date?.to || toDate,
                },
                show_by: filters.filter_filters?.show_by || 'realCost'
            });
        }
    }, [filters.filter_filters?.date?.from, filters.filter_filters?.date?.to]);

    return (
        <DashboardContext.Provider value={{
            viewMode,
            changeViewMode,
            filters,
            updateFilters,
            updatePage,
            updatePageSize,
            updateFilterOrder,
            resetFilters,
            dashboardDates,
            setDashboardDates,
            isLoading,
            setIsLoadingSales,
            setIsLoadingProfits,
            totalSales,
            totalProfits,
            totalSalesCounter,
            totalProfitsCounter,
        }}>
            {children}
        </DashboardContext.Provider>
    );
}

DashboardProvider.defaultProps = {
    defaultFilters: {} as FilterOptions,
    defaultOrders: [] as FilterOrders,
    defaultPage: 1,
    defaultPageSize: 999999999,
}

export { DashboardProvider, DashboardContext };

export function useDashboard() {
    return useContext(DashboardContext);
}