import React, { useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { DashboardService } from '../../../services/dashboard/DashboardService';
import { useDashboard } from '../provider/DashboardProvider';
import { CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import CustomDonutGraph from '../../../components/graphs/CustomDonutGraph';
import { useMain } from '../provider/MainProvider';

interface SoldDishesProps {
    most?: boolean;
}

const SoldDishes: React.FC<SoldDishesProps> = ({ most }) => {

    const { displayComparedGraphs } = useMain();
    const { filters } = useDashboard();

    const [series, setSeries] = useState<{ series: number[], labels: string[] }>({ series: [], labels: [] });

    const [data] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.show_by) return;
        try {
            const service = most ? (new DashboardService).getMostSoldDishes(filters) : (new DashboardService).getLeastSoldDishes(filters);
            const response = await service;
            return response.getResponseData();
        } catch (error) {
            console.error(error);
        }
    }, [filters]));

    useEffect(() => {
        if (data) {
            setSeries({
                series: Object.values(data)?.map((item: any) => item.count),
                labels: Object.values(data)?.map((item: any) => item.dish_name)
            });
        }
    }, [data]);

    if (!data) return null;

    return (
        <>
            <CardHeader className="p-0 d-flex">
                <CardTitle tag="h4" className="h5">
                    {most ? 'Platos más vendidos' : 'Platos menos vendidos'}
                </CardTitle>
            </CardHeader>

            <CustomDonutGraph series={series.series} labels={series.labels} height={displayComparedGraphs ? '200px' : '225px'} />
        </>
    );
};

export default SoldDishes;