import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardActions, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import UserForm from "../UserForm";
import { UserService } from "../../../services/users/userService";
import { EditUserFieldsModel, User } from "../../../type/user-type";
import useFetch from "../../../hooks/useFetch";
import UserEditPermissions from "./UserEditPermissions";
import { RolePermission } from "../../../type/role-type";
import StatusSwitch from "../../../components/StatusSwitch";
import { toast } from 'react-toastify';
import useHandleErrors from "../../../hooks/useHandleErrors";
import { isString } from "formik";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import { Edit } from "../../../components/icon/material-icons";

const UserEdit: FC = () => {

  const navigate = useNavigate();
  const { handleErrors } = useHandleErrors();
  const { id = '' } = useParams<{ id: string }>();

  const [editPermissions, setEditPermissions] = useState<boolean>(false);
  const [userPermissions, setUserPermissions] = useState<RolePermission[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [changingStatus, setChangingStatus] = useState<boolean>(false);

  const [data] = useFetch(useCallback(async () => {
    const userService = new UserService();
    const response = await userService.getUserById(id as string);
    setUserPermissions(response.getResponseData().data.userPermissions);
    return response.getResponseData() as User;
  }, [id, editPermissions]));

  // Function to save user data/images/status etc...
  const handleUpdate = async (values: EditUserFieldsModel) => {
    setLoading(true);

    // Remove roleId if user is already assigned to that role
    (data?.userRoles[0].role?.id === values.roleId) && (values.roleId = null);

    // Update user image if exists or add it if not
    try {
      if (values.logo && values.id) {
        if (values.logo instanceof Blob && isString(values.id)) {
          const response = ((await (new UserService()).updateImage(values.logo, values.id)).getResponseData())
          if (!response.success) {
            toast.error(response.message);
            return;
          }
        }
      }
    } catch (error: any) {
      toast.error('Error al añadir imagen de perfil');
    }

    // Change user password
    try {
      if (isString(values.id) && values.password && values.re_password) {
        const response = ((await (new UserService()).changeUserPassword(values.id, values.password, values.re_password)).getResponseData())
        if (!response.success) {
          toast.error(response.message);
          return;
        }
      }
    } catch (error: any) {
      toast.error('Error al actualizar constraseña');
    }

    // Update user information
    try {
      let response = await (await (new UserService()).editUser({ ...values, userId: values.id })).getResponseData();
      if (response.success) {
        toast.success(response.message);
        navigate('/users', { replace: true });
      } else {
        handleErrors(response);
      }
    } catch (error: any) {
      toast.error('Error al editar Usuario');
    } finally {
      setLoading(false);
    }
  };

  const toggleUserStatus = async (id: string, status: boolean, toggleStatus: Function) => {
    try {
      setChangingStatus(true);
      let response = await (
        await new UserService().toggleUserStatus(id, status)
      ).getResponseData();
      if (response.success) {
        toggleStatus(status);
      }
    } catch (error: any) {
      //
    } finally {
      setChangingStatus(false);
    }
  };

  const getContent = () => {
    if (loading) return <Loader />;

    if (data !== null) {
      const userData = {
        ...data,
        roleId: data.userRoles[0]?.role?.id,
        password: "",
        re_password: "",
        company: data.userRoles[0]?.company?.id,
        profileImg: data.profileImg && data.profileImg,
      };

      return (
        <Fragment>
          <CardHeader borderSize={1} className="d-flex justify-content-between">
            <div className="d-flex">
              <Edit fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
              <CardTitle className="fs-3 mb-0 ms-3">Editar Usuario</CardTitle>
            </div>
            <CardActions>
              <StatusSwitch itemId={data.id} status={data.active} change={toggleUserStatus} />
            </CardActions>
          </CardHeader>
          <UserForm isLoading={loading} submit={handleUpdate} userData={userData} />
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
        </SubHeaderLeft>
        <SubHeaderRight>
          <Button color="brand-two" icon="Security" onClick={() => { setEditPermissions(true) }}>
            Editar Permisos
          </Button>
        </SubHeaderRight>
      </SubHeader>
      <Page container="xxl">
        <Card stretch={true}>
          <>{getContent()}</>
        </Card>
      </Page>

      {editPermissions && data &&
        <UserEditPermissions
          userId={id}
          isOpen={editPermissions} setIsOpen={setEditPermissions}
          userPermissions={userPermissions} setUserPermissions={setUserPermissions}
        />
      }
    </Fragment>
  );
};

export default UserEdit;