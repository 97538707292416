import React, { useContext } from 'react';
import { CustomTable } from '../../../components/table/CustomTable';
import { SalesApiResponse } from '../../../type/sales-type';
import { Link, useNavigate } from 'react-router-dom';
import { FixNumber } from '../../../utils/fixNumber';
import moment from 'moment';
import { userIsSuperAdmin } from '../../../utils/userIsSuperAdmin';
import { handleConfirmationAlert } from '../../../utils/ConfirmationAlert';
import classNames from 'classnames';
import { PrivilegeContext } from '../../../components/priviledge/PriviledgeProvider';

interface SalesTableProps {
    data: any;
    filters: any;
    updatePage: (page: number) => void;
    updatePageSize: (limit: number) => void;
    updateFilterOrder: (filter: string) => void;
    deleteSale: (id: string) => void;
}

const SalesTable: React.FC<SalesTableProps> = ({ data, filters, updatePage, updatePageSize, updateFilterOrder, deleteSale }) => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    return (
        <CustomTable
            data={data?.sales ? data.sales : null}
            pagination={true}
            paginationData={{
                pageSize: filters.limit,
                currentPage: filters.page,
                pageCount: (data as SalesApiResponse) ? data.lastPage : 1,
                totalCount: data.totalRegisters,
                handlePagination: updatePage,
                handlePerPage: updatePageSize,
            }}
            defaultLimit={filters.limit || 50}
            defaultOrder={filters.filter_order || undefined}
            className={"table table-hover"}
            columns={[
                {
                    name: "Código",
                    keyValue: "tpv_sale_id",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <Link to={`${element.id}/view`} className="d-flex justify-content-center fw-bold" style={{ textDecoration: 'none' }}>
                                {element.tpvSaleId}
                            </Link>
                        );
                    },
                },
                {
                    name: 'Pedido',
                    keyValue: 'salesHasDishes',
                    className: 'text-center',
                    render: (element: any) => {
                        return (
                            element.salesHasDishes?.reduce((result: any, sale: any) => {
                                const existingDish = result.find((groupedDish: any) => groupedDish.id === sale.dishes?.id);

                                if (existingDish) {
                                    existingDish.quantity += 1;
                                    existingDish.total += sale.price;
                                } else {
                                    result.push({
                                        id: sale.dishes?.id,
                                        name: sale.dishes?.name,
                                        quantity: sale.quantity,
                                        price: sale.price,
                                    });
                                }

                                return result;
                            }, [])?.map((d: any, index: number) => (
                                <li key={index}>
                                    <span className='p-0'>{`${d.quantity} ${d.name} (${d.price}€)`}</span>
                                </li>
                            ))
                        );
                    },
                },
                {
                    name: 'Impuestos',
                    keyValue: 'tax_percentage',
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: 'text-center',
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center text-center">
                                {element.taxPercentage + ' %' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Precio total",
                    keyValue: "total",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center text-center">
                                {FixNumber(element.total) + ' €' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Coste medio",
                    keyValue: "average_cost",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center text-center">
                                {FixNumber(element.totalAverageCost) + ' €' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Coste total",
                    keyValue: "real_cost",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center text-center">
                                {FixNumber(element.totalRealCost) + ' €' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Beneficio",
                    keyValue: "benefit",
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <div className={classNames("d-flex justify-content-center text-center", {
                                'text-danger': element.total - element.totalRealCost < 0,
                                'text-success': element.total - element.totalRealCost > 0,
                            })}>
                                {FixNumber(element.total - element.totalRealCost) + ' €' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Organización",
                    keyValue: "company",
                    isVisible: userIsSuperAdmin(),
                    sortable: false,
                    sortColumn: updateFilterOrder,
                    className: `text-center`,
                    render: (element: any) => {
                        return (
                            <span>
                                {element?.company?.name ?? "N/A"}
                            </span>
                        );
                    },
                },
                {
                    name: "Fecha de venta",
                    keyValue: "sale_date",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: `text-center`,
                    render: (element: any) => {
                        return (
                            <div className={"text-center"}>
                                <span className={"text-muted"}>
                                    {moment(element.saleDate?.date).format("DD-MM-YYYY HH:mm") ?? "N/A"}
                                </span>
                            </div>
                        );
                    },
                },
                {
                    name: "Fecha de creación",
                    keyValue: "created_at",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: `text-center`,
                    render: (element: any) => {
                        return (
                            <div className={"text-center"}>
                                <span className={"text-muted"}>
                                    {moment(element.createdAt?.date).format("DD-MM-YYYY HH:mm") ?? "N/A"}
                                </span>
                            </div>
                        );
                    },
                },
                { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
            ]}
            actions={[
                {
                    title: "Editar",
                    buttonType: "icon",
                    iconColor: "text-info",
                    iconPath: "/media/icons/duotune/general/gen055.svg",
                    additionalClasses: "text-primary",
                    description: "Editar venta",
                    hide: () => !userCan('edit', 'sales'),
                    callback: (item: any) => { navigate(`${item.id}/edit`) },
                },
                {
                    title: "Eliminar",
                    buttonType: "icon",
                    iconColor: "text-danger",
                    iconPath: "/media/icons/duotune/general/gen027.svg",
                    additionalClasses: "text-danger",
                    description: "Eliminar venta",
                    hide: () => !userCan('delete', 'sales'),
                    callback: (item: any) => {
                        handleConfirmationAlert({
                            title: "Eliminar venta",
                            text: "Esta acción es irreversible. ¿Estás seguro de que quieres eliminar esta venta?",
                            icon: "warning",
                            onConfirm: () => { deleteSale(item.id) },
                        });
                    },
                },
            ]}
        />
    );
};

export default SalesTable;