import { Fragment, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import useFetch from "../../../hooks/useFetch";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import moment from "moment";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { IngredientService } from "../../../services/ingredients/ingredientService";
import { IngredientCategoriesApiResponse } from "../../../type/ingredient-type";
import { userIsSuperAdmin } from "../../../utils/userIsSuperAdmin";
import CategoryFilters from "./categories-options/CategoriesFilters";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import ErrorMessage from "../../../components/ErrorMessage";

const IngredientCategoriesList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();
    const ingredientService = new IngredientService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters, checkIfUrlHasFilters } = useFiltersPR();

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await ingredientService.getIngredientsCategories(filters);
        return response.getResponseData() as IngredientCategoriesApiResponse;
    }, [filters]));

    /**
     * Deletes a user with the given ID.
     *
     * @param {string} id - The ID of the user to be deleted.
     */
    const deleteCategory = async (id: string) => {
        let response = (await ingredientService.deleteIngredientCategory(id)).getResponseData();
        if (response.success) {
            toast.success("Categoría eliminada correctamente");
            updateFilters({ ...filters });
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardTitle>Listado de Categorías de Ingredientes</CardTitle>
                        {userCan("create", "ingredients") &&
                            <>
                                <SubheaderSeparator />
                                <Button color="light" icon="Add" isLight onClick={() => { navigate("create") }}>
                                    Añadir Categoría
                                </Button>
                            </>
                        }
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <CategoryFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={false}>
                    {error && <ErrorMessage error={error} />}

                    {data && data.ingredientCategories
                        ? (
                            <CustomTable
                                data={data?.ingredientCategories ? data.ingredientCategories : null}
                                pagination={true}
                                paginationData={{
                                    pageSize: filters.limit,
                                    currentPage: filters.page,
                                    pageCount: (data as IngredientCategoriesApiResponse) ? data.lastPage : 1,
                                    totalCount: data.totalRegisters,
                                    handlePagination: updatePage,
                                    handlePerPage: updatePageSize,
                                }}
                                defaultLimit={filters.limit || 50}
                                defaultOrder={filters.filter_order || undefined}
                                className={"table table-hover"}
                                columns={[
                                    {
                                        name: "Nombre",
                                        keyValue: "name",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {element.name}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Descripción",
                                        keyValue: "description",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                    },
                                    {
                                        name: "Usuario",
                                        keyValue: "user",
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {`${element.user?.name} ${element.user?.lastName || ''}` ?? "N/A"}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Organización",
                                        keyValue: "company",
                                        isVisible: userIsSuperAdmin(),
                                        sortable: false,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <span>
                                                    {element?.company?.name ?? "N/A"}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        name: "Fecha de creación",
                                        keyValue: "created_at",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <div className={"text-center"}>
                                                    <span className={"text-muted"}>
                                                        {moment(element.createdAt.date).format(
                                                            "DD-MM-YYYY HH:mm"
                                                        ) ?? "N/A"}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
                                ]}
                                actions={[
                                    {
                                        title: "Editar",
                                        buttonType: "icon",
                                        iconColor: "text-info",
                                        iconPath: "/media/icons/duotune/general/gen055.svg",
                                        additionalClasses: "text-primary",
                                        description: "Editar categoría",
                                        hide: () => !userCan('edit', 'ingredients'),
                                        callback: (item: any) => { navigate(`${item.id}/edit`) },
                                    },
                                    {
                                        title: "Eliminar",
                                        buttonType: "icon",
                                        iconColor: "text-danger",
                                        iconPath: "/media/icons/duotune/general/gen027.svg",
                                        additionalClasses: "text-danger",
                                        description: "Eliminar categoría",
                                        hide: () => !userCan('delete', 'ingredients'),
                                        callback: (item: any) => {
                                            handleConfirmationAlert({
                                                title: "Eliminar categoría",
                                                text: "¿Estás seguro de que deseas eliminar la categoría?",
                                                icon: "warning",
                                                onConfirm: () => { deleteCategory(item.id) },
                                            });
                                        },
                                    },
                                ]}
                            />
                        )
                        : !error && <Loader />
                    }
                </Card>
            </Page>
        </Fragment>
    );
};

export default IngredientCategoriesList;