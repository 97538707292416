import React, { useCallback } from 'react';
import useFetch from '../../../hooks/useFetch';
import { DashboardService } from '../../../services/dashboard/DashboardService';
import { useDashboard } from '../provider/DashboardProvider';
import { CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import { FixNumber } from '../../../utils/fixNumber';

interface ProfitableDishesProps {
}

const ProfitableDishes: React.FC<ProfitableDishesProps> = ({ }) => {

    const { filters } = useDashboard();

    const [data] = useFetch(useCallback(async () => {
        if (!filters.filter_filters?.date?.from || !filters.filter_filters?.date?.to) return;
        try {
            const response = await (new DashboardService).getMostProfitableDishes(filters);
            return response.getResponseData();
        } catch (error) {
            console.error(error);
        }
    }, [filters]));

    const formatNumber = (number: string) => {
        const color = number.includes('-') ? 'text-danger' : 'text-primary';
        return <span className={color}>{number} €</span>;
    };

    if (!data) return null;

    return (
        <>
            <CardHeader className="p-0 d-flex mb-3">
                <CardTitle className="h5">Platos más rentables</CardTitle>
            </CardHeader>

            <ol>
                {Object.keys(data)?.map((key) => (
                    <li key={key}>
                        {data[key].dish_name}: {formatNumber(FixNumber(data[key].profit))}
                    </li>
                ))}
            </ol>
        </>
    );
};

export default ProfitableDishes;