import { Fragment } from "react";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import DeliveriesList from "./deliveries-list/DeliveriesList";

export const DeliveriesListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider>
                <DeliveriesList />
            </FiltersProvider>
        </Fragment>
    )
}