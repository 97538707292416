import React from 'react';
import { CustomTable } from '../../../components/table/CustomTable';
import { Link } from 'react-router-dom';
import { FixNumber } from '../../../utils/fixNumber';
import { userIsSuperAdmin } from '../../../utils/userIsSuperAdmin';
import moment from 'moment';
import { DeliveryNoteLine } from '../../../type/deliveryNote-type';
import { deliveryNotesMenu, supplierMenu } from '../../../menu';
import { CardTitle } from '../../../components/bootstrap/Card';

interface IngredientLastPricesListProps {
    data: any;
    filters: any;
    updatePage: (page: number) => void;
    updatePageSize: (limit: number) => void;
    updateFilterOrder: (keyValue: string, order: "asc" | "desc") => void;
}

const IngredientLastPricesList: React.FC<IngredientLastPricesListProps> = ({ data, filters, updatePage, updatePageSize, updateFilterOrder }) => {

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <CardTitle>Últimas compras</CardTitle>
            </div>

            {data && (
                <CustomTable
                    data={data?.deliveryNoteLines ? data.deliveryNoteLines : null}
                    pagination={true}
                    paginationData={{
                        pageSize: filters.limit,
                        currentPage: filters.page,
                        pageCount: (data as DeliveryNoteLine) ? data.lastPage : 1,
                        totalCount: data.totalRegisters,
                        handlePagination: updatePage,
                        handlePerPage: updatePageSize,
                    }}
                    defaultLimit={filters.limit || 50}
                    defaultOrder={filters.filter_order || undefined}
                    className={"table table-hover"}
                    columns={[
                        {
                            name: 'Nº Albarán',
                            keyValue: 'delivery_note_number',
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: 'text-center',
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-center">
                                        <Link to={`${deliveryNotesMenu.deliveryNotes.path}/${element.deliveryNotes?.id}/view`} className='pointer fw-bold' style={{ textDecoration: 'none' }}>
                                            {element.deliveryNotes?.deliveryNoteNumber}
                                        </Link>
                                    </div>
                                );
                            }
                        },
                        {
                            name: "Cantidad",
                            keyValue: "quantity",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (element: any) => {
                                // Filter the ingredient by supplier
                                const filteredIngredient = element.ingredient?.ingredientHasSupplierCode.filter((item: any) => item.supplier.id === element.supplier.id);

                                const quantity = filteredIngredient[0]?.hasUnits
                                    ? `${element.quantity} x ${filteredIngredient[0]?.equivalentQuantity}${filteredIngredient[0]?.equivalentUM} = ${element.quantity * filteredIngredient[0]?.equivalentQuantity}`
                                    : `${element.quantity}${element.measureUnit}`;

                                const measureUnit = filteredIngredient[0]?.hasUnits ? filteredIngredient[0]?.equivalentUM : element.ingredient?.measureUnit;
                                return (
                                    <div className="d-flex justify-content-center text-center">
                                        {quantity}{measureUnit}
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Precio",
                            keyValue: "price",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            cellClassName: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-center">
                                        {FixNumber(element.price)} €
                                    </div>
                                );
                            }
                        },
                        {
                            name: "Proveedor",
                            keyValue: "supplier",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-center">
                                        <Link to={`${supplierMenu.suppliers.path}/${element.supplier?.id}/view`} className='pointer fw-bold' style={{ textDecoration: 'none' }}>
                                            {element.supplier?.name}
                                        </Link>
                                    </div>
                                );
                            },
                        },
                        {
                            name: "Organización",
                            keyValue: "company",
                            isVisible: userIsSuperAdmin(),
                            sortable: false,
                            sortColumn: updateFilterOrder,
                            className: `text-center`,
                            render: (element: any) => {
                                return (
                                    <span>
                                        {element?.company?.name ?? "N/A"}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "Fecha de albarán",
                            keyValue: "delivery_note_date",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            cellClassName: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-center">
                                        {moment(element.deliveryNotes?.deliveryNoteDate?.date).format("DD-MM-YYYY")}
                                    </div>
                                );
                            }
                        },
                        {
                            name: "Fecha de compra",
                            keyValue: "created_at",
                            sortable: true,
                            sortColumn: updateFilterOrder,
                            className: "text-center",
                            render: (element: any) => {
                                return (
                                    <div className="d-flex justify-content-center text-center">
                                        {moment(element.createdAt.date).format("DD-MM-YYYY")}
                                    </div>
                                )
                            },
                        },
                    ]}
                />
            )}
        </>
    );
};

export default IngredientLastPricesList;