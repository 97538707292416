import { FC, Fragment, useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import PersonalInfoCard from "./PersonalInfoCard";
import useFetch from "../../../hooks/useFetch";
import { User } from "../../../type/user-type";
import { UserService } from "../../../services/users/userService";
import { getUserRolesByObject } from "../../../helpers/helpers";
import moment from "moment";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { adminMenu } from "../../../menu";
import { toast } from "react-toastify";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";

const UserProfile: FC = () => {

  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);
  const { handleErrors } = useHandleErrors();
  const userService = new UserService();

  const [data, loading] = useFetch(useCallback(async () => {
    const response = await userService.getUserById(id as string);
    return response.getResponseData() as User;
  }, [id]));

  const _handleDelete = async () => {
    try {
      const response = await (await userService.deleteUser(id)).getResponseData();
      if (response.success) {
        navigate(-1);
        setTimeout(() => {
          toast.success("Usuario eliminado correctamente");
        }, 100);
      } else {
        handleErrors(response);
      }
    } catch (error: any) {
      handleErrors(error);
    }
  };

  if (loading) return <Loader />;
  if (!data) return null;

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
        </SubHeaderLeft>
        <SubHeaderRight>
          {userCan('edit', 'user') && <Button color='primary' isLink icon='Edit' onClick={() => navigate(`${adminMenu.users.path}/${id}/edit`)} />}
          {userCan('edit', 'user') && userCan('delete', 'user') && <SubheaderSeparator />}
          {userCan('delete', 'user') && (
            <Button
              color='primary' isLink icon='Delete'
              onClick={() => {
                handleConfirmationAlert({
                  title: "Eliminar usuario",
                  text: "Esta acción es irreversible. ¿Estás seguro de que quieres eliminar este usuario?",
                  icon: "warning",
                  onConfirm: _handleDelete
                })
              }}
            />
          )}
        </SubHeaderRight>
      </SubHeader>

      <Page container="xxl">
        {data && (
          <>
            <div className="pt-3 pb-5 d-flex align-items-center">
              <span className="display-6 fw-bold me-3">{data.name + (data.lastName && ` ${data.lastName}`)}</span>
              {getUserRolesByObject(data)?.map((role: string) => (
                <span key={"profile-role" + role} className="border border-dark border-2 text-dark fw-bold px-3 py-2 rounded">
                  {role}
                </span>
              ))}
              <span className="text-muted fs-5 ms-4">Último acceso: {moment(data.lastLogin?.date).format('DD/MM/YYYY')}</span>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <PersonalInfoCard
                  profileImg={data.profileImg?.id}
                  email={data.email}
                  phone={data.telephone}
                  address={data.address}
                />
              </div>
            </div>
          </>
        )}
      </Page>
    </Fragment>
  );
};

export default UserProfile;