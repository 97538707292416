import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import useCompanies from "../../hooks/useCompanies";
import useHasCompanyPermission from "../../hooks/useHasCompanyPermission";
import * as yup from 'yup';
import { Elaboration, ElaborationIngredient } from "../../type/elaboration-type";
import Button from "../../components/bootstrap/Button";
import { CardBody, CardFooter } from "../../components/bootstrap/Card";
import Spinner from "../../components/bootstrap/Spinner";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import { MEASUREMENT_UNITS } from "../../utils/ingredients/ingredientsUtils";
import ElaborationIngredients from "./components/ElaborationIngredients";
import { toast } from "react-toastify";

interface ElaborationFormProps {
    isLoading: boolean;
    submit: Function;
    data?: any;
}

const elaborationSchema = yup.object().shape({
    name: yup.string().required('El nombre es requerido').max(100, 'No puede tener más de 100 caracteres'),
    description: yup.string(),
});

const ElaborationForm: FC<ElaborationFormProps> = ({ data, submit, isLoading }) => {

    const { userCan } = useContext(PrivilegeContext);
    const { hasPermissions } = useHasCompanyPermission();
    const { getCompanyList } = useCompanies();
    const mode = data ? 'Editar' : 'Crear';

    const [ingredientsList, setIngredientsList] = useState<any>([]);

    const _handleSubmit = (values: any) => {
        if (values.ingredientId !== undefined && ingredientsList.map((ingredient: any) => ingredient.ingredientId).includes(values.ingredientId)) {
            toast.error('No puede haber ingredientes duplicados');
            return;
        }

        if (values.childElaborationId !== undefined && ingredientsList.map((ingredient: any) => ingredient.childElaborationId).includes(values.childElaborationId)) {
            toast.error('No puede haber elaboraciones duplicadas');
            return;
        }

        values.elaborationIngredients = ingredientsList;
        // If the ingredient is already in the list, do not add it
        /* if (ingredientsList.map((ingredient: any) => ingredient.ingredientId).includes(formikIngredients.values.ingredientId)) {
            toast.error('El ingrediente ya existe');
            return;
        }
        if (ingredientsList.map((ingredient: any) => ingredient.childElaborationId).includes(formikIngredients.values.childElaborationId)) {
            toast.error('La elaboración ya existe');
            return;
        } */

        // If the ingredient is not in the list, add it
        if ((formikIngredients.values.ingredientId !== '' || formikIngredients.values.childElaborationId !== '') && formikIngredients.values.quantity !== 0) {
            values.elaborationIngredients.push(formikIngredients.values);
        }
        submit(values);
    };

    const _handleAddIngredient = (values: any) => {
        if (values.ingredientId !== '' && ingredientsList.map((ingredient: any) => ingredient.ingredientId).includes(values.ingredientId)) {
            toast.error('El ingrediente ya existe');
            return;
        }
        if (values.childElaborationId !== '' && ingredientsList.map((ingredient: any) => ingredient.childElaborationId).includes(values.childElaborationId)) {
            toast.error('La elaboración ya existe');
            return;
        }
        if (values.quantity < 0 || isNaN(values.quantity)) {
            toast.error('Debe ser un número positivo');
            return;
        }
        setIngredientsList([...ingredientsList, values]);
        formikIngredients.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            elaborationId: data?.elaborationId || '',
            company: data?.company || '',
            name: data?.name || '',
            description: data?.description || '',
            processingQuantity: data?.processingQuantity || 1,
            measureUnit: data?.measureUnit || 'g',
            elaborationIngredients: data?.elaborationIngredients || [],
        },
        validationSchema: elaborationSchema,
        onSubmit: (values) => { _handleSubmit(values) },
    });

    const formikIngredients = useFormik<ElaborationIngredient>({
        initialValues: {
            id: '',
            type: '',
            ingredientIdentifier: '',
            quantity: 0,
            measureUnit: 'g',
            ingredientId: '',
            childElaborationId: '',
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.quantity) {
                errors.quantity = 'La cantidad es obligatoria';
            }
            if (!values.measureUnit) {
                errors.measureUnit = 'La unidad de medida es obligatoria';
            }
            if (!values.ingredientId && !values.childElaborationId) {
                errors.ingredientId = 'El ingrediente es obligatorio';
            }
            return errors;
        },
        onSubmit: (values) => { _handleAddIngredient(values) },
    });

    const verifyClass = (inputFieldID: keyof Elaboration) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof Elaboration) => {
        const error = formik.touched[inputFieldID] && formik.errors[inputFieldID];
        return (error ? <div className="invalid-feedback">{String(error)}</div> : <></>);
    };

    useEffect(() => {
        if (data?.elaborationIngredients) {
            setIngredientsList(data?.elaborationIngredients.map((ingredient: any) => {
                return {
                    id: ingredient.id,
                    type: ingredient.childElaboration ? 'elaboration' : 'ingredient',
                    childElaborationId: ingredient?.childElaboration?.id || '',
                    ingredientId: ingredient?.ingredient?.id || '',
                    ingredientIdentifier: ingredient?.ingredientIdentifier || '',
                    quantity: ingredient.quantity,
                    measureUnit: ingredient.measureUnit,
                }
            }));
        }
    }, [data]);

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="off">
            <CardBody isScrollable={false} className="row g-3">
                {userCan('admin_company', 'companies') &&
                    <FormGroup label='Organización' className='col-md-6'>
                        <Select
                            id='company' onChange={formik.handleChange}
                            value={formik.values.company} list={getCompanyList()}
                            className={verifyClass('company')}
                            ariaLabel='organization select' placeholder='Elegir organización ...'
                        />
                        {showErrors('company')}
                    </FormGroup>
                }

                {hasPermissions(formik.values.company) && (
                    <div className="d-flex justify-content-center flex-column">
                        <div className="row d-flex justify-content-between">
                            <FormGroup requiredInputLabel label='Nombre' className='col-md-4'>
                                <Input
                                    id='name' required onChange={formik.handleChange} value={formik.values.name}
                                    onBlur={formik.handleBlur} className={verifyClass('name')}
                                />
                                {showErrors('name')}
                            </FormGroup>

                            <FormGroup label='Cantidad de elaboración' className='col-md-4'>
                                <Input
                                    id='processingQuantity' type='number' onChange={formik.handleChange} value={formik.values.processingQuantity}
                                    onBlur={formik.handleBlur} className={verifyClass('processingQuantity') + ' w-50'}
                                />
                                {showErrors('processingQuantity')}
                            </FormGroup>

                            <FormGroup label='Unidad de medida' className='col-md-3'>
                                <Select
                                    id='measureUnit' onChange={formik.handleChange}
                                    value={formik.values.measureUnit} list={MEASUREMENT_UNITS}
                                    className={verifyClass('measureUnit')}
                                    ariaLabel="measurement unit select" placeholder="Elegir ..."
                                />
                                {showErrors('measureUnit')}
                            </FormGroup>

                            <FormGroup label='Descripción' className='col-md-12'>
                                <textarea
                                    id='description' onChange={formik.handleChange} value={formik.values.description} rows={3}
                                    onBlur={formik.handleBlur} className={'form-control ' + verifyClass('description')}
                                />
                                {showErrors('description')}
                            </FormGroup>
                        </div>
                        <ElaborationIngredients ingredientsList={ingredientsList} setIngredientsList={setIngredientsList} formik={formikIngredients} />
                    </div>
                )}
            </CardBody>

            <CardFooter className="d-flex justify-content-center">
                <Button type="submit" size='lg' color='primary'>
                    {isLoading ? <Spinner isSmall /> : mode}
                </Button>
            </CardFooter>
        </form>
    )
}

export default ElaborationForm;