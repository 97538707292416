import Button from "../../components/bootstrap/Button";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import { useFormik } from "formik";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { CardBody, CardFooter, CardTitle } from "../../components/bootstrap/Card";
import useCompanies from "../../hooks/useCompanies";
import useIngredientsCategories from "../../hooks/useIngredientsCategories";
import Select from "../../components/bootstrap/forms/Select";
import { PrivilegeContext } from "../../components/priviledge/PriviledgeProvider";
import { MEASUREMENT_UNITS, MEASUREMENT_UNITS_V2 } from "../../utils/ingredients/ingredientsUtils";
import IngredientSuppliersForm from "./components/IngredientSuppliersForm";
import * as yup from "yup";
import useHasCompanyPermission from "../../hooks/useHasCompanyPermission";
import FormLabel from "../../components/bootstrap/forms/FormLabel";
import useIngredients from "../../hooks/useIngredients";
import { toast } from "react-toastify";

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    entityData?: any;
}

export interface IIngredientForm {
    id?: string;
    name?: string;
    averagePriceRange?: number | undefined;
    unitMeasureType?: string;
    visualizationUnitMeasure?: string;
    purchaseUnitMeasure?: string;
    ingredientCategoryId?: string;
    company?: string | undefined;
    supplierCodes?: SupplierData[];
}

export interface SupplierData {
    id: string;
    supplierName: string;
    supplierId: string;
    code: string;
    hasUnits: boolean;
    equivalentQuantity: number;
    equivalentMU: string;
}

const IngredientSchema = yup.object({
    name: yup.string().min(1, 'Demasido Corto').max(100, 'Demasiado Largo').required('Campo obligatorio'),
    averagePriceRange: yup.number().min(1, 'El mínimo es 1').max(1000, 'El máximo es 1000').required('Campo obligatorio'),
    visualizationUnitMeasure: yup.string().required('Campo obligatorio'),
    purchaseUnitMeasure: yup.string().required('Campo obligatorio'),
});

const CreateIngredientSchema = IngredientSchema.concat(
    yup.object({
        unitMeasureType: yup.string().required('Campo obligatorio'),
    }));

const IngredientForm: FC<CreateFormProps> = ({ isLoading, submit, entityData }) => {

    const { userCan } = useContext(PrivilegeContext);
    const { hasPermissions } = useHasCompanyPermission();
    const { getMeasurementUnits } = useIngredients();
    const { fetchIngredientsCategories, getIngredientsCategoriesList } = useIngredientsCategories();
    const { getCompanyList } = useCompanies();

    const mode = entityData ? 'Editar' : 'Crear';
    const entityInitialValues: IIngredientForm = {
        ingredientCategoryId: entityData?.ingredientCategoryId || '',
        name: entityData?.name || '',
        averagePriceRange: entityData?.averagePriceRange || 10,
        unitMeasureType: entityData?.unitMeasureType || '',
        visualizationUnitMeasure: entityData?.visualizationUnitMeasure || '',
        purchaseUnitMeasure: entityData?.purchaseUnitMeasure || '',
        supplierCodes: entityData?.supplierCodes || [],
        company: entityData?.company || '',
    };

    const [isMeasureUnitTypeSelected, setIsMeasureUnitTypeSelected] = useState<boolean>(false);
    const [suppliers, setSuppliers] = useState<{ label: string, value: string }[]>(entityData?.supplierCodes || []);
    const [supplierName, setSupplierName] = useState<any>();
    const [supplierCode, setSupplierCode] = useState<string>('');
    const [supplierHasUnits, setSupplierHasUnits] = useState<boolean>(false);
    const [supplierQuantity, setSupplierQuantity] = useState<number>(0);
    const [supplierUM, setSupplierUM] = useState<string>('');

    const formik = useFormik({
        initialValues: entityInitialValues,
        validationSchema: mode === 'Crear' ? CreateIngredientSchema : IngredientSchema,
        onSubmit: values => {
            if (supplierName !== undefined || supplierCode !== '') {
                const hasRequiredFields = () => supplierName && supplierCode;

                if (!hasRequiredFields()) {
                    toast.error('Por favor, rellena los campos de proveedor y código');
                    return;
                }

                if (supplierHasUnits && (!supplierQuantity || !supplierUM)) {
                    toast.error('Por favor, rellena los campos de cantidad y unidad de medida');
                    return;
                }

                const newSupplier = {
                    id: '',
                    supplierName: supplierName.label,
                    supplierId: supplierName.value,
                    code: supplierCode,
                    hasUnits: supplierHasUnits,
                    equivalentQuantity: supplierQuantity,
                    equivalentUM: supplierUM,
                };
                submit({ ...values, supplierCodes: [...suppliers, newSupplier] });
                return;
            }

            submit({ ...values, supplierCodes: suppliers });
        },
    });

    const verifyClass = (inputFieldID: keyof IIngredientForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof IIngredientForm) => {
        const error = formik.touched[inputFieldID] && formik.errors[inputFieldID];
        return (error ? <div className="invalid-feedback">{String(error)}</div> : <></>);
    };

    /* const formikSuppliers = useFormik<SupplierData>({
        initialValues: {
            id: '',
            supplierName: '',
            supplierId: '',
            code: '',
            hasUnits: false,
            equivalentQuantity: 0,
            equivalentMU: '',
        },
        validate: (values) => {
            const errors: any = {};
            if (values.supplierName === '') errors.supplierName = 'Campo obligatorio';
            if (values.code === '') errors.code = 'Campo obligatorio';
            if (values.hasUnits && values.equivalentQuantity === 0) errors.equivalentQuantity = 'Campo obligatorio';
            if (values.hasUnits && values.equivalentMU === '') errors.equivalentMU = 'Campo obligatorio';
            return errors;
        },
        onSubmit: (values) => { _handleAddSupplier(values) },
    }); */

    useEffect(() => {
        if (userCan('admin_company', 'companies') && formik.values.company) fetchIngredientsCategories(formik.values.company);
    }, [formik.values.company]);

    useEffect(() => {
        getMeasurementUnits(formik.values.unitMeasureType);
        if (mode === 'Crear' && formik.values.unitMeasureType !== '') {
            formik.setFieldValue('purchaseUnitMeasure', '');
        }
    }, [formik.values.unitMeasureType]);

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody className='row g-3 p-4'>
                    <CardTitle>Información general</CardTitle>

                    {userCan('admin_company', 'companies') &&
                        <FormGroup label='Organización' className='col-md-4'>
                            <Select
                                id='company' onChange={formik.handleChange}
                                value={formik.values.company} list={getCompanyList()}
                                className={verifyClass('company')}
                                ariaLabel='organization select' placeholder='Elegir organización ...'
                            />
                            {showErrors('company')}
                        </FormGroup>
                    }

                    {hasPermissions(formik.values.company) && (
                        <>
                            <FormGroup requiredInputLabel label='Nombre' className='col-md-4'>
                                <Input
                                    id='name' required onChange={formik.handleChange} value={formik.values.name}
                                    onBlur={formik.handleBlur} className={verifyClass('name')}
                                />
                                {showErrors('name')}
                            </FormGroup>

                            <FormGroup requiredInputLabel label='Categoría' className='col-md-3'>
                                <Select
                                    id='ingredientCategoryId' required onChange={formik.handleChange}
                                    value={formik.values.ingredientCategoryId}
                                    list={getIngredientsCategoriesList()}
                                    className={verifyClass('ingredientCategoryId')}
                                    placeholder='Elegir categoría ...' ariaLabel='Elegir categoría ...'
                                />
                                {showErrors('ingredientCategoryId')}
                            </FormGroup>

                            <div className="col-md-3">
                                <>
                                    <div className="row">
                                        <FormLabel
                                            label='Últimas compras'
                                            tooltip icon="Info"
                                            tooltipLabel="El número introducido servirá para calcular el P.M (precio medio) de las últimas X compras (albaranes)"
                                        />
                                    </div>
                                    <Input id='averagePriceRange' type='number' onChange={formik.handleChange}
                                        value={formik.values.averagePriceRange} onBlur={formik.handleBlur}
                                        className={verifyClass('averagePriceRange') + ' w-50'}
                                    />
                                    {showErrors('averagePriceRange')}
                                </>
                            </div>

                            <FormGroup requiredInputLabel label='Unidad medida visualización' className='col-md-3'>
                                <Select
                                    id='visualizationUnitMeasure' required
                                    onChange={(e: any) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue('visualizationUnitMeasure', e.target.value);
                                        if (MEASUREMENT_UNITS_V2.mass.map(e => e.value).includes(e.target.value)) formik.setFieldValue('unitMeasureType', 'mass');
                                        else if (MEASUREMENT_UNITS_V2.volume.map(e => e.value).includes(e.target.value)) formik.setFieldValue('unitMeasureType', 'volume');
                                        else if (MEASUREMENT_UNITS_V2.unit.map(e => e.value).includes(e.target.value)) formik.setFieldValue('unitMeasureType', 'unit');
                                        setIsMeasureUnitTypeSelected(true);
                                    }}
                                    value={formik.values.visualizationUnitMeasure}
                                    list={MEASUREMENT_UNITS}
                                    className={verifyClass('visualizationUnitMeasure')}
                                    placeholder='Elegir unidad de medida ...' ariaLabel='Elegir Medida...'
                                />
                                {showErrors('visualizationUnitMeasure')}
                            </FormGroup>

                            <FormGroup requiredInputLabel label='Unidad medida adquisición' className='col-md-3'>
                                <Select
                                    id='purchaseUnitMeasure' required onChange={formik.handleChange}
                                    disabled={mode === 'Crear' && !isMeasureUnitTypeSelected}
                                    value={formik.values.purchaseUnitMeasure}
                                    list={getMeasurementUnits(formik.values.unitMeasureType)}
                                    className={verifyClass('purchaseUnitMeasure')}
                                    placeholder='Elegir unidad de medida ...' ariaLabel='Elegir Medida...'
                                />
                                {showErrors('purchaseUnitMeasure')}
                            </FormGroup>

                            <IngredientSuppliersForm
                                suppliers={suppliers} setSuppliers={setSuppliers}

                                supplierName={supplierName} setSupplierName={setSupplierName}
                                supplierCode={supplierCode} setSupplierCode={setSupplierCode}
                                supplierHasUnits={supplierHasUnits} setSupplierHasUnits={setSupplierHasUnits}
                                supplierQuantity={supplierQuantity} setSupplierQuantity={setSupplierQuantity}
                                supplierUM={supplierUM} setSupplierUM={setSupplierUM}

                                unitMeasureType={formik.values.unitMeasureType}
                            />
                        </>
                    )}
                </CardBody>

                <CardFooter className="d-flex justify-content-center">
                    <Button type="submit" size='lg' color='primary'>
                        {isLoading ? <Spinner isSmall /> : `${mode} Ingrediente`}
                    </Button>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default IngredientForm;