import { Fragment } from "react";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import IngredientCategoriesList from "./category-list/IngredientCategoriesList";

export const IngredientCategoriesListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider>
                <IngredientCategoriesList />
            </FiltersProvider>
        </Fragment>
    )
}