import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';
import { CreateElaborationFieldsModel, EditElaborationFieldsModel } from '../../type/elaboration-type';

const ELABORATION_ENDPOINT = '/elaborations';
export class ElaborationService extends RestServiceConnection {

    /**
     * ------------------------------------------------------------------------
     * EN: ELABORATION REQUEST SERVICE
     * ES: SERVICIO DE ELABORACIONES
     * ------------------------------------------------------------------------
     */

    getElaborations = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ELABORATION_ENDPOINT + '/list-elaborations',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getElaborationById = async (elaborationId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ELABORATION_ENDPOINT + '/get-elaboration',
            data: { elaborationId }
        }, true);
        return this;
    }

    createElaboration = async (values: CreateElaborationFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ELABORATION_ENDPOINT + '/create-elaboration',
            data: values,
        }, true);
        return this;
    }

    editElaboration = async (values: EditElaborationFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ELABORATION_ENDPOINT + '/edit-elaboration',
            data: values,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteElaboration = async (elaborationId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ELABORATION_ENDPOINT + '/delete-elaboration',
            data: { elaborationId }
        }, true);
        return this;
    }

    getCostsGraph = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: ELABORATION_ENDPOINT + '/elaboration-cost-graph',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

}