import React from 'react';
import { KTSVG } from '../KTSVG';

interface SpinnerLogoProps {
    loading?: boolean;
    height?: string;
}

const SpinnerLogo: React.FC<SpinnerLogoProps> = ({ loading, height }) => {
    return (
        loading
            ? <div className='d-flex justify-content-center align-items-center' style={{ height: height || '100vh' }}>
                <div>
                    <KTSVG path={'/media/loading_name.svg'} />
                </div>
            </div>
            : null
    );
};

export default SpinnerLogo;

export const Loader = ({ height }: SpinnerLogoProps) => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ height: height || '80vh' }}>
            <div>
                <KTSVG path={'/media/loading_name.svg'} />
            </div>
        </div>
    );
};