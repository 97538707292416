import React from 'react';
import FormGroup from './bootstrap/forms/FormGroup';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from './bootstrap/Dropdown';
import Button from './bootstrap/Button';

interface PriceDropdownProps {
    filters: any;
    updateFilters: (filters: any) => void;
    label?: string;
    className?: any;
}

const PriceDropdown: React.FC<PriceDropdownProps> = ({ filters, updateFilters, label, className }) => {
    return (
        <FormGroup label={label} className={className}>
            <Dropdown>
                <DropdownToggle>
                    <Button color='primary' isLight className='px-3 py-0'>
                        {filters.filter_filters?.show_by === "realCost"
                            ? "Coste real"
                            : filters.filter_filters?.show_by === "averagePrice"
                                ? "Precio medio"
                                : "Precio medio ponderado"
                        }
                    </Button>
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd>
                    <DropdownItem>
                        <Button color='link' onClick={() => updateFilters({ show_by: 'realCost' })}>Coste real</Button>
                    </DropdownItem>
                    <DropdownItem>
                        <Button color='link' onClick={() => updateFilters({ show_by: 'averagePrice' })}>Precio medio</Button>
                    </DropdownItem>
                    <DropdownItem>
                        <Button color='link' onClick={() => updateFilters({ show_by: 'weightedAveragePrice' })}>Precio medio ponderado</Button>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </FormGroup>
    );
};

export default PriceDropdown;