import { useCallback, useEffect, useState } from 'react';
import { IngredientService } from '../services/ingredients/ingredientService';
import useHandleErrors from './useHandleErrors';
import useFilters from './useFilters';

//------------------------------------------------------------------------
/**
* 
* EN: Hook to manage ingredients suppliers fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de proveedores de ingredientes.
*
*/
//----------------------------------------------------------------------

const useIngredientSuppliers = () => {

  const { handleErrors } = useHandleErrors();
  const { filters } = useFilters({}, [], 1, 1000);

  const [ingredientSuppliers, setIngredientSuppliers] = useState<any>([]);

  const fetchIngredientSuppliers = useCallback(async (companyId?: string) => {
    try {
      const response = await (new IngredientService()).getIngredientsSuppliers(filters);
      const fetchedData = response.getResponseData() as any;

      if (fetchedData && fetchedData.data.suppliers) {
        const mappedData = fetchedData.data.suppliers.map((entity: { id: any; name: any; }) => ({
          value: entity.id,
          label: entity.name,
          id: ''
        }));
        setIngredientSuppliers(mappedData);
      } else {
        handleErrors(response);
      }
    } catch (error) {
      console.log('Error fetching suppliers:', error);
    }
  }, [filters]);

  useEffect(() => {
    fetchIngredientSuppliers();
  }, []);

  const getIngredientSuppliersList = () => {
    return ingredientSuppliers;
  };

  return { ingredientSuppliers, fetchIngredientSuppliers, getIngredientSuppliersList };
}

export default useIngredientSuppliers;