import { useNavigate, useParams } from "react-router-dom";
import { IngredientService } from "../../../services/ingredients/ingredientService";
import { toast } from "react-toastify";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { useCallback, useState } from "react";
import { EditCategoryFieldsModel, IngredientCategory } from "../../../type/ingredient-type";
import useFetch from "../../../hooks/useFetch";
import CategoryForm from "../IngredientCategoryForm";
import Modal, { ModalBody, ModalHeader } from "../../../components/bootstrap/Modal";
import { CardTitle } from "../../../components/bootstrap/Card";
import { Category } from "../../../components/icon/material-icons";
import Button from "../../../components/bootstrap/Button";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";

const IngredientCategoryEdit = () => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const ingredientService = new IngredientService();

    const [loading, setLoading] = useState<boolean>(false);

    const fetchEntity = useCallback(async () => {
        const response = await ingredientService.getCategoryById(id as string);
        return response.getResponseData() as IngredientCategory;
    }, [id]);
    const [entity] = useFetch(fetchEntity);

    const handleUpdate = async (values: EditCategoryFieldsModel) => {
        setLoading(true);

        values.ingredientCategoryId = id;

        try {
            let response = (await ingredientService.editIngredientCategory(values)).getResponseData();
            if (response.success) {
                toast.success(response.message);
                navigate(-1);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar la categoría de ingrediente');
        } finally {
            setLoading(false);
        }
    };

    const getContent = () => {
        if (loading) return <Loader height="20vh" />;

        if (entity !== null) {
            const data = {
                ...entity,
                company: entity.company?.id || null,
                ingredientCategoryId: entity.ingredientCategories?.id,
                name: entity.name || '',
                description: entity.description || '',
            };

            return <CategoryForm isLoading={loading} submit={handleUpdate} data={data} />;
        }
    };

    return (
        <>
            <Modal isOpen={true} setIsOpen={() => (true)} size='md' titleId='Editar categoría'>
                <ModalHeader className='ms-2 p-4 gap-4'>
                    <Category fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                    <CardTitle className="fs-3">Editar categoría de Ingrediente</CardTitle>
                    <Button className='btn-close fs-5 p-4' onClick={() => navigate(-1)} />
                </ModalHeader>
                <hr className="mt-0" />
                <ModalBody className='px-4'>
                    <>{getContent()}</>
                </ModalBody>
            </Modal>
        </>
    )
}

export default IngredientCategoryEdit;