import { FC, Fragment } from "react";
import { Supplier } from "../../type/supplier-type";
import * as yup from 'yup';
import { validateCifNif } from "../../utils/cifNifValidator";
import InputPhone from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../components/bootstrap/Card";
import Page from "../../layout/Page/Page";
import Spinner from "../../components/bootstrap/Spinner";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import SvgAccountCircle from "../../components/icon/material-icons/AccountCircle";
import { SubheaderSeparator } from "../../layout/SubHeader/SubHeader";

interface SupplierFormProps {
    submit: (values: any) => void;
    isLoading: boolean;
    supplierData?: Supplier;
}

const schema = yup.object({
    name: yup.string().required('El nombre es obligatorio').max(100, 'No puede tener más de 100 caracteres'),
    postalCode: yup.string().matches(/^[0-9]{5}$/, 'El código postal debe tener 5 dígitos'),
    telephone: yup.string().required('El teléfono es obligatorio'),
    email: yup.string().email('El email no es válido').required('El email es obligatorio'),
    cifNif: yup.string().test('valid-cif-nif', 'El CIF/DNI no es válido', (value) => {
        if (typeof value === 'string') {
            return validateCifNif(value);
        } else {
            return true;
        }
    }).required('El CIF/DNI es obligatorio'),
    contactPersonName: yup.string().matches(/^[a-zA-ZáéíóúüÁÉÍÓÚÜñÑ.,\s.0-9]+$/u, 'Formato incorrecto').required('El nombre es obligatorio'),
    contactPersonTelephone: yup.string().required('El teléfono es obligatorio'),
    contactPersonPosition: yup.string().required('El cargo es obligatorio'),
});

const SupplierForm: FC<SupplierFormProps> = ({ submit, isLoading, supplierData }) => {

    const navigate = useNavigate();
    const mode = supplierData ? 'Editar' : 'Crear';

    const initialValues: Supplier = {
        id: supplierData?.id || '',
        name: supplierData?.name || '',
        address: supplierData?.address || '',
        population: supplierData?.population || '',
        postalCode: supplierData?.postalCode || '',
        province: supplierData?.province || '',
        telephone: supplierData?.telephone || '',
        telephoneSecondary: supplierData?.telephoneSecondary || '',
        email: supplierData?.email || '',
        cifNif: supplierData?.cifNif || '',
        contactPersonName: supplierData?.contactPersonName || '',
        contactPersonTelephone: supplierData?.contactPersonTelephone || '',
        contactPersonPosition: supplierData?.contactPersonPosition || '',
        company: supplierData?.company?.id || '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schema,
        onSubmit: values => submit(values)
    });

    const verifyClass = (inputFieldID: keyof Supplier) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof Supplier) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? (<div className="invalid-feedback">{String(formik.errors[inputFieldID])}</div>) : (<></>);
    };

    return (
        <Fragment>
            <div className="m-auto">
                <Page container='xxl'>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <Card stretch={true}>
                            <CardHeader borderSize={1} className="d-flex justify-content-start">
                                <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                                <SubheaderSeparator className="mx-3" />
                                <SvgAccountCircle fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                                <CardTitle className="fs-3 mb-0 ms-3">Crear Proveedor</CardTitle>
                            </CardHeader>
                            <CardBody isScrollable={false}>
                                <CardTitle>Información general</CardTitle>
                                <div className="row mt-4">
                                    <FormGroup label='Nombre y apellidos:' requiredInputLabel className="col-md-4">
                                        <Input id='name' onChange={formik.handleChange} value={formik.values.name} className={verifyClass('name')} />
                                        {showErrors('name')}
                                    </FormGroup>
                                </div>

                                <div className="row mt-3">
                                    <FormGroup label='Email:' requiredInputLabel className="col-md-4">
                                        <Input id='email' onChange={formik.handleChange} value={formik.values.email} className={verifyClass('email')} />
                                        {showErrors('email')}
                                    </FormGroup>
                                    <FormGroup label='CIF/NIF:' requiredInputLabel className="col-md-2">
                                        <Input id='cifNif' onChange={formik.handleChange} value={formik.values.cifNif} className={verifyClass('cifNif')} />
                                        {showErrors('cifNif')}
                                    </FormGroup>
                                    <FormGroup label='Teléfono:' requiredInputLabel className="col-md-3">
                                        <InputPhone
                                            country={'es'}
                                            onChange={(value) => formik.setFieldValue('telephone', value)}
                                            value={formik.values.telephone}
                                            inputClass={verifyClass('telephone')}
                                            inputStyle={{ backgroundColor: '#f8f9fa' }}
                                        />
                                        {showErrors('telephone')}
                                    </FormGroup>
                                    <FormGroup label='Teléfono secundario:' className="col-md-3">
                                        <InputPhone
                                            country={'es'}
                                            onChange={(value) => formik.setFieldValue('telephoneSecondary', value)}
                                            value={formik.values.telephoneSecondary}
                                            inputClass={verifyClass('telephoneSecondary')}
                                            inputStyle={{ backgroundColor: '#f8f9fa' }}
                                        />
                                        {showErrors('telephoneSecondary')}
                                    </FormGroup>
                                </div>

                                <div className="row mt-3">
                                    <FormGroup label='Domicilio:' className="col-md-5">
                                        <Input id='address' onChange={formik.handleChange} value={formik.values.address} className={verifyClass('address')} />
                                        {showErrors('address')}
                                    </FormGroup>
                                    <FormGroup label='Provincia:' className="col-md-2">
                                        <Input id='province' onChange={formik.handleChange} value={formik.values.province} className={verifyClass('province')} />
                                        {showErrors('province')}
                                    </FormGroup>
                                    <FormGroup label='Población:' className="col-md-3">
                                        <Input id='population' onChange={formik.handleChange} value={formik.values.population} className={verifyClass('population')} />
                                        {showErrors('population')}
                                    </FormGroup>
                                    <FormGroup label='CP:' className="col-md-2">
                                        <Input id='postalCode' onChange={formik.handleChange} value={formik.values.postalCode} className={verifyClass('postalCode')} />
                                        {showErrors('postalCode')}
                                    </FormGroup>
                                </div>

                                <CardTitle className="mt-5">Persona de contacto</CardTitle>
                                <div className="row mt-4">
                                    <FormGroup label='Nombre:' requiredInputLabel className="col-md-4">
                                        <Input id='contactPersonName' onChange={formik.handleChange} value={formik.values.contactPersonName} className={verifyClass('contactPersonName')} />
                                        {showErrors('contactPersonName')}
                                    </FormGroup>
                                    <FormGroup label='Teléfono:' requiredInputLabel className="col-md-3">
                                        <InputPhone
                                            country={'es'}
                                            onChange={(value) => formik.setFieldValue('contactPersonTelephone', value)}
                                            value={formik.values.contactPersonTelephone}
                                            inputClass={verifyClass('contactPersonTelephone')}
                                            inputStyle={{ backgroundColor: '#f8f9fa' }}
                                        />
                                        {showErrors('contactPersonTelephone')}
                                    </FormGroup>
                                    <FormGroup label='Cargo:' requiredInputLabel className="col-md-3">
                                        <Input id='contactPersonPosition' onChange={formik.handleChange} value={formik.values.contactPersonPosition} className={verifyClass('contactPersonPosition')} />
                                        {showErrors('contactPersonPosition')}
                                    </FormGroup>
                                </div>
                            </CardBody>

                            <CardFooter className="d-flex justify-content-center">
                                <Button type="submit" size='lg' color='primary'>
                                    {isLoading ? <Spinner isSmall /> : `${mode} Proveedor`}
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </Page>
            </div>
        </Fragment>
    )
}

export default SupplierForm;