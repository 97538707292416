import { useNavigate, useParams } from "react-router-dom";
import { DeliveryNoteService } from "../../../services/delivery-notes/deliveryNoteService";
import { Fragment, useCallback, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { DeliveryNoteApiResponse } from "../../../type/deliveryNote-type";
import Button from "../../../components/bootstrap/Button";
import Card, { CardBody, CardHeader } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import moment from "moment";
import 'moment/locale/es';
import { phoneFormatter } from "../../../utils/strings/phoneFormatter";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { financialMenu } from "../../../menu";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import useHandleErrors from "../../../hooks/useHandleErrors";
import classNames from "classnames";
import { FixNumber } from "../../../utils/fixNumber";

const DeliveryNoteView = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const service = new DeliveryNoteService();

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getDeliveryNoteById(id as string);
        return response.getResponseData() as DeliveryNoteApiResponse;
    }, [id]));

    const _handleDelete = async () => {
        try {
            const response = await (await service.deleteDeliveryNote(id)).getResponseData();
            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success("Albarán eliminado correctamente");
                }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            handleErrors(error);
        }
    }

    if (loading) return <Loader />;
    if (!data) return null;

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                </SubHeaderLeft>
                <SubHeaderRight>
                    {userCan('edit', 'delivery_notes') && <Button color='primary' isLink icon='Edit' onClick={() => navigate(`${financialMenu.deliveryNotes.path}/${id}/edit`)} />}
                    {userCan('edit', 'delivery_notes') && userCan('delete', 'delivery_notes') && <SubheaderSeparator />}
                    {userCan('delete', 'delivery_notes') && (
                        <Button
                            color='primary' isLink icon='Delete'
                            onClick={() => {
                                handleConfirmationAlert({
                                    title: "Eliminar albarán",
                                    text: "Esta acción es irreversible. ¿Estás seguro de que quieres eliminar este albarán?",
                                    icon: "warning",
                                    onConfirm: _handleDelete
                                })
                            }}
                        />
                    )}
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={true} className="col-md-6 m-auto p-3">
                    <CardHeader className="d-flex align-items-end flex-column">
                        <div className="d-flex flex-row align-items-center fs-4 fw-bold">
                            Albarán <div className="bg-warning rounded px-3 py-1 ms-2">Nº {data.deliveryNoteNumber}</div>
                        </div>
                        <span className="fs-5 mt-2">{moment(data.deliveryNoteDate?.date).format('LL')}</span>
                    </CardHeader>

                    <CardBody>
                        <div className="row rounded p-3" style={{ border: '1px solid lightgrey' }}>
                            <div className="col-md-8">
                                <div className="d-flex flex-column">
                                    <span className="fw-bold">PROVEEDOR</span>
                                    <span>{data.supplier?.name}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="d-flex flex-column">
                                    <span className="fw-bold">CIF/DNI</span>
                                    <span>{data.supplier?.cifNif}</span>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex flex-column">
                                    <span className="fw-bold">DIRECCIÓN</span>
                                    <span>{data.supplier?.address}, {data.supplier?.population}, {data.supplier?.province}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="d-flex flex-column">
                                    <span className="fw-bold">TELÉFONO</span>
                                    <span>{phoneFormatter(data.supplier?.contactPersonTelephone)}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <table className="table table-bordered table-dark">
                                <thead className="bg-light">
                                    <tr>
                                        <th className="text-center">REFERENCIA</th>
                                        <th className="text-center">CANTIDAD</th>
                                        <th className="text-center">DESCUENTO</th>
                                        <th className="text-center">PRECIO UNITARIO</th>
                                        <th className="text-center">IMPORTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.deliveryNoteLines?.map((line: any, index: number) => {
                                        const filteredSupplierCode = line.ingredient?.ingredientHasSupplierCode.filter((item: any) => item.supplier.id === data.supplier.id);

                                        const unitMeasure = filteredSupplierCode[0].hasUnits ? ' u.' : line.measureUnit;

                                        return (
                                            <tr key={index}>
                                                <td className="text-center">{line.ingredient?.name}</td>
                                                <td className="text-center">{line.quantity}{unitMeasure}</td>
                                                <td className="text-center">{line.discount} %</td>
                                                <td className="text-center">{FixNumber((line.price / line.quantity) * (1 - (line.discount / 100)))} €</td>
                                                <td className="text-center">{FixNumber(line.price)} €</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={4} className="text-end fw-bold">IMPORTE TOTAL</td>
                                        <td className="text-center fw-bold">{FixNumber(data.totalPrice)} €</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className={classNames("row rounded p-3 mt-4", { 'd-none': data.comments === '' })} style={{ border: '1px solid lightgrey' }}>
                            <span className="fw-bold">COMENTARIOS</span>
                            <div className="mt-2">{data.comments}</div>
                        </div>
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    )
}

export default DeliveryNoteView;