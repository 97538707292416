import { useContext, useCallback, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import useFetch from "../../../hooks/useFetch";
import { DishService } from "../../../services/dishes/dishService";
import { DishesApiResponse } from "../../../type/dish-type";
import moment from "moment";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import { CustomTable } from "../../../components/table/CustomTable";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { userIsSuperAdmin } from "../../../utils/userIsSuperAdmin";
import ErrorMessage from "../../../components/ErrorMessage";
import StatusDropdown from "../../../components/StatusDropdown";
import DishesFilters from "./dishes-options/DishesFilters";
import { dishesMenu } from "../../../menu";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import { FixNumber } from "../../../utils/fixNumber";

const DishesList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();
    const dishService = new DishService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await dishService.getDishes(filters);
        return response.getResponseData() as DishesApiResponse;
    }, [filters]));

    /**
     * Delete a dish with the given ID.
     *
     * @param {string} id - The ID of the dish to be deleted.
     */
    const deleteDish = async (id: string) => {
        let response = (await dishService.deleteDish(id)).getResponseData();
        if (response.success) {
            toast.success("Plato eliminado correctamente");
            refetch();
        }
    };

    /**
     * Toggles the status of a dish.
     *
     * @param {string} id - The ID of the dish.
     * @param {boolean} status - The new status of the dish.
     * @param {Function} toggleStatus - A function to toggle the status.
     * @return {void}
     */
    const toggleDishStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus([...changingStatus, id]);
            let response = await (await new DishService().toggleDishStatus(id)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toggleStatus(status);
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
        }
    };

    /**
     * Export dishes to Excel.
     *
     * @return {void}
     */
    const handleExport = async () => {
        try {
            const response = await (new DishService()).exportDishes({ ...filters, limit: 9999999 });
            if (response) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'lista_platos.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar los platos');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    /**
     * Converts a dish to an elaboration.
     *
     * @param {string} id - The ID of the dish.
     * @return {void}
     */
    const _handleConvertToElaboration = async (id: string) => {
        let response = (await dishService.convertToElaboration(id, 10)).getResponseData();
        if (response.success) {
            toast.success("Plato convertido a elaboración");
            refetch();
        }
    };

    /**
     * Get the ingredients or elaborations of a dish.
     *
     * @param {any} dish - The dish.
     * @return {any}
     */
    const getDishIngredients = (dish: any) => {
        if (dish.dishHasElaboration.length === 0 && dish.dishHasIngredient.length > 0) return (
            dish.dishHasIngredient?.map((ingredient: any) => {
                return (
                    <span key={ingredient.id}>
                        {ingredient.ingredient?.name}
                    </span>
                );
            })).reduce((prev: any, curr: any) => [prev, ', ', curr])

        if (dish.dishHasElaboration.length > 0 && dish.dishHasIngredient.length === 0) return (
            dish.dishHasElaboration?.map((elaboration: any) => {
                return (
                    <span key={elaboration.id}>
                        {elaboration.elaborations?.name}
                    </span>
                );
            })).reduce((prev: any, curr: any) => [prev, ', ', curr]
            );

        if (dish.dishHasElaboration.length > 0 && dish.dishHasIngredient.length > 0) return (
            dish.dishHasElaboration?.map((elaboration: any) => {
                return (
                    <span key={elaboration.id}>
                        {elaboration.elaborations?.name}
                    </span>
                );
            }).concat(dish.dishHasIngredient?.map((ingredient: any) => {
                return (
                    <span key={ingredient.id}>
                        {ingredient.ingredient?.name}
                    </span>
                );
            })).reduce((prev: any, curr: any) => [prev, ', ', curr])
        );

        return "N/A";
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardTitle>Listado de Platos</CardTitle>
                        {userCan("create", "dishes") &&
                            <>
                                <SubheaderSeparator />
                                <Button color="light" icon="Add" isLight onClick={() => { navigate("create") }}>
                                    Añadir Plato
                                </Button>
                            </>
                        }
                        <SubheaderSeparator />
                        <Button color="light" isLight title="Exportar platos a excel" icon="Download" onClick={handleExport}>
                            Exportar
                        </Button>
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <DishesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={false}>
                    {error && <ErrorMessage />}

                    {(data && data.dishes)
                        ? (
                            <CustomTable
                                data={data?.dishes ? data.dishes : null}
                                pagination={true}
                                paginationData={{
                                    pageSize: filters.limit,
                                    currentPage: filters.page,
                                    pageCount: (data as DishesApiResponse) ? data.lastPage : 1,
                                    totalCount: data.totalRegisters,
                                    handlePagination: updatePage,
                                    handlePerPage: updatePageSize,
                                }}
                                defaultLimit={filters.limit || 50}
                                defaultOrder={filters.filter_order || undefined}
                                className={"table table-hover"}
                                columns={[
                                    {
                                        name: "Nombre",
                                        keyValue: "name",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="text-center cursor-pointer fw-bold" onClick={() => navigate(`${dishesMenu.dishes.path}/${element.id}/view`)}>
                                                    {element.name}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "TPV",
                                        keyValue: "tpv_code",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="text-center">
                                                    {element.tpvCode}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Categoría",
                                        keyValue: "dish_category",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="text-center">
                                                    {element.dishCategory.name}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Ingredientes",
                                        keyValue: "ingredients",
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="text-center">
                                                    {getDishIngredients(element)}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Precio medio (P.M)",
                                        keyValue: "average_price",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {FixNumber(element.averagePrice)} €
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "P.M ponderado",
                                        keyValue: "weighted_average_price",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {FixNumber(element.weightedAveragePrice)} €
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Último precio",
                                        keyValue: "last_price",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {FixNumber(element.lastPrice)} €
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Usuario",
                                        keyValue: "user",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {`${element.user.name} ${element.user.lastName || ''}` ?? "N/A"}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Organización",
                                        keyValue: "company",
                                        isVisible: userIsSuperAdmin(),
                                        sortable: false,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <span>
                                                    {element?.company?.name ?? "N/A"}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        name: "Fecha de creación",
                                        keyValue: "created_at",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <div className={"text-center"}>
                                                    <span className={"text-muted"}>
                                                        {moment(element.createdAt.date).format(
                                                            "DD-MM-YYYY"
                                                        ) ?? "N/A"}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Estado",
                                        keyValue: "active",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className={"text-center"} key={element.id}>
                                                    <StatusDropdown
                                                        disabled={changingStatus.includes(element.id)}
                                                        itemId={element.id}
                                                        status={element.active}
                                                        change={toggleDishStatus}
                                                    />
                                                </div>
                                            );
                                        },
                                    },
                                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
                                ]}
                                actions={[
                                    {
                                        title: 'Convertir a elaboración',
                                        buttonType: "icon",
                                        iconColor: "text-dark",
                                        iconPath: "/media/icons/duotune/general/gen023.svg",
                                        description: "Convertir a elaboración",
                                        hide: (item: any) => item.isConverted,
                                        callback: (item: any) => {
                                            handleConfirmationAlert({
                                                title: "Convertir a elaboración",
                                                text: "Si conviertes el plato a elaboración, esta se podrá añadir dentro de otros platos",
                                                icon: "info",
                                                onConfirm: () => { _handleConvertToElaboration(item.id) }
                                            })
                                        }
                                    },
                                    {
                                        title: "Editar",
                                        buttonType: "icon",
                                        iconColor: "text-info",
                                        iconPath: "/media/icons/duotune/general/gen055.svg",
                                        additionalClasses: "text-primary",
                                        description: "Editar plato",
                                        hide: () => !userCan('edit', 'dishes'),
                                        callback: (item: any) => { navigate(`${item.id}/edit`) },
                                    },
                                    {
                                        title: "Eliminar",
                                        buttonType: "icon",
                                        iconColor: "text-danger",
                                        iconPath: "/media/icons/duotune/general/gen027.svg",
                                        additionalClasses: "text-danger",
                                        description: "Eliminar plato",
                                        hide: () => !userCan('delete', 'dishes'),
                                        callback: (item: any) => {
                                            handleConfirmationAlert({
                                                title: "Eliminar plato",
                                                text: "Esta acción eliminará el histórico y la trazabilidad de este plato",
                                                icon: "warning",
                                                onConfirm: () => { deleteDish(item.id) },
                                            });
                                        },
                                    },
                                ]}
                            />
                        )
                        : !error && <Loader />
                    }
                </Card>
            </Page>
        </Fragment>
    )
}

export default DishesList;