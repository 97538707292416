import { Fragment } from "react";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import SuppliersList from "./suppliers-list/SuppliersList";

export const SuppliersListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider>
                <SuppliersList />
            </FiltersProvider>
        </Fragment>
    )
}

export default SuppliersListWrapper;