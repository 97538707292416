import React, { useEffect, useState } from 'react';
import { useDashboard } from '../provider/DashboardProvider';
import { FixNumber } from '../../../utils/fixNumber';
import { CardTitle } from '../../../components/bootstrap/Card';
import classNames from 'classnames';
import PriceDropdown from '../../../components/PriceDropdown';
import CustomAreaGraph from '../../../components/graphs/CustomAreaGraph';

interface DashboardGraphsProps {
    sales?: boolean;
    profits?: boolean;
    comparePage?: boolean;
}

const DashboardGraphs: React.FC<DashboardGraphsProps> = ({ sales, profits }) => {

    const { filters, updateFilters, totalSales, totalProfits, totalSalesCounter, totalProfitsCounter } = useDashboard();

    const [series, setSeries] = useState<any[]>([{ data: [] }]);

    // Update series when sales or profits change
    useEffect(() => {
        // Combine data and avoid unnecessary re-renders
        const combinedData = (sales && totalSales) || (profits && totalProfits)
            ? sales
                ? totalSales?.[0]?.salesTotal?.map((item: any) => ({ x: item.from?.date, y: item.totalSalesPrice }))
                : totalProfits?.[0]?.salesTotal?.map((item: any) => ({ x: item.from?.date, y: item.totalProfits }))
            : [];

        // Update series only if combinedData has changed
        if (JSON.stringify(combinedData) !== JSON.stringify(series[0]?.data)) {
            setSeries([{ data: combinedData }]);
        }
    }, [sales, profits, totalSales, totalProfits]);

    if ((sales && !totalSales) || (profits && !totalProfits)) return null;

    return (
        <>
            {(totalSalesCounter || totalProfitsCounter) && (
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <CardTitle className='text-center'>{sales ? 'Ventas totales' : 'Beneficios totales'} ({FixNumber(totalSalesCounter || totalProfitsCounter)} €)</CardTitle>

                    <PriceDropdown filters={filters} updateFilters={updateFilters} className={classNames({ 'd-none': !profits })} />
                </div>
            )}

            {(totalSales || totalProfits) && <CustomAreaGraph chartId={sales ? 'totalSalesGraph' : 'totalProfitsGraph'} series={series} />}
        </>
    );
};

export default DashboardGraphs;