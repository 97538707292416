import { Fragment, useCallback, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, { CardActions, CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { useNavigate, useParams } from "react-router-dom";
import { DishService } from "../../../services/dishes/dishService";
import { DishApiResponse, EditDishFieldsModel } from "../../../type/dish-type";
import useFetch from "../../../hooks/useFetch";
import StatusSwitch from "../../../components/StatusSwitch";
import { toast } from "react-toastify";
import DishForm from "../DishForm";
import { adminMenu } from "../../../menu";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { SetMeal } from "../../../components/icon/material-icons";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";

const EditDish = () => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const dishService = new DishService();

    const [loading, setLoading] = useState<boolean>(false);
    const [changingStatus, setChangingStatus] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        const response = await dishService.getDishById(id as string);
        return response.getResponseData() as DishApiResponse;
    }, [id]));

    const handleUpdate = async (values: EditDishFieldsModel) => {
        setLoading(true);
        try {
            let response = (await dishService.editDish({ ...values, dishId: id })).getResponseData();
            if (response.success) {
                toast.success(response.message);
                navigate(adminMenu.dishes.path, { replace: true });
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar el plato');
        } finally {
            setLoading(false);
        }
    };

    const toggleUserStatus = async (id: string, status: boolean, toggleStatus: Function) => {
        try {
            setChangingStatus(true);
            let response = (await dishService.toggleDishStatus(id)).getResponseData();
            if (response.success) {
                toggleStatus(status);
            }
        } catch (error: any) {
            toast.error('Error al cambiar el estado del plato');
        } finally {
            setChangingStatus(false);
        }
    };

    const getContent = () => {
        if (loading) return <Loader />;

        if (data !== null) {
            const dishData = {
                ...data,
                dishCategoryId: data?.dishCategory?.id,
                dishIngredients: data?.dishHasElaboration.map((ingredient: any) => {
                    return {
                        id: ingredient.id,
                        type: 'elaboration',
                        typeId: ingredient.elaborations.id,
                        quantity: ingredient.quantity,
                        measureUnit: ingredient.measureUnit,
                    }
                }, []).concat(data?.dishHasIngredient.map((ingredient: any) => {
                    return {
                        id: ingredient.id,
                        type: 'ingredient',
                        typeId: ingredient.ingredient.id,
                        quantity: ingredient.quantity,
                        measureUnit: ingredient.measureUnit,
                    }
                }, [])),
                company: null,
            };

            return (
                <Fragment>
                    <CardHeader borderSize={1} className="d-flex justify-content-between">
                        <div className="d-flex">
                            <SetMeal fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                            <CardTitle className="fs-3 mb-0 ms-3">Editar Plato</CardTitle>
                        </div>
                        <CardActions>
                            <StatusSwitch itemId={data.id} status={data.active} change={toggleUserStatus} />
                        </CardActions>
                    </CardHeader>
                    <DishForm isLoading={loading} submit={handleUpdate} data={dishData} />
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                </SubHeaderLeft>
            </SubHeader>
            <Page container="xxl">
                <Card stretch={true}>
                    <>{getContent()}</>
                </Card>
            </Page>
        </Fragment>
    )
}

export default EditDish;