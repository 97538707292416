import React, { useState } from 'react';
import { useDashboard } from '../provider/DashboardProvider';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { Dropdown, FormControl } from 'react-bootstrap';
import Button from '../../../components/bootstrap/Button';
import Select from '../../../components/bootstrap/forms/Select';
import useDishesCategories from '../../../hooks/useDishCategories';
import { useMain } from '../provider/MainProvider';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import classNames from 'classnames';

const today = new Date();
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
const formattedStartDate = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${firstDayOfMonth.getDate().toString().padStart(2, '0')}`;
const formattedEndDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

interface DashboardFiltersProps {
  comparePage?: boolean;
}

const DashboardFilters: React.FC<DashboardFiltersProps> = ({ comparePage }) => {

  const { filters: mainFilters, displayComparedGraphs } = useMain();
  const { filters, updateFilters, dashboardDates, setDashboardDates, viewMode, changeViewMode } = useDashboard();
  const { getDishesCategoriesList } = useDishesCategories();

  const [startDate, setStartDate] = useState<any | null>(comparePage ? (mainFilters.filter_filters?.date?.from !== '' ? mainFilters.filter_filters.date.from : null) : null);
  const [endDate, setEndDate] = useState<any | null>(comparePage ? (mainFilters.filter_filters?.date?.to !== '' ? mainFilters.filter_filters.date.to : null) : null);
  const [selectedOption, setSelectedOption] = useState(viewMode === 'years' ? 'Año' : viewMode === 'months' ? 'Mes' : viewMode === 'weeks' ? 'Semana' : 'Día');
  const options = ['Año', 'Mes', 'Semana', 'Día'];

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);
    setDashboardDates({ ...dashboardDates, startDate: selectedStartDate, endDate: endDate });
    updateFilters({ date: { ...filters.filter_filters?.date, from: selectedStartDate } });
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndDate = e.target.value;
    setEndDate(selectedEndDate);
    setDashboardDates({ ...dashboardDates, startDate: startDate, endDate: selectedEndDate });
    updateFilters({ date: { ...filters.filter_filters?.date, to: selectedEndDate } });
  };

  return (
    <Card>
      <CardBody className='py-1'>
        <div className='row'>
          <FormGroup label='Categoría de plato:' className={classNames('d-flex align-items-center', { 'col-md-5': displayComparedGraphs, 'col-md-3': !displayComparedGraphs })}>
            <Select
              id='dish_category'
              list={getDishesCategoriesList()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ dish_category: [e.target.value] })}
              value={filters.filter_filters?.dish_category || ''}
              className='ms-2'
              placeholder='Elegir categoría ...'
              ariaLabel='Elegir categoría ...'
            />
          </FormGroup>

          <FormGroup className={classNames('d-flex align-items-center', { 'col-md-7': displayComparedGraphs, 'col-md-1': !displayComparedGraphs })}>
            <Dropdown
              onSelect={(e: any) => {
                const value = e === 'Año' ? 'years' : e === 'Mes' ? 'months' : e === 'Semana' ? 'weeks' : 'days';
                setSelectedOption(e);
                updateFilters({ date: { ...filters.filter_filters?.date, mode: value } });
                changeViewMode(value);
              }}
            >
              <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ paddingRight: '10px', paddingLeft: '10px', marginLeft: 'auto', marginRight: '-60px' }}>
                {selectedOption}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {options.map((option) => (
                  <Dropdown.Item key={option} eventKey={option}>
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </FormGroup>

          <FormGroup className={classNames('d-flex align-items-center', { 'col-md-4': displayComparedGraphs, 'col-md-2': !displayComparedGraphs })}>
            <label htmlFor="startDate" className='mr-2 nowrap font-weight-bold'>Fecha Inicial</label>
            <FormControl
              type="date"
              id="startDate"
              value={startDate ? startDate : formattedStartDate}
              onChange={handleStartDateChange}
              max={endDate ? endDate : formattedEndDate}
            />
          </FormGroup>

          <FormGroup className={classNames('d-flex align-items-center', { 'col-md-4': displayComparedGraphs, 'col-md-2': !displayComparedGraphs })}>
            <label htmlFor="endDate" className='mr-2 nowrap font-weight-bold'>Fecha Final</label>
            <FormControl
              type="date"
              id="endDate"
              value={endDate ? endDate : formattedEndDate}
              onChange={handleEndDateChange}
              min={startDate ? startDate : formattedStartDate}
              max={today.toISOString().split('T')[0]}
            />
          </FormGroup>

          <div className='d-flex align-items-center justify-content-end col-md-4'>
            <Button
              color="storybook" isLight /* icon='EventBusy' */ onClick={() => {
                setStartDate(null);
                setEndDate(null);
                updateFilters({
                  dish_category: [],
                  date: { ...filters.filter_filters?.date, from: formattedStartDate, to: formattedEndDate }
                });
              }}
            >
              Resetear
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardFilters;