export function phoneFormatter(str: string) {
    if (!str) return "";

    if (str.length === 9) {
        return str.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4');
    } else if (str.length === 11) {
        return str.replace(/(\d{2})(\d{3})(\d{2})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
    } else {
        return str;
    }
}