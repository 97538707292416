import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FilterOptions } from '../../../../hooks/useFilters';
import useCompanies from '../../../../hooks/useCompanies';
import Icon from '../../../../components/icon/Icon';
import CustomSearchInput from '../../../../components/bootstrap/forms/CustomSearchInput';
import Button from '../../../../components/bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas';
import { userIsSuperAdmin } from '../../../../utils/userIsSuperAdmin';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Input from '../../../../components/bootstrap/forms/Input';
import { DishService } from '../../../../services/dishes/dishService';
import SearchableSelect from '../../../../components/SearchableSelect';

interface SalesFiltersProps {
    filters: FilterOptions
    updateFilters: (filters: any) => void
    resetFilters: (limit?: any) => void
}

const SalesFilters: React.FC<SalesFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getCompanyList } = useCompanies();

    const [filterMenu, setFilterMenu] = useState(false);
    const [dishes, setDishes] = useState<any>([]);

    const fetchDishes = useCallback(async (companyId?: string) => {
        try {
            if (userIsSuperAdmin() && !companyId) return [];
            const response = await (new DishService()).getDishes();
            const fetchedData = response.getResponseData() as any;

            if (fetchedData && fetchedData.data?.dishes) {
                const mappedData = fetchedData.data.dishes.map((entity: { id: string; name: string; }) => ({
                    value: entity.id,
                    label: entity.name,
                    key: entity.id,
                })).sort((a: any, b: any) => {
                    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
                    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                    return 0;
                });

                setDishes(mappedData);
            }
        } catch (error) {
            console.log('Error fetching dishes:', error);
        }
    }, []);

    const getDishById = (dishesIds: string[]) => {
        const dish = dishes.filter((d: any) => dishesIds?.includes(d.value));
        if (dish) {
            return dish;
        }
        return null;
    };

    useEffect(() => {
        fetchDishes();
    }, []);

    return (
        <Fragment>
            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='salesFilters'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='salesFilters'>Filtros de Ventas</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        {userIsSuperAdmin() &&
                            <FormGroup label='Organización:' className="col-md-12">
                                <Select
                                    id='company'
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })}
                                    value={filters.filter_filters?.company || ''}
                                    placeholder='Elegir organización ...'
                                    ariaLabel='Default select example'
                                />
                            </FormGroup>
                        }

                        <FormGroup label='Platos:' className='col-md-12'>
                            <SearchableSelect
                                name='dish-select'
                                isMulti
                                isClearable
                                options={dishes}
                                onChange={(value: any) => updateFilters({ dishes: value.map((s: any) => s.value) })}
                                defaultValue={getDishById(filters.filter_filters?.dishes)}
                                placeholder='plato'
                            />
                        </FormGroup>

                        <FormGroup label='Desde:' className="col-md-6">
                            <Input
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ date: { ...filters.filter_filters?.date, from: e.target.value } })}
                                value={filters.filter_filters?.date?.from}
                            />
                        </FormGroup>

                        <FormGroup label='Hasta:' className="col-md-6">
                            <Input
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ date: { ...filters.filter_filters?.date, to: e.target.value } })}
                                value={filters.filter_filters?.date?.to}
                            />
                        </FormGroup>

                        <div className='col-12'>
                            <Button
                                className='mt-4' color="storybook" isLight
                                onClick={() => {
                                    resetFilters();
                                    updateFilters({ date: { from: '', to: '' }, dishes: '' });
                                }}
                            >
                                Resetear
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    );
};

export default SalesFilters;