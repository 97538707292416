import { FC, Fragment, useState } from 'react'
import Button from '../../../../components/bootstrap/Button'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../../components/bootstrap/OffCanvas'
import Icon from '../../../../components/icon/Icon'
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import { userIsSuperAdmin } from '../../../../utils/userIsSuperAdmin';
import useCompanies from '../../../../hooks/useCompanies';

interface IRoleFiltersProps {
  updateFilters: (filters: any) => void
  resetFilters: (limit: number, hardReset: boolean) => void
  filters: any
}

const RoleFilters: FC<IRoleFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

  const { getCompanyList } = useCompanies();

  const [filterMenu, setFilterMenu] = useState(false)

  const handleTextChange = (search: string) => {
    updateFilters({ search_array: search });
  };

  return (
    <Fragment>
      <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
        <Icon icon='Search' size='2x' color='primary' />
      </label>
      <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_array || ''}></CustomSearchInput>

      <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
        Filtros
      </Button>

      <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
        <OffCanvasHeader setOpen={setFilterMenu}>
          <OffCanvasTitle id='userFilters'> Filtros de Roles </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>
            {userIsSuperAdmin() &&
              <FormGroup label='Organización:' className="col-md-12">
                <Select
                  id='companyId'
                  list={getCompanyList()}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ companyId: e.target.value })}
                  value={filters.filter_filters?.companyId || ''}
                  placeholder='Elegir organización ...'
                  ariaLabel='Company select'
                />
              </FormGroup>
            }

            <FormGroup id='filter1' label='Estado' className='col-md-12'>
              <Select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                value={filters.filter_filters?.active?.toString() || '3'}
                id='active-select' ariaLabel='Default select example' placeholder='Estado...'
                list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activo', text: 'Activo' }, { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
              />
            </FormGroup>

            <div className='col-12'>
              <Button className='mt-4' color="storybook" isLight onClick={() => { resetFilters(50, true) }}>
                Resetear
              </Button>
            </div>
          </div>
        </OffCanvasBody>
      </OffCanvas>
    </Fragment>
  )
}

export default RoleFilters;