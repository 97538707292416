import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../../components/bootstrap/Alert';
import { LoginService } from '../../services/auth/loginService';
import { LoginContainer } from './LoginContainer';
import { LoginForm } from './LoginForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { login, User } from '../../redux/authSlice';
import { toast } from "react-toastify";
import { PrivilegeContext } from '../../components/priviledge/PriviledgeProvider';

const Login: FC = () => {

  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated, loading, error } = useSelector((state: RootState) => state.auth);

  if (isAuthenticated) {
    setTimeout(() => {
      if (userCan('get', 'dashboard')) {
        navigate('/dashboard', { replace: true })
      } else {
        navigate('/users', { replace: true })
      }
    }, 100);
  }

  const handleLogin = async (username: string, password: string) => {

    const response = await (await (new LoginService()).authUserCredentials(username, password)).getResponseData();

    if (!response.success) {
      toast.error(response.message);
      dispatch(
        login(
          {
            isAuthenticated: false,
            loading: false,
            error: response.message,
            user: null
          }
        )
      )
      return;
    }

    if (response.success) {
      // store data in indexDb for service worker.
      try {
        //saveUser(response.data);
        const user: User = {
          id: response.data.user.id,
          token: response.data.token,
          name: response.data.user.name,
          profilePictureId: response.data.user.imgProfile,
          roles: response.data.user.roles,
        };

        // dispatch login action
        dispatch(login(
          {
            isAuthenticated: true,
            loading: false,
            error: null,
            user: user
          }
        ))
      } catch (error) {
        toast.error('Error saving user to indexDb');
        return;
      }
    }

    if (response.payload) { navigate('/users', { replace: true }) };
  };

  return (

    <LoginContainer>
      {/* Alert for login errors taking api msg */}
      {error && <Alert color='warning' isLight icon='Warning'> {error} </Alert>
      }
      {/* Form section to wrapp all formik logic */}
      <LoginForm isLoading={loading} submit={handleLogin} errorsBool={error != null} />
    </LoginContainer>
  );
};

export default Login;