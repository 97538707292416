import { Fragment } from "react";
import { FiltersProvider } from "../../components/providers/FiltersProvider";
import DishCategoriesList from "./category-list/DishCategoriesList";

export const DishCategoriesListWrapper = () => {
    return (
        <Fragment>
            <FiltersProvider>
                <DishCategoriesList />
            </FiltersProvider>
        </Fragment>
    )
}

export default DishCategoriesListWrapper;