import React, { Fragment, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { SaleApiResponse } from '../../../type/sales-type';
import { SalesService } from '../../../services/sales/salesService';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import moment from "moment";
import 'moment/locale/es';
import { handleConfirmationAlert } from '../../../utils/ConfirmationAlert';
import { toast } from 'react-toastify';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { financialMenu } from '../../../menu';
import { PrivilegeContext } from '../../../components/priviledge/PriviledgeProvider';
import { Loader } from '../../../components/bootstrap/SpinnerLogo';
import { FixNumber } from '../../../utils/fixNumber';

interface SaleViewProps { }

const SaleView: React.FC<SaleViewProps> = ({ }) => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const { userCan } = useContext(PrivilegeContext);
    const service = new SalesService();

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getSaleById(id as string);
        return response.getResponseData() as SaleApiResponse;
    }, [id]));

    const _handleDelete = async () => {
        try {
            const response = await (await service.deleteSale(id)).getResponseData();
            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success("Venta eliminada correctamente");
                }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            handleErrors(error);
        }
    };

    if (loading) return <Loader />;
    if (!data) return null;

    const groupedDishes = data.salesHasDishes?.reduce((result: any, sale: any) => {
        const existingDish = result.find((groupedDish: any) => groupedDish.id === sale.dishes?.id);

        if (existingDish) {
            existingDish.quantity += 1;
            existingDish.total += sale.price;
        } else {
            result.push({
                id: sale.dishes?.id,
                name: sale.dishes?.name,
                quantity: sale.quantity,
                price: sale.price,
            });
        }

        return result;
    }, []);

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                </SubHeaderLeft>
                <SubHeaderRight>
                    {userCan('edit', 'sales') && <Button color='primary' isLink icon='Edit' onClick={() => navigate(`${financialMenu.sales.path}/${id}/edit`)} />}
                    {userCan('edit', 'sales') && userCan('delete', 'sales') && <SubheaderSeparator />}
                    {userCan('delete', 'sales') && (
                        <Button
                            color='primary' isLink icon='Delete'
                            onClick={() => {
                                handleConfirmationAlert({
                                    title: "Eliminar venta",
                                    text: "Esta acción es irreversible. ¿Estás seguro de que quieres eliminar esta venta?",
                                    icon: "warning",
                                    onConfirm: _handleDelete
                                })
                            }}
                        />
                    )}
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={true} className="col-md-5 m-auto p-3">
                    <CardHeader className='row d-flex align-items-center mb-4'>
                        <div className='col-md-4'>
                            <img src="/logo.png" alt="logo" width="100" />
                        </div>
                        <div className="col-md-8 d-flex align-items-end flex-column">
                            <div className="d-flex flex-row align-items-center fs-4 fw-bold">
                                Ticket <div className="bg-warning rounded px-3 py-1 ms-2">Nº {data.tpvSaleId}</div>
                            </div>
                            <span className="fs-5 mt-2">{moment(data.saleDate?.date).format('LL')}</span>
                        </div>
                    </CardHeader>

                    <CardBody>
                        <div className="row">
                            <table className="table table-bordered table-dark">
                                <thead className="bg-light">
                                    <tr>
                                        <th className="text-center">Unidades</th>
                                        <th className="text-center">Descripción</th>
                                        <th className="text-center">PVP</th>
                                        <th className="text-center">Importe</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupedDishes?.map((d: any, index: number) => (
                                        <tr key={index}>
                                            <td className="text-center">{d.quantity}</td>
                                            <td className="text-center">{d.name}</td>
                                            <td className="text-center">{d.price} €</td>
                                            <td className="text-center">{d.quantity * d.price} €</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="row mt-4">
                            <table className="table table-bordered table-dark">
                                <thead className="bg-light">
                                    <tr>
                                        <th></th>
                                        <th className="text-center">Sin IVA</th>
                                        <th className="text-center">IVA</th>
                                        <th className="text-center">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-center'>IVA {data.taxPercentage}%</td>
                                        <td className="text-center">{FixNumber(data.total - data.taxQuantity)} €</td>
                                        <td className="text-center">{FixNumber(data.taxQuantity)} €</td>
                                        <td className="text-center">{FixNumber(data.total)} €</td>
                                    </tr>
                                </tbody>
                            </table>

                            <span className='text-end fs-4 fw-bold mt-3'>Total: {FixNumber(data.total)} €</span>
                        </div>
                    </CardBody>
                </Card>
            </Page>
        </Fragment>
    );
};

export default SaleView;