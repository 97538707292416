import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';
import { CreateDeliveryNoteFieldsModel, EditDeliveryNoteFieldsModel } from '../../type/deliveryNote-type';

const DN_ENDPOINT = '/delivery-notes';

export class DeliveryNoteService extends RestServiceConnection {

    /**
     * ------------------------------------------------------------------------
     * EN: DELVIERY NOTES REQUEST SERVICE
     * ES: SERVICIO DE ALBARANES
     * ------------------------------------------------------------------------
     */

    createDeliveryNote = async (values: CreateDeliveryNoteFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DN_ENDPOINT + '/create-delivery-note',
            data: values,
        }, true);
        return this;
    }

    editDeliveryNote = async (values: EditDeliveryNoteFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DN_ENDPOINT + '/edit-delivery-note',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getDeliveryNotes = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DN_ENDPOINT + '/list-delivery-notes',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getDeliveryNoteById = async (deliveryNoteId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DN_ENDPOINT + '/get-delivery-note',
            data: { deliveryNoteId }
        }, true);
        return this;
    }

    deleteDeliveryNote = async (deliveryNoteId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DN_ENDPOINT + '/delete-delivery-note',
            data: { deliveryNoteId }
        }, true);

        return this
    }

    exportDeliveryNotes = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DN_ENDPOINT + '/export-delivery-notes',
            responseType: 'arraybuffer', // define el tipo de respuesta que se espera
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    exportDeliveryNotesLines = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DN_ENDPOINT + '/export-delivery-notes-lines',
            responseType: 'arraybuffer', // define el tipo de respuesta que se espera
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getIngredientUnitPrice = async (price: number, quantity: number) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DN_ENDPOINT + '/get-ingredient-unit-price',
            data: { price, quantity }
        }, true) as AxiosResponse;
        return this;
    }

}