import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { EditIngredientFieldsModel, CreateIngredientFieldsModel } from '../../type/ingredient-type';
import { FilterOptions } from '../../hooks/useFilters';

const INGREDIENT_ENDPOINT = '/ingredients';
export class IngredientService extends RestServiceConnection {

    /**
     * ------------------------------------------------------------------------
     * EN: INGREDIENT REQUEST SERVICE
     * ES: SERVICIO DE INGREDIENTES
     * ------------------------------------------------------------------------
     */

    createIngredient = async (values: CreateIngredientFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/create-ingredient',
            data: values,
        }, true);
        return this;
    }

    editIngredient = async (values: EditIngredientFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/edit-ingredient',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getIngredients = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/list-ingredients',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    getIngredientById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/get-ingredient',
            data: {
                ingredientId: id
            }
        }, true);
        return this;
    }

    deleteIngredient = async (userId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/delete-ingredient',
            data: { ingredientId: userId }
        }, true);
        return this;
    }

    getLastPurchases = async (ingredientId: string, range: number | null, filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/ingredient-last-purchases',
            data: { ingredientId, range, ...filters },
        }, true);
        return this;
    }

    getIngredientCostsGraph = async (filters: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/ingredient-cost-graph',
            data: { ...filters },
        }, true);
        return this;
    }

    /**
     * ------------------------------------------------------------------------
     * EN: INGREDIENT CATEGORIES REQUEST
     * ES: SERVICIO DE CATEGORIAS DE INGREDIENTES
     * ------------------------------------------------------------------------
     */

    getIngredientsCategories = async (filters?: FilterOptions | any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/list-categories',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    getCategoryById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/get-category',
            data: {
                ingredientCategoryId: id
            }
        }, true);
        return this;
    }

    createIngredientCategory = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/create-category',
            data: values,
        }, true);
        return this;
    }

    editIngredientCategory = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/edit-category',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteIngredientCategory = async (ingredientCategoryId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/delete-category',
            data: { ingredientCategoryId }
        }, true);
        return this;
    }

    /**
     * ------------------------------------------------------------------------
     * EN: INGREDIENT SUPPLIERS REQUEST
     * ES: SERVICIO DE PROVEEDORES DE INGREDIENTES
     * ------------------------------------------------------------------------
     */

    getIngredientsSuppliers = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: INGREDIENT_ENDPOINT + '/list-ingredients-suppliers',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }


}