import { Fragment, useContext } from 'react';
import Page from '../../layout/Page/Page';
import { PrivilegeContext } from '../../components/priviledge/PriviledgeProvider';
import DashboardFilters from './dasboard-options/DashboardFilters';
import DashboardGraphs from './components/DashboardGraphs';
import SoldDishes from './components/SoldDishes';
import IngredientsLists from './components/IngredientsLists';
import ProfitableDishes from './components/ProfitableDishes';
import { useDashboard } from './provider/DashboardProvider';
import { Loader } from '../../components/bootstrap/SpinnerLogo';
import { useMain } from './provider/MainProvider';
import classNames from 'classnames';

const DashboardComparePage = () => {

    const { displayComparedGraphs } = useMain();
    const { isLoading } = useDashboard();
    const { userCan } = useContext(PrivilegeContext);

    if (!userCan('get', 'dashboard')) return null;

    return (
        <div className={classNames({ 'w-50': displayComparedGraphs })}>
            {displayComparedGraphs && (
                <Fragment>
                    <DashboardFilters comparePage />

                    <Page container="fluid" className='pt-0'>
                        {isLoading
                            ? <Loader height='90vh' />
                            : (
                                <div className='row'>
                                    {userCan('total_sales_graph', 'dashboard') && (
                                        <div className={classNames({ 'col-md-6': !displayComparedGraphs })}>
                                            <DashboardGraphs sales comparePage />
                                        </div>
                                    )}

                                    {userCan('total_profit_graph', 'dashboard') && (
                                        <div className={classNames({ 'col-md-6': !displayComparedGraphs })}>
                                            <DashboardGraphs profits comparePage />
                                        </div>
                                    )}
                                </div>
                            )}

                        <div className='row justify-content-between'>
                            {userCan('sold_stats_dishes', 'dashboard') && (
                                <>
                                    <div className={classNames({ 'col-md-4': displayComparedGraphs, 'col-md-2': !displayComparedGraphs })}>
                                        <SoldDishes most />
                                    </div>
                                    <div className={classNames({ 'col-md-4': displayComparedGraphs, 'col-md-2': !displayComparedGraphs })}>
                                        <SoldDishes />
                                    </div>
                                </>
                            )}

                            {userCan('most_profitable_dishes', 'dashboard') && (
                                <div className={classNames({ 'col-md-4': displayComparedGraphs, 'col-md-2': !displayComparedGraphs })}>
                                    <ProfitableDishes />
                                </div>
                            )}

                            {userCan('ingredient_stats', 'dashboard') && (
                                <>
                                    <div className={classNames({ 'col-md-6': displayComparedGraphs, 'col-md-2': !displayComparedGraphs })}>
                                        <IngredientsLists most />
                                    </div>
                                    <div className={classNames({ 'col-md-6': displayComparedGraphs, 'col-md-2': !displayComparedGraphs })}>
                                        <IngredientsLists />
                                    </div>
                                </>
                            )}
                        </div>
                    </Page>
                </Fragment>
            )}
        </div>
    );
};

export default DashboardComparePage;