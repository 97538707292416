import { FC, Fragment, useState } from "react";
import Icon from "../../../../components/icon/Icon";
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import Button from "../../../../components/bootstrap/Button";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../../components/bootstrap/OffCanvas";
import FormGroup from "../../../../components/bootstrap/forms/FormGroup";
import Select from "../../../../components/bootstrap/forms/Select";
import useCompanies from "../../../../hooks/useCompanies";
import { userIsSuperAdmin } from "../../../../utils/userIsSuperAdmin";

interface ISupplierFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: any
}

export const SupplierFilters: FC<ISupplierFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getCompanyList } = useCompanies();

    const [filterMenu, setFilterMenu] = useState(false);

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <CustomSearchInput
                placeholder={'Buscar'}
                onSearch={(e: any) => updateFilters({ search_array: e })}
                defaultValue={filters.filter_filters?.search_array || ''}
            />

            {userIsSuperAdmin() &&
                <>
                    <Button color='primary' isLight icon='Filter' onClick={() => {
                        setFilterMenu(true)
                    }}>
                        Filtros
                    </Button>

                    <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='supplierFilters' isBodyScroll placement='end'>
                        <OffCanvasHeader setOpen={setFilterMenu}>
                            <OffCanvasTitle id='supplierFilters'>Filtros de Proveedor</OffCanvasTitle>
                        </OffCanvasHeader>
                        <OffCanvasBody>
                            <div className='row g-4'>
                                <FormGroup id='filter0' label='Compañia:' className="col-md-12">
                                    <Select
                                        id='company'
                                        list={getCompanyList()}
                                        value={filters.filter_filters?.company || ''}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })}
                                        placeholder='Elegir compañía ...'
                                        ariaLabel='Default select example'
                                    />
                                </FormGroup>

                                {/* <FormGroup id='filter1' label='Estado de cuenta' className='col-12'>
                                <Select
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ active: parseInt(e.target.value) })}
                                    value={filters.filter_filters?.active?.toString() || 'true'}
                                    id='active-select' ariaLabel='Default select example' placeholder='Estado...'
                                    list={[{ value: 3, label: 'Todos', text: 'Todos' }, { value: 1, label: 'Activo', text: 'Activo' }, { value: 0, label: 'Desactivado', text: 'Desactivado' }]}
                                />
                            </FormGroup> */}

                                <div className='col-12'>
                                    <Button
                                        className='mt-4'
                                        color="storybook"
                                        isLight
                                        onClick={() => resetFilters(50)}
                                    >
                                        Resetear
                                    </Button>
                                </div>
                            </div>
                        </OffCanvasBody>
                    </OffCanvas>
                </>
            }
        </Fragment>
    )
};