import { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import { ElaborationService } from "../../../services/elaborations/elaborationService";
import { toast } from "react-toastify";
import { elaborationMenu } from "../../../menu";
import ElaborationForm from "../ElaborationForm";
import { CardTitle } from "../../../components/bootstrap/Card";
import Modal, { ModalHeader, ModalBody } from "../../../components/bootstrap/Modal";
import { Bento } from "../../../components/icon/material-icons";
import Button from "../../../components/bootstrap/Button";
import useHandleErrors from "../../../hooks/useHandleErrors";

const CreateElaboration = () => {

    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();

    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCreation = async (values: any) => {
        try {
            setLoading(true)
            let response = await (await (new ElaborationService()).createElaboration(values)).getResponseData();
            if (response.success) {
                toast.success(response.message);
                navigate(elaborationMenu.elaborations.path, { replace: true })
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al crear la elaboración');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Modal isOpen={true} setIsOpen={setModal} size='lg' titleId='Nueva categoría'>
                <ModalHeader className='ms-2 p-4 gap-4'>
                    <Bento fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                    <CardTitle className="fs-3">Crear Elaboración</CardTitle>
                    <Button className='btn-close fs-5 p-4' onClick={() => navigate(-1)} />
                </ModalHeader>
                <hr className="mt-0" />
                <ModalBody className='px-4'>
                    <ElaborationForm submit={handleCreation} isLoading={loading} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default CreateElaboration;