import { Fragment, useCallback, useContext } from "react";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import Page from "../../../layout/Page/Page";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { Link, useNavigate } from "react-router-dom";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import useFetch from "../../../hooks/useFetch";
import { SupplierService } from "../../../services/suppliers/suppliersService";
import { SuppliersApiResponse } from "../../../type/supplier-type";
import { userIsSuperAdmin } from "../../../utils/userIsSuperAdmin";
import moment from "moment";
import { toast } from "react-toastify";
import Tooltips from "../../../components/bootstrap/Tooltips";
import { SupplierFilters } from "./suppliers-options/SupplierFilters";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import ErrorMessage from "../../../components/ErrorMessage";
import useHandleErrors from "../../../hooks/useHandleErrors";

const SuppliersList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();
    const navigate = useNavigate();
    const supplierService = new SupplierService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await supplierService.getSuppliers(filters);
        return response.getResponseData() as SuppliersApiResponse;
    }, [filters]));

    const deleteSupplier = async (id: string) => {
        const response = (await supplierService.deleteSupplier(id));
        const responseData = response.getResponseData();
        /* console.log(responseData);
        console.log(response); */
        if (responseData) {
            toast.success("Proveedor eliminado correctamente");
            updateFilters({ ...filters });
        } else {
            handleErrors(response);
            toast.error("Error al eliminar el proveedor");
        }
        // TODO: handle errors
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardTitle>Listado de Proveedores</CardTitle>
                        {userCan("create", "ingredients") &&
                            <>
                                <SubheaderSeparator />
                                <Button color="light" icon="Add" isLight onClick={() => { navigate("create") }}>
                                    Añadir Proveedor
                                </Button>
                            </>
                        }
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <SupplierFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={false}>
                    {error && <ErrorMessage error={error} />}

                    {data && data?.suppliers
                        ? (
                            <CustomTable
                                data={data.suppliers || null}
                                pagination={true}
                                paginationData={{
                                    pageSize: filters.limit,
                                    currentPage: filters.page,
                                    pageCount: (data as SuppliersApiResponse) ? data.lastPage : 1,
                                    totalCount: data.totalRegisters,
                                    handlePagination: updatePage,
                                    handlePerPage: updatePageSize,
                                }}
                                defaultLimit={filters.limit || 50}
                                defaultOrder={filters.filter_order || undefined}
                                className={"table table-hover"}
                                columns={[
                                    {
                                        name: "Nombre",
                                        keyValue: "name",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <Link to={`${element.id}/view`} style={{ textDecoration: 'none' }}>
                                                    <div className="d-flex justify-content-center text-center fw-bold">
                                                        {element.name}
                                                    </div>
                                                </Link>
                                            );
                                        },
                                    },
                                    {
                                        name: "CIF/NIF",
                                        keyValue: "cifNif",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                    },
                                    {
                                        name: "Teléfono",
                                        keyValue: "telephone",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            const phoneNum = element?.contactPersonTelephone;
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {`+${phoneNum.slice(0, 2)} ${phoneNum.slice(2, 5)} ${phoneNum.slice(5, 8)} ${phoneNum.slice(8)}`}
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        name: "Correo electrónico",
                                        keyValue: "email",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                    },
                                    {
                                        name: "Provincia",
                                        keyValue: "province",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        cellClassName: "text-center",
                                    },
                                    {
                                        name: "Contacto",
                                        keyValue: "contact_person_name",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            const phoneNum = element?.contactPersonTelephone;
                                            return (
                                                <Tooltips title={`+${phoneNum.slice(0, 2)} ${phoneNum.slice(2, 5)} ${phoneNum.slice(5, 8)} ${phoneNum.slice(8)}`}>
                                                    <div className="d-flex justify-content-center text-center">
                                                        {element?.contactPersonName + ' - ' + element?.contactPersonPosition ?? "N/A"}
                                                    </div>
                                                </Tooltips>
                                            );
                                        },
                                    },
                                    {
                                        name: "Gasto total",
                                        keyValue: "total_spent",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {element?.totalSpent + '€' ?? "N/A"}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Organización",
                                        keyValue: "company",
                                        isVisible: userIsSuperAdmin(),
                                        sortable: false,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <span>
                                                    {element?.company?.name ?? "N/A"}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        name: "Fecha de creación",
                                        keyValue: "created_at",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <div className={"text-center"}>
                                                    <span className={"text-muted"}>
                                                        {moment(element.createdAt.date).format("DD-MM-YYYY HH:mm") ?? "N/A"}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
                                ]}
                                actions={[
                                    {
                                        title: "Editar",
                                        buttonType: "icon",
                                        iconColor: "text-info",
                                        iconPath: "/media/icons/duotune/general/gen055.svg",
                                        additionalClasses: "text-primary",
                                        description: "Editar proveedor",
                                        hide: () => !userCan('edit', 'suppliers'),
                                        callback: (item: any) => { navigate(`${item.id}/edit`) },
                                    },
                                    {
                                        title: "Eliminar",
                                        buttonType: "icon",
                                        iconColor: "text-danger",
                                        iconPath: "/media/icons/duotune/general/gen027.svg",
                                        additionalClasses: "text-danger",
                                        description: "Eliminar proveedor",
                                        hide: () => !userCan('delete', 'suppliers'),
                                        callback: (item: any) => {
                                            handleConfirmationAlert({
                                                title: "Eliminar proveedor",
                                                text: "¿Estás seguro de que deseas eliminar el proveedor?",
                                                icon: "warning",
                                                onConfirm: () => { deleteSupplier(item.id) },
                                            });
                                        },
                                    },
                                ]}
                            />
                        )
                        : !error && <Loader />
                    }
                </Card>
            </Page>
        </Fragment>
    )
}

export default SuppliersList;