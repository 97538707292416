import React, { useContext, useEffect, useState } from "react";
import useFilters from "../../../hooks/useFilters";

type MainProviderProps = {
    children: React.ReactNode
}

type MainContextData = {
    filters: any,
    updateFilters: (filters: any) => void,
    resetFilters: () => void,
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => void,
    updatePage: (page: number) => void,
    updatePageSize: (pageSize: number) => void,
    displayComparedGraphs: boolean,
    setDisplayComparedGraphs: (displayComparedGraphs: boolean) => void,
}

const MainContext: React.Context<MainContextData> = React.createContext<MainContextData>({
    filters: {} as any,
    updateFilters: (filters: any) => { },
    resetFilters: () => { },
    updateFilterOrder: (keyvalue: string, order: "asc" | "desc") => { },
    updatePage: (page: number) => { },
    updatePageSize: (pageSize: number) => { },
    displayComparedGraphs: false,
    setDisplayComparedGraphs: (displayComparedGraphs: boolean) => { },
});

const MainProvider: React.FC<MainProviderProps> = ({ children }) => {

    const { filters, updateFilters, resetFilters, updateFilterOrder, updatePage, updatePageSize } = useFilters();

    const [displayComparedGraphs, setDisplayComparedGraphs] = useState(false);

    useEffect(() => {
        setDisplayComparedGraphs(filters.filter_filters?.date?.compare);
    }, [filters.filter_filters?.date?.compare]);

    return (
        <MainContext.Provider value={{
            filters,
            updateFilters,
            resetFilters,
            updateFilterOrder,
            updatePage,
            updatePageSize,
            displayComparedGraphs,
            setDisplayComparedGraphs
        }}>
            {/* This container is necessary to be able to split the screen in two and to display the compared graphs on the right side. */}
            <div className="row">
                {children}
            </div>
        </MainContext.Provider>
    );
}

MainProvider.defaultProps = {
}

export { MainProvider, MainContext };

export function useMain() {
    return useContext(MainContext);
}