import { Fragment } from "react"
import { FiltersProvider } from "../../components/providers/FiltersProvider"
import IngredientsList from "./ingredient-list/IngredientsList"

const IngredientListWrapper = () => {

    return (
        <Fragment>
            <FiltersProvider>
                <IngredientsList />
            </FiltersProvider>
        </Fragment>
    )
}

export default IngredientListWrapper;