import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';
import { CreateDishFieldsModel, EditDishFieldsModel } from '../../type/dish-type';

const DISH_ENDPOINT = '/dishes';

export class DishService extends RestServiceConnection {

    /**
     * ------------------------------------------------------------------------
     * EN: DISH REQUEST SERVICE
     * ES: SERVICIO DE PLATOS
     * ------------------------------------------------------------------------
     */

    createDish = async (values: CreateDishFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/create-dish',
            data: values,
        }, true);
        return this;
    }

    editDish = async (values: EditDishFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/edit-dish',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    getDishes = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/list-dishes',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    getDishById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/get-dish',
            data: {
                dishId: id
            }
        }, true);
        return this;
    }

    deleteDish = async (dishId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/delete-dish',
            data: { dishId }
        }, true);

        return this
    }

    toggleDishStatus = async (dishId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/toggle-dish',
            data: { dishId }
        }, true);

        return this
    }

    exportDishes = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/export-dishes',
            responseType: 'arraybuffer', // define el tipo de respuesta que se espera
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    listDishIngredients = async (filters?: FilterOptions | any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/list-dish-ingredients',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    listDishElaborations = async (filters?: FilterOptions | any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/list-dish-elaborations',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    convertToElaboration = async (dishId: string, processingQuantity: number) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/convert-to-elaboration',
            data: { dishId, processingQuantity }
        }, true);

        return this
    }

    getPricesGraph = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/dish-price-graph',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }

    getCostsGraph = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/dish-cost-graph',
            data: { ...filters }
        }, true) as AxiosResponse;
        return this;
    }


    /**
     * ------------------------------------------------------------------------
     * EN: DISH CATEGORIES REQUEST
     * ES: SERVICIO DE CATEGORIAS DE PLATOS
     * ------------------------------------------------------------------------
     */

    getDishCategories = async (filters?: FilterOptions | any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/list-dish-categories',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    getCategoryById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/get-dish-category',
            data: {
                dishCategoryId: id
            }
        }, true);
        return this;
    }

    createDishCategory = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/create-dish-category',
            data: values,
        }, true);
        return this;
    }

    editDishCategory = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/edit-dish-category',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteDishCategory = async (dishCategoryId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: DISH_ENDPOINT + '/delete-dish-category',
            data: { dishCategoryId }
        }, true);
        return this;
    }

}