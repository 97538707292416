import { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTabItem, CardTitle } from "../../../components/bootstrap/Card";
import Progress from "../../../components/bootstrap/Progress";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";

const CompanyProfile: FC = () => {

    const navigate = useNavigate();

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='primary' isLink icon='ArrowBack' onClick={() => navigate('/organizations', { replace: true })}>
                    </Button>
                </SubHeaderLeft>
            </SubHeader>
            <Page container='fluid'>
                <div className='row'>
                <div className='col-xxl-8 col-xl-6'>
                        <Card hasTab>
                            <CardTabItem id='profile' title='Profile' icon='Contacts'>

                                <Card
                                    className='rounded-2'
                                    tag='form'>
                                    <CardHeader>
                                        <CardLabel icon='Person'>
                                            <CardTitle>Personal Information</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4'>

                                        </div>
                                    </CardBody>

                                </Card>

                            </CardTabItem>
                            <CardTabItem id='address' title='Address' icon='HolidayVillage'>
                                <Card
                                    className='rounded-2'
                                    tag='form'>
                                    <CardHeader>
                                        <CardLabel icon='HolidayVillage'>
                                            <CardTitle>Address Information</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4'>

                                        </div>
                                    </CardBody>

                                </Card>
                            </CardTabItem>
                            <CardTabItem id='profile2' title='Password' icon='Lock'>
                                <Card
                                    className='rounded-2'
                                    tag='form'>
                                    <CardHeader>
                                        <CardLabel icon='Lock'>
                                            <CardTitle>Change Password</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row g-4'>

                                        </div>
                                    </CardBody>
                                </Card>
                            </CardTabItem>
                        </Card>
                    </div>
                    <div className='col-xxl-4 col-xl-6'>
                        <Card>
                            <CardBody>
                                <div className='d-flex justify-content-between'>
                                    <p>Puntos mensuales</p>
                                    <p className='fw-bold'>90%</p>
                                </div>
                                <Progress value={90} />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardLabel icon='PhotoSizeSelectActual' iconColor='info'>
                                    <CardTitle>Documentos</CardTitle>
                                </CardLabel>
                                <CardActions>
                                    <Button
                                        color='info'
                                        icon="Upload"
                                        isLight
                                        onClick={() => { }}>
                                        Subir documentos
                                    </Button>
                                </CardActions>
                            </CardHeader>
                            <CardBody> DOCUMENTOS </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </Fragment>
    )
}

export default CompanyProfile;