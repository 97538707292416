import { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import DashboardPage from "./DashBoardPage";
import { DashboardProvider } from "./provider/DashboardProvider";
import DashboardComparePage from "./DashBoardComparePage";
import { MainProvider } from "./provider/MainProvider";

type DashboardWrapperProps = {}

export const DashboardWrapper: React.FC<DashboardWrapperProps> = () => {
    return (
        <Fragment>
            <ToastContainer />
            <MainProvider>
                <DashboardProvider>
                    <DashboardPage />
                </DashboardProvider>
                <DashboardProvider>
                    <DashboardComparePage />
                </DashboardProvider>
            </MainProvider>
        </Fragment>
    );
}