import { Fragment, useCallback, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, { CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { financialMenu } from "../../../menu";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { DeliveryNoteApiResponse, EditDeliveryNoteFieldsModel } from "../../../type/deliveryNote-type";
import { DeliveryNoteService } from "../../../services/delivery-notes/deliveryNoteService";
import DeliveryNoteForm from "../DeliveryForm";
import { Receipt } from "../../../components/icon/material-icons";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";

const EditDeliveryNote = () => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const deliveryNote = new DeliveryNoteService();

    const [loading, setLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        const response = await deliveryNote.getDeliveryNoteById(id as string);
        return response.getResponseData() as DeliveryNoteApiResponse;
    }, [id]));

    const handleUpdate = async (values: EditDeliveryNoteFieldsModel) => {
        setLoading(true);
        values.deliveryNoteId = id;

        try {
            let response = (await deliveryNote.editDeliveryNote({ ...values, dishId: id })).getResponseData();
            if (response.success) {
                toast.success(response.message);
                navigate(financialMenu.deliveryNotes.path, { replace: true });
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar el albarán');
        } finally {
            setLoading(false);
        }
    };

    const getContent = () => {
        if (loading) return <Loader />;

        if (data !== null) {
            const deliveryNoteData = {
                ...data,
                supplierId: data?.supplier?.id,
                deliveryNoteLines: data?.deliveryNoteLines?.map((line: any) => {
                    return {
                        ...line,
                        ingredientId: line?.ingredient?.id,
                        hasUnits: line?.ingredient?.ingredientHasSupplierCode.filter((code: any) => code.supplier?.id === data?.supplier?.id)[0]?.hasUnits,
                    };
                }, []),
                deliveryNoteDate: data?.deliveryNoteDate?.date.split(' ')[0],
                company: null,
            };

            return (
                <Fragment>
                    <CardHeader borderSize={1} className="d-flex justify-content-start">
                        <Receipt fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                        <CardTitle className="fs-3 mb-0 ms-3">Editar Albarán</CardTitle>
                    </CardHeader>
                    <DeliveryNoteForm isLoading={loading} submit={handleUpdate} data={deliveryNoteData} />
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
                </SubHeaderLeft>
            </SubHeader>
            <Page container="fluid">
                <Card stretch={true} className="col-md-11 m-auto">
                    <>{getContent()}</>
                </Card>
            </Page>
        </Fragment>
    )
}

export default EditDeliveryNote;