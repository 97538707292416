import { useCallback, useEffect, useState } from 'react';
import { userIsSuperAdmin } from '../utils/userIsSuperAdmin';
import { DishService } from '../services/dishes/dishService';

//------------------------------------------------------------------------
/**
* 
* EN: Hook to manage dishes categories fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de categorias de platos.
*
*/
//----------------------------------------------------------------------
const useDishesCategories = () => {

    const [dishesCategories, setDishesCategories] = useState<any>([]);

    const fetchDishesCategories = useCallback(async (companyId?: string) => {
        try {
            if (userIsSuperAdmin() && !companyId) return [];
            const response = await (new DishService()).getDishCategories((companyId) ? { filter_filters: { companyId: companyId } } : undefined);
            const fetchedData = response.getResponseData() as any;

            if (fetchedData && fetchedData.data.dishes) {
                const mappedData = fetchedData.data.dishes.map((entity: { id: string; name: string; company: { id: string; }; }) => ({
                    value: entity.id,
                    label: entity.name,
                    key: entity.id,
                    companyid: entity.company.id
                }));
                setDishesCategories(mappedData);
            }
        } catch (error) {
            console.log('Error fetching categories:', error);
        }
    }, []);

    useEffect(() => {
        fetchDishesCategories();
    }, []);

    const getDishesCategoriesList = () => {
        return dishesCategories;
    };

    return { dishesCategories, fetchDishesCategories, getDishesCategoriesList };
}

export default useDishesCategories;