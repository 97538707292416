import { useContext, useCallback, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import useFetch from "../../../hooks/useFetch";
import moment from "moment";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import { CustomTable } from "../../../components/table/CustomTable";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubheaderSeparator, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { userIsSuperAdmin } from "../../../utils/userIsSuperAdmin";
import ErrorMessage from "../../../components/ErrorMessage";
import DishesFilters from "./deliveries-options/DeliveriesFilters";
import { DeliveryNoteService } from "../../../services/delivery-notes/deliveryNoteService";
import { DeliveryNotesApiResponse } from "../../../type/deliveryNote-type";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import { FixNumber } from "../../../utils/fixNumber";
import Icon from "../../../components/icon/Icon";
import Tooltip from "react-bootstrap/esm/Tooltip";
import IconWithTooltip from "../../../components/IconWithTooltip";

const DeliveriesList = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();
    const deliveryNoteService = new DeliveryNoteService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await deliveryNoteService.getDeliveryNotes(filters);
        return response.getResponseData() as DeliveryNotesApiResponse;
    }, [filters]));

    /**
     * Export delivery notes to Excel.
     * 
     * @param {boolean} lines - If true, export the delivery notes lines.
     *
     * @return {void}
     */
    const handleExport = async (lines?: boolean) => {
        if (
            filters.filter_filters?.date?.from === undefined
            || filters.filter_filters?.date?.to === undefined
            || filters.filter_filters?.date?.from === ''
            || filters.filter_filters?.date?.to === ''
        ) {
            toast.info('Debes seleccionar un rango de fechas para exportar');
            return;
        };

        const service = lines ? deliveryNoteService.exportDeliveryNotesLines({ ...filters, limit: 9999999 }) : deliveryNoteService.exportDeliveryNotes({ ...filters, limit: 9999999 });

        try {
            const response = await service;

            if (response.response?.status !== 200) {
                toast.error('No se ha podido exportar el archivo');
                return;
            }

            const fileData = response.getResponseData();
            const blob = new Blob([fileData]);
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `albaranes-${moment().subtract(10, 'days').calendar()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    /**
     * Delete a delivery note with the given ID.
     *
     * @param {string} id - The ID of the delivery note to be deleted.
     */
    const deleteDeliveryNote = async (id: string) => {
        let response = (await deliveryNoteService.deleteDeliveryNote(id)).getResponseData();
        if (response.success) {
            toast.success("Albarán eliminado correctamente");
            refetch();
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Fragment>
                        <CardTitle>Listado de Albaranes</CardTitle>
                        <SubheaderSeparator />
                        <CardTitle><span className="text-muted fs-5">Costes totales: </span>{FixNumber(data?.totalCosts) || 0} €</CardTitle>
                        {userCan("create", "delivery_notes") &&
                            <>
                                <SubheaderSeparator />
                                <Button
                                    color="light" icon="Add" isLight
                                    onClick={() => { navigate("create") }}
                                >
                                    Añadir Albarán
                                </Button>
                            </>
                        }
                        <SubheaderSeparator />
                        <Button color="light" isLight title="Exportar albaranes a excel" icon="Download" onClick={() => handleExport(false)}>
                            Exportar
                        </Button>
                        <SubheaderSeparator />
                        <Button color="light" isLight title="Exportar líneas de albarán a excel" icon="Download" onClick={() => handleExport(true)}>
                            Exportar líneas
                        </Button>
                    </Fragment>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <DishesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={false}>
                    {error && <ErrorMessage />}

                    {(data && data.deliveryNotes)
                        ? (
                            <CustomTable
                                data={data?.deliveryNotes ? data.deliveryNotes : null}
                                pagination={true}
                                paginationData={{
                                    pageSize: filters.limit,
                                    currentPage: filters.page,
                                    pageCount: (data as DeliveryNotesApiResponse) ? data.lastPage : 1,
                                    totalCount: data.totalRegisters,
                                    handlePagination: updatePage,
                                    handlePerPage: updatePageSize,
                                }}
                                defaultLimit={filters.limit || 50}
                                defaultOrder={filters.filter_order || undefined}
                                className={"table table-hover"}
                                columns={[
                                    {
                                        name: "Nombre",
                                        keyValue: "deliveryNoteNumber",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <Link to={`${element.id}/view`} className="d-flex justify-content-center fw-bold" style={{ textDecoration: 'none' }}>
                                                    {element.deliveryNoteNumber}
                                                </Link>
                                            );
                                        },
                                    },
                                    {
                                        name: "Proveedor",
                                        keyValue: "supplier",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {element.supplier?.name ?? "N/A"}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Precio",
                                        keyValue: "totalPrice",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {element.totalPrice + ' €' ?? "N/A"}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Usuario",
                                        keyValue: "user",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: "text-center",
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {`${element.user?.name} ${element.user?.lastName || ''}` ?? "N/A"}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Organización",
                                        keyValue: "company",
                                        isVisible: userIsSuperAdmin(),
                                        sortable: false,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <span>
                                                    {element?.company?.name ?? "N/A"}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        name: "Fecha de registro",
                                        keyValue: "created_at",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <div className={"text-center"}>
                                                    <span className={"text-muted"}>
                                                        {moment(element.deliveryNoteDate?.date).format("DD-MM-YYYY") ?? "N/A"}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: "Fecha de creación",
                                        keyValue: "created_at",
                                        sortable: true,
                                        sortColumn: updateFilterOrder,
                                        className: `text-center`,
                                        render: (element: any) => {
                                            return (
                                                <div className={"text-center"}>
                                                    <span className={"text-muted"}>
                                                        {moment(element.createdAt.date).format(
                                                            "DD-MM-YYYY HH:mm"
                                                        ) ?? "N/A"}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        name: 'Comentarios',
                                        keyValue: 'comments',
                                        className: 'text-center',
                                        render: (element: any) => {
                                            return (
                                                <div className="d-flex justify-content-center text-center">
                                                    {element.comments ? <IconWithTooltip icon="Comment" label="Tiene un comentario" /> : "N/A"}
                                                </div>
                                            );
                                        },
                                    },
                                    { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
                                ]}
                                actions={[
                                    {
                                        title: "Editar",
                                        buttonType: "icon",
                                        iconColor: "text-info",
                                        iconPath: "/media/icons/duotune/general/gen055.svg",
                                        additionalClasses: "text-primary",
                                        description: "Editar albarán",
                                        hide: () => !userCan('edit', 'delivery_notes'),
                                        callback: (item: any) => { navigate(`${item.id}/edit`) },
                                    },
                                    {
                                        title: "Eliminar",
                                        buttonType: "icon",
                                        iconColor: "text-danger",
                                        iconPath: "/media/icons/duotune/general/gen027.svg",
                                        additionalClasses: "text-danger",
                                        description: "Eliminar albarán",
                                        hide: () => !userCan('delete', 'delivery_notes'),
                                        callback: (item: any) => {
                                            handleConfirmationAlert({
                                                title: "Eliminar albarán",
                                                text: "Si lo eliminas, afectará al precio medio de todos los ingredientes y platos relacionados",
                                                icon: "warning",
                                                onConfirm: () => { deleteDeliveryNote(item.id) },
                                            });
                                        },
                                    },
                                ]}
                            />
                        )
                        : !error && <Loader />
                    }
                </Card>
            </Page>
        </Fragment>
    )
}

export default DeliveriesList;