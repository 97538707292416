export const dishesMenu = {
	dishes: {
		id: 'dishes',
		text: 'Platos',
		path: '/dishes',
		icon: 'RestaurantMenu',
		permissions_required: {
			entity: 'dishes',
			action: 'admin_dishes'
		},
	},
};

export const dishCategoryMenu = {
	dishCategories: {
		id: 'dishCategories',
		text: 'Categorías de platos',
		path: '/dish-categories',
		icon: 'Category',
		permissions_required: {
			entity: 'dishes',
			action: 'admin_dishes'
		},
	},
};

export const elaborationMenu = {
	elaborations: {
		id: 'elaborations',
		text: 'Elaboraciones',
		path: '/elaborations',
		icon: 'Restaurant',
		permissions_required: {
			entity: 'elaborations',
			action: 'admin_elaborations'
		},
	},
};

export const ingredientMenu = {
	ingredients: {
		id: 'ingredients',
		text: 'Ingredientes',
		path: '/ingredients',
		icon: 'Inventory2',
		permissions_required: {
			entity: 'ingredients',
			action: 'admin_ingredients'
		},
	},
};

export const ingredientCategoryMenu = {
	ingredientCategories: {
		id: 'ingredientCategories',
		text: 'Categorías de ingredientes',
		path: '/ingredient-categories',
		icon: 'Category',
		permissions_required: {
			entity: 'ingredients',
			action: 'admin_ingredients'
		},
	},
};

export const supplierMenu = {
	suppliers: {
		id: 'suppliers',
		text: 'Proveedores',
		path: '/suppliers',
		icon: 'PeopleAlt',
		permissions_required: {
			entity: 'suppliers',
			action: 'list'
		},
	},
};

export const deliveryNotesMenu = {
	deliveryNotes: {
		id: 'deliveryNotes',
		text: 'Albaranes',
		path: '/delivery-notes',
		icon: 'Receipt',
		permissions_required: {
			entity: 'delivery_notes',
			action: 'admin_delivery_notes'
		},
	},
};

export const salesMenu = {
	sales: {
		id: 'sales',
		text: 'Ventas',
		path: '/sales',
		icon: 'PointOfSale',
		permissions_required: {
			entity: 'sales',
			action: 'admin_sales'
		},
	},
};

export const usersMenu = {
	users: {
		id: 'users',
		text: 'Usuarios',
		path: '/users',
		icon: 'Person',
		permissions_required: {
			entity: 'user',
			action: 'admin_user'
		},
	},
};

export const adminMenu = {
	title: {
		id: 'title-admin',
		text: 'Administración',
		icon: 'Extension',
	},
	...dishesMenu,
	...dishCategoryMenu,
	...elaborationMenu,
	...ingredientMenu,
	...ingredientCategoryMenu,
	...supplierMenu,
	...usersMenu,
};

export const financialMenu = {
	title: {
		id: 'title-finance',
		text: 'Finanzas',
		icon: 'Extension',
	},
	...deliveryNotesMenu,
	...salesMenu,
};

export const superAdminMenu = {
	title: {
		id: 'title-super-admin',
		text: 'SuperAdmin',
		icon: 'Extension',
	},
	organizations: {
		id: 'organizations',
		text: 'Organizaciones',
		path: '/organizations',
		icon: 'CorporateFare',
		subMenu: null,
	},
	roles: {
		id: 'roles',
		text: 'Roles',
		path: '/roles',
		icon: 'AdminPanelSettings',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Panel de control',
		path: '/dashboard',
		icon: 'Dashboard',
		permissions_required: {
			entity: 'dashboard',
			action: 'get'
		},
		subMenu: null,
	}
};

export const permissionsPage = {
	permissions: {
		id: 'permissions',
		text: 'Permissions',
		path: '/permissions',
		subMenu: null,
	},
};