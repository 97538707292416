import React from 'react';
import { CustomTable } from '../../../components/table/CustomTable';
import { SalesApiResponse } from '../../../type/sales-type';
import { Link } from 'react-router-dom';
import { FixNumber } from '../../../utils/fixNumber';
import { userIsSuperAdmin } from '../../../utils/userIsSuperAdmin';
import moment from 'moment';
import { FilterOptions } from '../../../hooks/useFilters';

interface SaleLinesTableProps {
    data: any;
    filters: FilterOptions;
    updatePage: (page: number) => void;
    updatePageSize: (limit: number) => void;
    updateFilterOrder: (filter: string) => void;
}

const SaleLinesTable: React.FC<SaleLinesTableProps> = ({ data, filters, updatePage, updatePageSize, updateFilterOrder }) => {
    return (
        <CustomTable
            data={data?.salesHasDishes ? data.salesHasDishes : null}
            pagination={true}
            paginationData={{
                pageSize: filters.limit,
                currentPage: filters.page,
                pageCount: (data as SalesApiResponse) ? data.lastPage : 1,
                totalCount: data.totalRegisters,
                handlePagination: updatePage,
                handlePerPage: updatePageSize,
            }}
            defaultLimit={filters.limit || 50}
            defaultOrder={filters.filter_order || undefined}
            className={"table table-hover"}
            columns={[
                {
                    name: "Código",
                    keyValue: "tpv_sale_id",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <Link to={`${element.sales?.id}/view`} className="d-flex justify-content-center fw-bold" style={{ textDecoration: 'none' }}>
                                {element.sales?.tpvSaleId}
                            </Link>
                        );
                    },
                },
                {
                    name: 'Pedido',
                    keyValue: 'salesHasDishes',
                    className: 'text-center',
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center">
                                {element.quantity} {element.dishes?.name} ({element.price}€)
                            </div>
                        );
                    },
                },
                {
                    name: 'Impuestos',
                    keyValue: 'tax_percentage',
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: 'text-center',
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center text-center">
                                {element.sales?.taxPercentage + ' %' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Precio total",
                    keyValue: "total",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center text-center">
                                {FixNumber(element.sales?.total) + ' €' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Coste medio",
                    keyValue: "average_cost",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center text-center">
                                {FixNumber(element.sales?.totalAverageCost) + ' €' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Coste total",
                    keyValue: "real_cost",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                        return (
                            <div className="d-flex justify-content-center text-center">
                                {FixNumber(element.sales?.totalRealCost) + ' €' ?? "N/A"}
                            </div>
                        );
                    },
                },
                {
                    name: "Organización",
                    keyValue: "company",
                    isVisible: userIsSuperAdmin(),
                    sortable: false,
                    sortColumn: updateFilterOrder,
                    className: `text-center`,
                    render: (element: any) => {
                        return (
                            <span>
                                {element?.company?.name ?? "N/A"}
                            </span>
                        );
                    },
                },
                {
                    name: "Fecha de venta",
                    keyValue: "sale_date",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: `text-center`,
                    render: (element: any) => {
                        return (
                            <div className={"text-center"}>
                                <span className={"text-muted"}>
                                    {moment(element.saleDate?.date).format("DD-MM-YYYY HH:mm") ?? "N/A"}
                                </span>
                            </div>
                        );
                    },
                },
                {
                    name: "Fecha de creación",
                    keyValue: "created_at",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: `text-center`,
                    render: (element: any) => {
                        return (
                            <div className={"text-center"}>
                                <span className={"text-muted"}>
                                    {moment(element.createdAt?.date).format("DD-MM-YYYY HH:mm") ?? "N/A"}
                                </span>
                            </div>
                        );
                    },
                },
            ]}
        />
    );
};

export default SaleLinesTable;