import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';

const SUPPLIER_ENDPOINT = '/suppliers';
export class SupplierService extends RestServiceConnection {

    /**
     * ------------------------------------------------------------------------
     * EN: SUPPLIER REQUEST SERVICE
     * ES: SERVICIO DE PROVEEDORES
     * ------------------------------------------------------------------------
     */

    getSuppliers = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SUPPLIER_ENDPOINT + '/list',
            data: {
                ...filters
            },
        }, true) as AxiosResponse;
        return this;
    }

    getSupplierById = async (id: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SUPPLIER_ENDPOINT + '/get',
            data: {
                supplierId: id
            }
        }, true);
        return this;
    }

    createSupplier = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SUPPLIER_ENDPOINT + '/create',
            data: values,
        }, true);
        return this;
    }

    editSupplier = async (values: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SUPPLIER_ENDPOINT + '/edit',
            data: values,
            headers: {
                "Content-Type": "application/json"
            }
        }, true);
        return this;
    }

    deleteSupplier = async (supplierId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SUPPLIER_ENDPOINT + '/delete',
            data: {
                supplierId
            }
        }, true);
        return this;
    }

}