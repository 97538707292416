import React, { useState } from 'react';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import { FilterOptions } from '../../../hooks/useFilters';
import Input from '../../../components/bootstrap/forms/Input';
import SearchableSelect from '../../../components/SearchableSelect';
import useIngredientSuppliers from '../../../hooks/useIngredientSuppliers';
import moment from 'moment';
import PriceDropdown from '../../../components/PriceDropdown';

interface IngredientCostsFiltersProps {
    filters: FilterOptions;
    updateFilters: (filters: any) => void;
    resetFilters: (range: number) => void;
}

const IngredientCostsFilters: React.FC<IngredientCostsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const { getIngredientSuppliersList } = useIngredientSuppliers();

    const [filterMenu, setFilterMenu] = useState(false);

    const getSupplierById = (id: string[]) => {
        const supplier = getIngredientSuppliersList().filter((s: any) => id?.includes(s.value));
        if (supplier) {
            return supplier;
        }
        return null;
    };

    return (
        <div className='d-flex justify-content-end align-items-center'>
            <PriceDropdown filters={filters} updateFilters={updateFilters} className='me-3' />

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'>Filtrar precios</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <FormGroup label='Proveedor:' className='col-md-12' key={filters.filter_filters?.supplier_id}>
                            <SearchableSelect
                                name='supplier'
                                isMulti
                                isClearable
                                options={getIngredientSuppliersList()}
                                onChange={(value: any) => updateFilters({ supplier_id: value.map((s: any) => s.value) })}
                                defaultValue={getSupplierById(filters.filter_filters?.supplier_id)}
                                placeholder='proveedor'
                            />
                        </FormGroup>

                        <FormGroup label='Rango de fechas:' className='col-md-6'>
                            <Dropdown>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CalendarToday'>
                                        {filters.filter_filters?.date?.mode === "months" ? "Mes" : filters.filter_filters?.date?.mode === "weeks" ? "Semana" : "Día"}
                                    </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='link' icon='calendar_view_month' onClick={() => updateFilters({ date: { ...filters.filter_filters?.date, mode: "months" } })}>Mes</Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='link' icon='calendar_view_week' onClick={() => updateFilters({ date: { ...filters.filter_filters?.date, mode: "weeks" } })}>Semana</Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='link' icon='calendar_view_day' onClick={() => updateFilters({ date: { ...filters.filter_filters?.date, mode: "days" } })}>Día</Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </FormGroup>

                        <FormGroup label='Últimas compras:' className='col-md-6'>
                            <Input
                                type='number'
                                value={filters.filter_filters?.range}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (Number(e.target.value) > 0 && Number(e.target.value) < 1000) {
                                        updateFilters({ range: Number(e.target.value) })
                                    }
                                }}
                                className='w-50'
                                min={1}
                                max={1000}
                            />
                        </FormGroup>

                        <FormGroup label='Desde:' className='col-md-6'>
                            <input
                                type='date'
                                className='form-control'
                                value={filters.filter_filters?.date?.from}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ date: { ...filters.filter_filters?.date, from: e.target.value } })}
                            />
                        </FormGroup>

                        <FormGroup label='Hasta:' className='col-md-6'>
                            <input
                                type='date'
                                className='form-control'
                                value={filters.filter_filters?.date?.to}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ date: { ...filters.filter_filters?.date, to: e.target.value } })}
                            />
                        </FormGroup>

                        <div className='col-md-12'>
                            <Button
                                className='mt-4' color="storybook" isLight
                                onClick={() => {
                                    updateFilters({ supplier_id: '' });
                                    updateFilters({ date: { mode: 'days', from: moment().subtract(1, 'months').format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD'), } });
                                    updateFilters({ range: 10 });
                                }}
                            >
                                Resetear
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </div>
    );
};

export default IngredientCostsFilters;