import { useFormik } from "formik";
import { FC, Fragment, useState } from "react";
import Button from "../../components/bootstrap/Button";
import Card, { CardBody, CardFooter, CardHeader, CardTitle } from "../../components/bootstrap/Card";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Spinner from "../../components/bootstrap/Spinner";
import PlaceholderImage from "../../components/extras/PlaceholderImage";
import * as yup from "yup";
import AsyncImg from "../../components/AsyncImg";
import 'react-phone-input-2/lib/style.css'
import { CompanyService } from "../../services/companies/companyService";
import { toast } from "react-toastify";
import { handleConfirmationAlert } from "../../utils/ConfirmationAlert";
import { useParams } from "react-router-dom";

interface CreateFormProps {
    submit: Function;
    isLoading?: boolean;
    companyData?: any;
    logo?: any;
}

export interface ICompanyForm {
    cif?: string;
    name?: string;
    address?: string;
    logo?: Document;
}

const CompanySchema = yup.object({
    name: yup.string().min(1, 'Demasido corto').max(100, 'Demasiado largo').required('Campo obligatorio'),
    cif: yup.string().matches(/^[A-H|J|N|P|Q|R|S|U|V|W]{1}[0-9]{7}[0-9,A-J]{1}$/, 'Formato C.I.F inválido').required('Campo obligatorio'),
    address: yup.string().min(1, 'Demasido corto').max(100, 'Demasiado largo'),
});

const CompanyForm: FC<CreateFormProps> = ({ submit, isLoading, companyData, logo }) => {

    const { id = '' } = useParams<{ id: string }>();
    const mode = companyData ? 'Editar' : 'Crear';

    const [selectedImage, setSelectedImage] = useState<any>(null);

    const companyInitialValues: ICompanyForm = {
        cif: companyData?.cif,
        name: companyData?.name,
        address: companyData?.address,
    }

    const formik = useFormik({
        initialValues: companyInitialValues,
        validationSchema: CompanySchema,
        onSubmit: values => submit(values),
    });

    const handleImageUpload = async (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        try {
            const organizationService = new CompanyService();
            const response = await organizationService.editOrganizationImg(id, file);

            const responseData = response.getResponseData();

            if (responseData.success) {
                setTimeout(() => {
                    toast.success('Logo actualizado');
                }, 100);
            } else {
                toast.error(responseData.message);
            }
        } catch (error: any) {
            toast.error("Formato de imagen incorrecto");
        }
    };

    const deleteImage = async () => {
        handleConfirmationAlert({
            title: '¿Estás seguro?',
            text: 'Se eliminará el logo de la organización',
            icon: 'warning',
            onConfirm: async () => {
                try {
                    const organizationService = new CompanyService();
                    const response = await organizationService.deleteOrganizationImg(id);

                    const responseData = response.getResponseData();

                    if (responseData.success) {
                        setSelectedImage(null);
                        window.location.reload();
                    }
                } catch (error: any) {
                    toast.error("Error al eliminar el logo de la organización");
                }
            }
        })
    };

    const verifyClass = (inputFieldID: keyof ICompanyForm) => {
        if (formik.touched[inputFieldID]) { return (formik.errors[inputFieldID]) ? 'is-invalid' : '' } return '';
    };

    const showErrors = (inputFieldID: keyof ICompanyForm) => {
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete={'off'}>
                <CardBody isScrollable={false} className="row justify-content-between">
                    {mode === 'Editar' && (
                        <div className="col-md-3">
                            <CardHeader className='rounded-1 mb-0'>
                                <CardTitle>Logo</CardTitle>
                            </CardHeader>
                            <CardBody className={'text-center'}>
                                <div className={'text-center pb-4'}>
                                    {selectedImage
                                        ? <img width={200} height={200} src={selectedImage} alt="selected" className='mx-auto d-block img-fluid mb-3 rounded' />
                                        : logo
                                            ? <AsyncImg isBackground height="200px" width="200px" styles="avatar-content" id={logo.id} />
                                            : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                                    }
                                </div>

                                <Input type='file' onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)} autoComplete='photo' placeholder={"Cambiar imagen"} />
                            </CardBody>
                            <CardFooter className={'justify-content-center'}>
                                <Button color='dark' isLight icon='Delete' onClick={deleteImage}>Eliminar imagen</Button>
                            </CardFooter>
                        </div>
                    )}

                    <div className="col-md-8">
                        <CardHeader className='rounded-1 mb-0'>
                            <CardTitle>Datos</CardTitle>
                        </CardHeader>
                        <CardBody className="row g-3 d-flex justify-content-between ">
                            <FormGroup requiredInputLabel label='Nombre' className='col-md-6'>
                                <Input id='name' onChange={formik.handleChange} value={formik.values.name || ''} onBlur={formik.handleBlur} className={verifyClass('name')} />
                                {showErrors('name')}
                            </FormGroup>

                            <FormGroup requiredInputLabel label='CIF' className='col-md-3'>
                                <Input id='cif' onChange={formik.handleChange} value={formik.values.cif || ''} onBlur={formik.handleBlur} className={verifyClass('cif')} />
                                {showErrors('cif')}
                            </FormGroup>

                            <FormGroup label='Dirección' className='col-md-12'>
                                <Input id='address' onChange={formik.handleChange} value={formik.values.address || ''} onBlur={formik.handleBlur} className={verifyClass('address')} />
                                {showErrors('address')}
                            </FormGroup>
                        </CardBody>
                    </div>
                </CardBody>

                <CardFooter className="justify-content-center">
                    <Button type="submit" color='primary' icon='Save'>
                        {isLoading ? <Spinner /> : `${mode} Organización`}
                    </Button>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default CompanyForm;