import { Fragment, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle, } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from "../../../layout/SubHeader/SubHeader";
import IngredientsFilters from "./ingredients-options/IngredientsFilters";
import useFetch from "../../../hooks/useFetch";
import { CustomTable } from "../../../components/table/CustomTable";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import moment from "moment";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { Link } from "react-router-dom";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { IngredientService } from "../../../services/ingredients/ingredientService";
import { IngredientsApiResponse } from "../../../type/ingredient-type";
import { userIsSuperAdmin } from "../../../utils/userIsSuperAdmin";
import { FixNumber } from "../../../utils/fixNumber";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import ErrorMessage from "../../../components/ErrorMessage";

const IngredientsList = () => {

  const navigate = useNavigate();
  const { userCan } = useContext(PrivilegeContext);

  const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

  const [data, loading, error] = useFetch(useCallback(async () => {
    const ingredientService = new IngredientService();
    const response = await ingredientService.getIngredients(filters);
    return response.getResponseData() as IngredientsApiResponse;
  }, [filters]));

  /**
   * Deletes a user with the given ID.
   *
   * @param {string} id - The ID of the user to be deleted.
   */
  const deleteIngredient = async (id: string) => {
    let response = (await new IngredientService().deleteIngredient(id)).getResponseData();
    if (response.success) {
      toast.success("Usuario eliminado correctamente");
      updateFilters({ ...filters });
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Fragment>
            <CardTitle>Listado de Ingredientes</CardTitle>
            {userCan("create", "ingredients") &&
              <>
                <SubheaderSeparator />
                <Button color="light" icon="Add" isLight onClick={() => { navigate("create") }}>
                  Añadir Ingrediente
                </Button>
              </>
            }
          </Fragment>
        </SubHeaderLeft>
        <SubHeaderRight>
          <IngredientsFilters updateFilters={updateFilters} filters={filters} resetFilters={resetFilters} />
        </SubHeaderRight>
      </SubHeader>

      <Page container="fluid">
        <Card stretch={false}>
          {error && <ErrorMessage error={error} />}

          {data && data.ingredients
            ? (
              <CustomTable
                data={data?.ingredients ? data.ingredients : null}
                pagination={true}
                paginationData={{
                  pageSize: filters.limit,
                  currentPage: filters.page,
                  pageCount: (data as IngredientsApiResponse) ? data.lastPage : 1,
                  totalCount: data.totalRegisters,
                  handlePagination: updatePage,
                  handlePerPage: updatePageSize,
                }}
                defaultLimit={filters.limit || 50}
                defaultOrder={filters.filter_order || undefined}
                className={"table table-hover"}
                columns={[
                  {
                    name: "Nombre",
                    keyValue: "name",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <Link to={`/ingredients/${element.id}/view`} style={{ textDecoration: 'none' }}>
                          <div className="d-flex justify-content-center text-center fw-bold">
                            {element.name}
                          </div>
                        </Link>
                      );
                    },
                  },
                  {
                    name: "Categoría",
                    keyValue: "ingredient_category",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center text-center">
                          {element.ingredientCategories.name}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Precio medio (P.M)",
                    keyValue: "average_price",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    cellClassName: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center text-center">
                          {FixNumber(element.averagePrice)} €
                        </div>
                      );
                    }
                  },
                  {
                    name: "P.M ponderado",
                    keyValue: "weighted_average_price",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    cellClassName: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center text-center">
                          {FixNumber(element.weightedAveragePrice)} €
                        </div>
                      );
                    }
                  },
                  {
                    name: "Último precio",
                    keyValue: "last_price",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    cellClassName: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center text-center">
                          {FixNumber(element.lastPrice)} €
                        </div>
                      );
                    }
                  },
                  {
                    name: "Últimas compras",
                    keyValue: "average_price_range",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    cellClassName: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center text-center">
                          {element.averagePriceRange}
                        </div>
                      );
                    }
                  },
                  {
                    name: "Usuario",
                    keyValue: "user",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        <div className="d-flex justify-content-center text-center">
                          {element.user ? element?.user?.name + (element.user?.lastName && (' ' + element.user?.lastName)) : "N/A"}
                        </div>
                      );
                    },
                  },
                  {
                    name: "Proveedores",
                    keyValue: "supplier",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: "text-center",
                    render: (element: any) => {
                      return (
                        element?.ingredientHasSupplierCode?.map((supplier: any) => {
                          return (
                            <div className="d-flex justify-content-center text-center" key={supplier.supplier.id}>
                              {supplier.supplier.name}
                            </div>
                          );
                        })
                      );
                    },
                  },
                  {
                    name: "Organización",
                    keyValue: "company",
                    isVisible: userIsSuperAdmin(),
                    sortable: false,
                    sortColumn: updateFilterOrder,
                    className: `text-center`,
                    render: (element: any) => {
                      return (
                        <span>
                          {element?.company?.name ?? "N/A"}
                        </span>
                      );
                    },
                  },
                  {
                    name: "Fecha de creación",
                    keyValue: "created_at",
                    sortable: true,
                    sortColumn: updateFilterOrder,
                    className: `text-center`,
                    render: (element: any) => {
                      return (
                        <div className={"text-center"}>
                          <span className={"text-muted"}>
                            {moment(element.createdAt.date).format("DD-MM-YYYY") ?? "N/A"}
                          </span>
                        </div>
                      );
                    },
                  },
                  { name: "Acciones", className: "min-w-100px text-end", isActionCell: true },
                ]}
                actions={[
                  {
                    title: "Editar",
                    buttonType: "icon",
                    iconColor: "text-info",
                    iconPath: "/media/icons/duotune/general/gen055.svg",
                    additionalClasses: "text-primary",
                    description: "Editar ingrediente",
                    hide: () => !userCan('edit', 'ingredients'),
                    callback: (item: any) => {
                      navigate(`${item.id}/edit`);
                    },
                  },
                  {
                    title: "Eliminar",
                    buttonType: "icon",
                    iconColor: "text-danger",
                    iconPath: "/media/icons/duotune/general/gen027.svg",
                    additionalClasses: "text-danger",
                    description: "Eliminar ingrediente",
                    hide: () => !userCan('delete', 'ingredients'),
                    callback: (item: any) => {
                      handleConfirmationAlert({
                        title: "Eliminar ingrediente",
                        text: "¿Estás seguro de que deseas eliminar el ingrediente?",
                        icon: "warning",
                        onConfirm: () => { deleteIngredient(item.id) },
                      });
                    },
                  },
                ]}
              />
            )
            : !error && <Loader />
          }
        </Card>
      </Page>
    </Fragment>
  );
};

export default IngredientsList;