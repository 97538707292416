import React, { useState } from 'react';
import { FilterOptions } from '../../hooks/useFilters';
import PriceDropdown from '../PriceDropdown';
import Button from '../bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import FormGroup from '../bootstrap/forms/FormGroup';
import moment from 'moment';
import RangeDropdown from '../RangeDropdown';

interface GraphFiltersProps {
    filters: FilterOptions;
    updateFilters: (filters: any) => void;
    resetFilters: (range: number) => void;
}

const GraphFilters: React.FC<GraphFiltersProps> = ({ filters, updateFilters }) => {

    const [filterMenu, setFilterMenu] = useState(false);

    return (
        <div className='d-flex justify-content-end align-items-center me-5'>
            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters' isBodyScroll placement='end'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='userFilters'>Filtrar Gráficas</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className='row g-4'>
                        <PriceDropdown filters={filters} updateFilters={updateFilters} label='Mostrar costes por:' />
                        <RangeDropdown filters={filters} updateFilters={updateFilters} className='col-md-12' />

                        <FormGroup label='Desde:' className='col-md-6'>
                            <input
                                type='date'
                                className='form-control'
                                value={filters.filter_filters?.date?.from}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ date: { ...filters.filter_filters?.date, from: e.target.value } })}
                            />
                        </FormGroup>

                        <FormGroup label='Hasta:' className='col-md-6'>
                            <input
                                type='date'
                                className='form-control'
                                value={filters.filter_filters?.date?.to}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ date: { ...filters.filter_filters?.date, to: e.target.value } })}
                            />
                        </FormGroup>

                        <div className='col-md-12'>
                            <Button
                                className='mt-4' color="storybook" isLight
                                onClick={() => {
                                    updateFilters({ supplier_id: '' });
                                    updateFilters({ date: { mode: 'days', from: moment().subtract(1, 'months').format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD'), } });
                                    updateFilters({ range: 10 });
                                }}
                            >
                                Resetear
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>
            </OffCanvas>
        </div>
    );
};

export default GraphFilters;