import React, { Fragment, useCallback, useContext, useState } from 'react';
import { EditSaleFieldsModel, SaleDish } from '../../type/sales-type';
import * as yup from 'yup';
import { PrivilegeContext } from '../../components/priviledge/PriviledgeProvider';
import useCompanies from '../../hooks/useCompanies';
import useFetch from '../../hooks/useFetch';
import { DishService } from '../../services/dishes/dishService';
import { DishesApiResponse } from '../../type/dish-type';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { CardBody, CardFooter, CardTitle } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Select from '../../components/bootstrap/forms/Select';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import Spinner from '../../components/bootstrap/Spinner';
import SaleDishes from './components/EditSaleDishes';

interface SaleFormProps {
    isLoading: boolean;
    submit: (values: EditSaleFieldsModel) => Promise<void>;
    data: EditSaleFieldsModel;
}

const schema = yup.object({
    tpvSaleId: yup.string().min(1, 'Demasido corto').max(50, 'Demasiado largo').required('El código TPV es obligatorio'),
    taxPercentage: yup.number().required('El porcentaje de impuestos es obligatorio').min(0, 'El porcentaje mínimo es 0').max(100, 'El porcentaje máximo es 100'),
    saleDate: yup.date().required('La fecha de la venta es obligatoria'),
    company: yup.string(),
});

const SaleForm: React.FC<SaleFormProps> = ({ isLoading, submit, data }) => {

    const { userCan } = useContext(PrivilegeContext);
    const { getCompanyList } = useCompanies();
    const { id = '' } = useParams<{ id: string }>();

    const [dishesList, setDishesList] = useState<SaleDish[]>(data.saleDishes || []);

    const [dishesData] = useFetch(useCallback(async () => {
        const response = await (new DishService()).getDishes();
        return response.getResponseData() as DishesApiResponse;
    }, []));

    const formik = useFormik<EditSaleFieldsModel>({
        initialValues: {
            saleId: id || '',
            tpvSaleId: data?.tpvSaleId || '',
            taxPercentage: data?.taxPercentage || 0,
            saleDate: data?.saleDate || '',
            saleDishes: data?.saleDishes || [],
            company: data?.company || '',
        },
        validationSchema: schema,
        onSubmit: (values) => {
            values.saleDishes = dishesList;
            if (formikDishes.values.dishId !== '' && formikDishes.values.quantity !== 0 && formikDishes.values.price !== 0) {
                values.saleDishes.push(formikDishes.values);
            }
            submit(values);
        },
    });

    const formikDishes = useFormik<SaleDish>({
        initialValues: {
            id: '',
            dishId: '',
            quantity: 1,
            price: 0,
        },
        validate: (values) => {
            const errors: any = {};
            if (values.dishId === '') errors.dishId = 'El plato es obligatorio';
            if (values.price < 0) errors.price = 'El precio mínimo es 0';
            return errors;
        },
        onSubmit: (values) => {
            setDishesList([...dishesList, values]);
            formikDishes.resetForm();
        },
    });

    const verifyClass = (inputFieldID: keyof EditSaleFieldsModel) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof EditSaleFieldsModel) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody isScrollable={false} className='row g-3 p-5 justify-content-between'>
                    <CardTitle>Información general</CardTitle>
                    {userCan('admin_company', 'companies') && (
                        <FormGroup label='Organización' className='col-md-3'>
                            <Select id='company' onChange={formik.handleChange} className={verifyClass('company')}
                                value={formik.values.company || ''} ariaLabel='Default select example'
                                placeholder='Elegir organización ...'
                                list={getCompanyList()}
                            />
                            {showErrors('company')}
                        </FormGroup>
                    )}

                    <FormGroup requiredInputLabel label='Código TPV' className='col-md-3'>
                        <Input id='tpvSaleId' onChange={formik.handleChange} value={formik.values.tpvSaleId || ''}
                            onBlur={formik.handleBlur} className={verifyClass('tpvSaleId')} />
                        {showErrors('tpvSaleId')}
                    </FormGroup>

                    <FormGroup requiredInputLabel label='Impuestos (%)' className='col-md-3'>
                        <Input
                            id='taxPercentage' type='number' min={0} max={100}
                            onChange={formik.handleChange} value={formik.values.taxPercentage || ''}
                            onBlur={formik.handleBlur} className={'w-50 ' + verifyClass('taxPercentage')}
                        />
                        {showErrors('taxPercentage')}
                    </FormGroup>

                    <FormGroup requiredInputLabel label='Fecha de la venta' className='col-md-3'>
                        <Input id='saleDate' onChange={formik.handleChange} value={formik.values.saleDate || ''}
                            type='date' onBlur={formik.handleBlur} className={verifyClass('saleDate')} />
                        {showErrors('saleDate')}
                    </FormGroup>

                    <SaleDishes formik={formikDishes} dishesData={dishesData} dishesList={dishesList} setDishesList={setDishesList} />
                </CardBody>
                <CardFooter className="d-flex justify-content-center">
                    <Button type="submit" size='lg' color='primary'>
                        {isLoading ? <Spinner isSmall /> : 'Editar Venta'}
                    </Button>
                </CardFooter>
            </form>
        </Fragment>
    );
};

export default SaleForm;