import { FC, useCallback, useEffect } from "react";
import Input from "../../../components/bootstrap/forms/Input";
import SearchableSelect from "../../../components/SearchableSelect";
import Button from "../../../components/bootstrap/Button";
import { IngredientService } from "../../../services/ingredients/ingredientService";
import { IngredientsApiResponse } from "../../../type/ingredient-type";
import useFetch from "../../../hooks/useFetch";
import { CardTitle } from "../../../components/bootstrap/Card";
import FormLabel from "../../../components/bootstrap/forms/FormLabel";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import { DeliveryNoteLine } from "../../../type/deliveryNote-type";
import { FilterOptions } from "../../../hooks/useFilters";
import { FixNumber } from "../../../utils/fixNumber";

interface DeliveryNoteLinesListProps {
    formik: any;
    filters: FilterOptions;
    deliveryNoteLinesList: any;
    setDeliveryNoteLinesList: any;
}

const DeliveryNoteLinesList: FC<DeliveryNoteLinesListProps> = ({ formik, filters, deliveryNoteLinesList, setDeliveryNoteLinesList }) => {

    const newDeliveryNoteLinesListList = [...deliveryNoteLinesList];

    const [ingredients] = useFetch(useCallback(async () => {
        if (filters.filter_filters?.supplier === '') return;
        const response = await (new IngredientService()).getIngredients(filters);
        return response.getResponseData() as IngredientsApiResponse;
    }, [filters]));

    const getIngredients = () => {
        const ingredientsNames: any = [];
        ingredients?.ingredients.map((ingredient: any) => {
            ingredientsNames.push({
                value: ingredient.id,
                label: ingredient.name,
                measureUnit: ingredient.purchaseUnitMeasure,
                hasUnits: ingredient.ingredientHasSupplierCode[0]?.hasUnits
            });
        });
        return ingredientsNames;
    };

    const verifyClass = (inputFieldID: keyof DeliveryNoteLine) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof DeliveryNoteLine) => {
        const error = formik.touched[inputFieldID] && formik.errors[inputFieldID];
        return (error ? <div className="invalid-feedback">{String(error)}</div> : <></>);
    };

    useEffect(() => {
        formik.values.ingredientId = '';
    }, [filters]);

    return (
        <>
            <CardTitle className="mt-5 mb-4">Líneas de Albarán</CardTitle>

            {deliveryNoteLinesList.length > 0 && (
                <div className="row">
                    <FormLabel label='Ingrediente' cols={3} required />
                    <FormLabel label='Cantidad' cols={1} required />
                    <FormLabel label='U. de medida' cols={1} />
                    <FormLabel label='Descuento (%)' cols={2} />
                    <FormLabel label='Precio (€)' cols={1} required />
                    <FormLabel label='Último precio' cols={1} />
                    <FormLabel label='Coste unitario' cols={2} />
                </div>
            )}

            {deliveryNoteLinesList.map((line: any, index: number) => {
                const ingredientSelected = getIngredients().filter((i: any) => i.value === line.ingredientId);
                const defaultValue = ingredientSelected[0] ? { value: ingredientSelected[0].value, label: ingredientSelected[0].label } : null;

                // Calculate the unit price of the ingredient depending on the units
                const unitPriceQuantity = line.ingredient?.ingredientHasSupplierCode[0]?.hasUnits
                    ? (line.price / (line.quantity * line.ingredient?.ingredientHasSupplierCode[0]?.equivalentQuantity)) * (1 - (line.discount / 100))
                    : (line.price / line.quantity) * (1 - (line.discount / 100));

                // Change the color of the unit price depending on the last price of the ingredient
                const unitPriceColor = FixNumber(line.ingredient?.lastPrice) > FixNumber(unitPriceQuantity)
                    ? 'text-success'
                    : FixNumber(line.ingredient?.lastPrice) === FixNumber(unitPriceQuantity)
                        ? 'text-dark'
                        : line.ingredient?.lastPrice && 'text-danger';

                return (
                    <div className="row mb-3" key={line.id}>
                        <div className="col-md-3" key={defaultValue?.value}>
                            <SearchableSelect
                                isSearchable
                                name={line.name + index.toString()}
                                defaultValue={defaultValue}
                                options={getIngredients()}
                                onChange={(e: any) => {
                                    newDeliveryNoteLinesListList[index].name = e.value;
                                    newDeliveryNoteLinesListList[index].measureUnit = e.measureUnit;
                                    e.hasUnits ? newDeliveryNoteLinesListList[index].hasUnits = true : newDeliveryNoteLinesListList[index].hasUnits = false;
                                    setDeliveryNoteLinesList(newDeliveryNoteLinesListList);
                                }}
                                placeholder={"un ingrediente "}
                            />
                        </div>

                        <div className="col-md-1">
                            <Input
                                type="number"
                                step={0.0001}
                                id={line.quantity.toString() + index.toString()}
                                value={line.quantity}
                                onChange={(e: any) => {
                                    newDeliveryNoteLinesListList[index].quantity = e.target.value;
                                    setDeliveryNoteLinesList(newDeliveryNoteLinesListList);
                                }}
                                className="pe-0"
                            />
                        </div>

                        <div className="col-md-1">
                            <Input
                                id={line.measureUnit.toString() + index.toString()}
                                value={line.hasUnits ? 'u' : line.measureUnit}
                                disabled
                            />
                        </div>

                        <div className="col-md-2">
                            <Input
                                type="number"
                                id={line.discount.toString() + index.toString()}
                                value={line.discount}
                                onChange={(e: any) => {
                                    newDeliveryNoteLinesListList[index].discount = e.target.value;
                                    setDeliveryNoteLinesList(newDeliveryNoteLinesListList);
                                }}
                                min={0}
                                max={100}
                                className="w-50"
                            />
                        </div>

                        <div className="col-md-1">
                            <Input
                                type="number"
                                step={0.01}
                                id={line.price.toString() + index.toString()}
                                value={line.price}
                                onChange={(e: any) => {
                                    newDeliveryNoteLinesListList[index].price = e.target.value;
                                    setDeliveryNoteLinesList(newDeliveryNoteLinesListList);
                                }}
                                className="pe-0"
                            />
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <p>{line.ingredient?.lastPrice ? `${FixNumber(line.ingredient?.lastPrice)} €` : 'N/A'}</p>
                        </div>

                        <div className="col-md-1 d-flex justify-content-center align-items-center">
                            <p className={unitPriceColor}>{FixNumber(unitPriceQuantity)} €</p>
                        </div>

                        <div className="col-md-1 d-flex justify-content-end p-0">
                            <Button
                                color='dark'
                                isLight
                                icon='Delete'
                                title="Eliminar línea"
                                onClick={() => {
                                    const newDeliveryNoteLinesListListData = deliveryNoteLinesList.filter((l: any) => l !== deliveryNoteLinesList[index]);
                                    setDeliveryNoteLinesList(newDeliveryNoteLinesListListData);
                                }}
                            />
                        </div>
                    </div>
                )
            })}

            <div className="row mt-2">
                <FormGroup label="Ingrediente" requiredInputLabel className="col-md-3">
                    <SearchableSelect
                        isSearchable
                        name="ingredientId"
                        defaultValue={null}
                        options={getIngredients().filter((i: any) => !deliveryNoteLinesList.map((ingredient: any) => ingredient.ingredientId).includes(i.value))}
                        onChange={(e: any) => {
                            formik.setFieldValue('ingredientId', e.value);
                            formik.setFieldValue('measureUnit', e.measureUnit);
                            e.hasUnits ? formik.setFieldValue('hasUnits', true) : formik.setFieldValue('hasUnits', false);
                        }}
                        placeholder={"un ingrediente "}
                    />
                    {showErrors('ingredientId')}
                </FormGroup>

                <FormGroup label="Cantidad" requiredInputLabel className="col-md-1">
                    <Input
                        type="number"
                        step={0.0001}
                        id="quantity"
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        className={verifyClass('quantity') + ' pe-0'}
                    />
                    {showErrors('quantity')}
                </FormGroup>

                <FormGroup label="U. Medida" className="col-md-1">
                    <Input
                        id="measureUnit"
                        value={formik.values.hasUnits ? 'u' : formik.values.measureUnit}
                        disabled
                    />
                </FormGroup>

                <FormGroup label="Descuento (%)" requiredInputLabel className="col-md-2">
                    <Input
                        type="number"
                        id="discount"
                        value={formik.values.discount}
                        onChange={formik.handleChange}
                        min={0}
                        max={100}
                        className="w-50"
                    />
                </FormGroup>

                <FormGroup label="Precio (€)" requiredInputLabel className="col-md-1">
                    <Input
                        type="number"
                        step={0.01}
                        id="price"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        className={verifyClass('price') + ' pe-0'}
                    />
                    {showErrors('price')}
                </FormGroup>

                <div className="col-md-2" />

                <div className="col-md-12 d-flex justify-content-center mt-4">
                    <Button type="submit" icon="Add" color='primary' isLight title="Añadir línea" onClick={formik.handleSubmit} />
                </div>
            </div>
        </>
    )
}

export default DeliveryNoteLinesList;