import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SupplierService } from "../../../services/suppliers/suppliersService";
import { toast } from "react-toastify";
import SupplierForm from "../SupplierForm";
import ErrorMessage from "../../../components/ErrorMessage";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";

const EditSupplier = () => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const supplierService = new SupplierService;

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [supplier, setSupplier] = useState<any>(null);

    const getSupplier = async () => {
        const response = (await supplierService.getSupplierById(id)).getResponseData()
        if (response.success) {
            setSupplier(response.data)
        }
    };

    useEffect(() => { getSupplier() }, []);

    const handleEdit = async (values: any) => {
        setLoading(true);
        values.supplierId = id;

        try {
            const response = await (await supplierService.editSupplier(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                toast.success('Proveedor editado correctamente');
            } else if (response.data.errors) {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            } else {
                throw new Error(response.message);
            }
        } catch (error: any) {
            toast.error(error.message || 'Error al editar el proveedor');
            setError(error.message);
            setTimeout(() => {
                setError(error.message);
            }, 5000);
        } finally {
            setLoading(false);
        }
    };

    if (error) return <ErrorMessage />;

    return (
        supplier !== null
            ? <SupplierForm submit={handleEdit} isLoading={loading} supplierData={supplier} />
            : <Loader />
    );

}

export default EditSupplier;