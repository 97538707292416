import { FC, Fragment, useState } from "react"
import { FilterOptions } from "../../../../hooks/useFilters"
import useCompanies from "../../../../hooks/useCompanies"
import Button from "../../../../components/bootstrap/Button"
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput"
import Icon from "../../../../components/icon/Icon"
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../../components/bootstrap/OffCanvas"
import FormGroup from "../../../../components/bootstrap/forms/FormGroup"
import { userIsSuperAdmin } from "../../../../utils/userIsSuperAdmin"
import Select from "../../../../components/bootstrap/forms/Select"
import Input from "../../../../components/bootstrap/forms/Input"
import FormLabel from "../../../../components/bootstrap/forms/FormLabel"
import SearchableSelect from "../../../../components/SearchableSelect"
import useIngredientSuppliers from "../../../../hooks/useIngredientSuppliers"

interface IIngretientFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit?: any) => void
    filters: FilterOptions
}

const DishesFilters: FC<IIngretientFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

    const { getCompanyList } = useCompanies();
    const { getIngredientSuppliersList } = useIngredientSuppliers();

    const [filterMenu, setFilterMenu] = useState(false);

    const handleTextChange = (search: string) => {
        updateFilters({ search_array: search });
    };

    const getSupplierById = (id: string[]) => {
        const supplier = getIngredientSuppliersList().filter((s: any) => id?.includes(s.value));
        if (supplier) {
            return supplier;
        }
        return null;
    };

    return (
        <Fragment>
            <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                <Icon icon='Search' size='2x' color='primary' />
            </label>
            <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''} />

            <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
                Filtros
            </Button>

            <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='dishFilters'>
                <OffCanvasHeader setOpen={setFilterMenu}>
                    <OffCanvasTitle id='dishFilters'>Filtros de Albaranes</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        {userIsSuperAdmin() &&
                            <FormGroup label='Organización:' className="col-md-12">
                                <Select
                                    id='company'
                                    list={getCompanyList()}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })}
                                    value={filters.filter_filters?.company || ''}
                                    placeholder='Elegir organización ...'
                                    ariaLabel='Default select example'
                                />
                            </FormGroup>
                        }

                        <FormGroup label='Proveedor:' className='col-md-12'>
                            <SearchableSelect
                                name='supplier'
                                isMulti
                                isClearable
                                options={getIngredientSuppliersList()}
                                onChange={(value: any) => updateFilters({ suppliers: value.map((s: any) => s.value) })}
                                defaultValue={getSupplierById(filters.filter_filters?.suppliers)}
                                placeholder='proveedor'
                            />
                        </FormGroup>

                        <div className="col-md-12">
                            <>
                                <div className="row d-flex justify-content-between">
                                    <FormLabel label="Rango de precios:" className="col-md-12" />
                                    <FormGroup className=' col-md-5'>
                                        <Input
                                            type='number'
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({
                                                price_range: {
                                                    min: e.target.value,
                                                    max: filters.filter_filters?.price_range?.max ?? 0,
                                                }
                                            })}
                                            value={filters.filter_filters?.price_range?.min ?? ''}
                                            placeholder='Precio mínimo'
                                        />
                                    </FormGroup>

                                    <FormGroup className=' col-md-5'>
                                        <Input
                                            type='number'
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({
                                                price_range: {
                                                    max: e.target.value,
                                                    min: filters.filter_filters?.price_range?.min ?? 0,
                                                }
                                            })}
                                            value={filters.filter_filters?.price_range?.max ?? ''}
                                            placeholder='Precio máximo'
                                        />
                                    </FormGroup>
                                </div>
                            </>
                        </div>

                        <FormGroup label='Desde:' className="col-md-6">
                            <Input
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ date: { ...filters.filter_filters?.date, from: e.target.value } })}
                                value={filters.filter_filters?.date?.from}
                            />
                        </FormGroup>

                        <FormGroup label='Hasta:' className="col-md-6">
                            <Input
                                type='date'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ date: { ...filters.filter_filters?.date, to: e.target.value } })}
                                value={filters.filter_filters?.date?.to}
                            />
                        </FormGroup>

                        <div className='col-12'>
                            <Button
                                className='mt-4' color="storybook" isLight
                                onClick={() => {
                                    resetFilters();
                                    updateFilters({ date: { from: '', to: '' }, suppliers: '' });
                                }}
                            >
                                Resetear
                            </Button>
                        </div>
                    </div>
                </OffCanvasBody>

            </OffCanvas>
        </Fragment>
    )
}

export default DishesFilters;