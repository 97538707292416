import {
	dashboardPagesMenu,
	superAdminMenu,
	usersMenu,
	permissionsPage,
	adminMenu,
	financialMenu,
} from '../menu';
import RoleWrapper from '../pages/roles';
import UsersWrapper from '../pages/users';
import UserEdit from '../pages/users/user-edit/UserEdit';
import UserProfile from '../pages/users/user-profile/UserProfile';
import UsersList from '../pages/users/users-list/UsersList';
import RoleEditPermissions from '../pages/roles/role-edit/RoleEditPermissions';
import PagePermissions from '../pages/auth/PagePermissions';
import UserListWrapper from '../pages/users/UserListIndex';
import RoleListWrapper from '../pages/roles/RoleListIndex';
import CompanyWrapper from '../pages/companies';
import CompanyProfile from '../pages/companies/company-profile/OrganizationProfile';
import CompanyCreate from '../pages/companies/company-create';
import OrganizationListWrapper from '../pages/companies/CompaniesListIndex';
import CompanyEdit from '../pages/companies/company-edit/OrganizationEdit';
import UserCreate from '../pages/users/user-create/UserCreate';
import IngredientsWrapper from '../pages/ingredients';
import IngredientListWrapper from '../pages/ingredients/IngredientListIndex';
import IngredientCreate from '../pages/ingredients/ingredient-create/IngredientCreate';
import IngredientEdit from '../pages/ingredients/ingredient-edit/IngredientEdit';
import SuppliersWrapper from '../pages/suppliers';
import SuppliersListWrapper from '../pages/suppliers/SuppliersListWrapper';
import CreateSupplier from '../pages/suppliers/supplier-create/CreateSupplier';
import EditSupplier from '../pages/suppliers/supplier-edit/EditSupplier';
import IngredientView from '../pages/ingredients/ingredient-view/IngredientView';
import SupplierView from '../pages/suppliers/supplier-view/SupplierView';
import ElaborationsWrapper from '../pages/elaborations';
import ElaborationsListWrapper from '../pages/elaborations/ElaborationsListWrapper';
import CreateElaboration from '../pages/elaborations/elaboration-create/ElaborationCreate';
import EditElaboration from '../pages/elaborations/elaboration-edit/ElaborationEdit';
import { IngredientCategoriesListWrapper } from '../pages/ingredient-categories/IngredientCategoriesListWrapper';
import DishCategoriesWrapper from '../pages/dish-categories';
import DishCategoriesListWrapper from '../pages/dish-categories/DishCategoriesListWrapper';
import DishCategoryCreate from '../pages/dish-categories/category-create/DishCategoryCreate';
import DishCategoryEdit from '../pages/dish-categories/category-edit/DishCategoryEdit';
import IngredientCategoriesWrapper from '../pages/ingredient-categories';
import IngredientCategoryCreate from '../pages/ingredient-categories/category-create/IngredientCategoryCreate';
import IngredientCategoryEdit from '../pages/ingredient-categories/category-edit/IngredientCategoryEdit';
import DishesWrapper from '../pages/dishes';
import DishesListWrapper from '../pages/dishes/DishesListWrapper';
import CreateDish from '../pages/dishes/dish-create/DishCreate';
import EditDish from '../pages/dishes/dish-edit/DishEdit';
import DeliveryNotesWrapper from '../pages/delivery-notes';
import { DeliveriesListWrapper } from '../pages/delivery-notes/DeliveriesListWrapper';
import CreateDeliveryNote from '../pages/delivery-notes/delivery-create/CreateDeliveryNote';
import EditDeliveryNote from '../pages/delivery-notes/delivery-edit/EditDeliveryNote';
import DeliveryNoteView from '../pages/delivery-notes/delivery-view/DeliveryNoteView';
import ElaborationView from '../pages/elaborations/elaboration-view/ElaborationView';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import SalesWrapper from '../pages/sales';
import { SalesListWrapper } from '../pages/sales/SalesListWrapper';
import EditSale from '../pages/sales/sale-edit/EditSale';
import SaleView from '../pages/sales/sale-view/SaleView';
import DishView from '../pages/dishes/dish-view/DishView';

const protectedRoutes = [
	{
		path: superAdminMenu.organizations.path,
		element: <CompanyWrapper />,
		sub: [
			{
				element: <OrganizationListWrapper />,
				access: {
					group: 'companies',
					action: 'list'
				},
				index: true
			},
			{
				path: 'create',
				access: {
					group: 'companies',
					action: 'create'
				},
				element: <CompanyCreate />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'companies',
					action: 'edit'
				},
				element: <CompanyEdit />,
			},
			{
				path: ':id/profile',
				access: {
					group: 'companies',
					action: 'get'
				},
				element: <CompanyProfile />,
			}
		]
	},


	/* ******************** USERS ********************* */
	{
		path: usersMenu.users.path,
		element: <UsersWrapper />,
		sub: [
			{
				element: <UserListWrapper />,
				access: {
					group: 'user',
					action: 'list'
				},
				index: true,
			},
			{
				path: ':id/profile',
				access: {
					group: 'user',
					action: 'get'
				},
				element: <UserProfile />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'user',
					action: 'edit'
				},
				element: <UserEdit />,
			},
			{
				path: 'create',
				access: {
					group: 'user',
					action: 'create'
				},
				element: <UserCreate />,
			}
		]
	},


	/* ******************** INGREDIENTS ********************* */
	{
		path: adminMenu.ingredients.path,
		element: <IngredientsWrapper />,
		sub: [
			{
				element: <IngredientListWrapper />,
				access: {
					group: 'ingredients',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'ingredients',
					action: 'create'
				},
				element: <IngredientCreate />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'ingredients',
					action: 'edit'
				},
				element: <IngredientEdit />,
			},
			{
				path: ':id/view',
				access: {
					group: 'ingredients',
					action: 'get'
				},
				element: <IngredientView />,
			},
		]
	},


	/* ******************** INGREDIENT CATEGORIES ********************* */
	{
		path: adminMenu.ingredientCategories.path,
		element: <IngredientCategoriesWrapper />,
		sub: [
			{
				element: <IngredientCategoriesListWrapper />,
				access: {
					group: 'ingredients',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'ingredients',
					action: 'create'
				},
				element: <IngredientCategoryCreate />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'ingredients',
					action: 'edit'
				},
				element: <IngredientCategoryEdit />,
			},
		]
	},


	/* ******************** SUPPLIERS ********************* */
	{
		path: adminMenu.suppliers.path,
		element: <SuppliersWrapper />,
		sub: [
			{
				element: <SuppliersListWrapper />,
				access: {
					group: 'suppliers',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'suppliers',
					action: 'create'
				},
				element: <CreateSupplier />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'suppliers',
					action: 'edit'
				},
				element: <EditSupplier />,
			},
			{
				path: ':id/view',
				access: {
					group: 'suppliers',
					action: 'get'
				},
				element: <SupplierView />,
			},
		]
	},


	/* ******************** ELABORATIONS ********************* */
	{
		path: adminMenu.elaborations.path,
		element: <ElaborationsWrapper />,
		sub: [
			{
				element: <ElaborationsListWrapper />,
				access: {
					group: 'elaborations',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'elaborations',
					action: 'create'
				},
				element: <CreateElaboration />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'elaborations',
					action: 'edit'
				},
				element: <EditElaboration />,
			},
			{
				path: ':id/view',
				access: {
					group: 'elaborations',
					action: 'get'
				},
				element: <ElaborationView />,
			},
		]
	},


	/* ******************** DISH CATEGORIES ********************* */
	{
		path: adminMenu.dishCategories.path,
		element: <DishCategoriesWrapper />,
		sub: [
			{
				element: <DishCategoriesListWrapper />,
				access: {
					group: 'dishes',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'dishes',
					action: 'create'
				},
				element: <DishCategoryCreate />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'dishes',
					action: 'edit'
				},
				element: <DishCategoryEdit />,
			},
		]
	},


	/* ******************** DISHES ********************* */
	{
		path: adminMenu.dishes.path,
		element: <DishesWrapper />,
		sub: [
			{
				element: <DishesListWrapper />,
				access: {
					group: 'dishes',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'dishes',
					action: 'create'
				},
				element: <CreateDish />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'dishes',
					action: 'edit'
				},
				element: <EditDish />,
			},
			{
				path: ':id/view',
				access: {
					group: 'dishes',
					action: 'get'
				},
				element: <DishView />,
			},
		]
	},


	/* ***************** DELIVERY NOTES ****************** */
	{
		path: financialMenu.deliveryNotes.path,
		element: <DeliveryNotesWrapper />,
		sub: [
			{
				element: <DeliveriesListWrapper />,
				access: {
					group: 'delivery_notes',
					action: 'list'
				},
				index: true,
			},
			{
				path: 'create',
				access: {
					group: 'delivery_notes',
					action: 'create'
				},
				element: <CreateDeliveryNote />,
			},
			{
				path: ':id/edit',
				access: {
					group: 'delivery_notes',
					action: 'edit'
				},
				element: <EditDeliveryNote />,
			},
			{
				path: ':id/view',
				access: {
					group: 'delivery_notes',
					action: 'get'
				},
				element: <DeliveryNoteView />,
			},
		]
	},

	/* ******************** SALES ********************* */
	{
		path: financialMenu.sales.path,
		element: <SalesWrapper />,
		sub: [
			{
				element: <SalesListWrapper />,
				access: {
					group: 'sales',
					action: 'list'
				},
				index: true,
			},
			{
				path: ':id/edit',
				access: {
					group: 'sales',
					action: 'edit'
				},
				element: <EditSale />,
			},
			{
				path: ':id/view',
				access: {
					group: 'sales',
					action: 'get'
				},
				element: <SaleView />,
			},
		]
	},


	/* ******************** ROLES ********************* */
	{
		path: superAdminMenu.roles.path,
		element: <RoleWrapper />,
		isProtected: true,
		sub: [
			{
				element: <RoleListWrapper />,
				access: {
					group: 'roles',
					action: 'list'
				},
				index: true
			},
			{
				path: ':id/edit',
				access: {
					group: 'roles',
					action: 'edit'
				},
				element: <RoleEditPermissions />,
			}
		]
	},


	/* ******************** DASHBOARD ******************** */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <DashboardWrapper />,
	},


	/** ************************************************** */
	{
		path: permissionsPage.permissions.path,
		element: <PagePermissions />,
	},

];
const contents = [...protectedRoutes];

export default contents;