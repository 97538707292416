import { Fragment, useCallback, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { useNavigate } from "react-router-dom";
import { CompanyService } from "../../../services/companies/companyService";
import { CompaniesApiResponse } from "../../../type/company-type";
import useFetch from "../../../hooks/useFetch";
import StatusDropdown from "../../../components/StatusDropdown";
import CompanyFilters from "./companies-options/CompanyFilters";
import AsyncImg from "../../../components/AsyncImg";
import { CustomTable } from "../../../components/table/CustomTable";
import moment from "moment";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import { useFiltersPR } from "../../../components/providers/FiltersProvider";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import ErrorMessage from "../../../components/ErrorMessage";

const CompanyList = () => {

	const navigate = useNavigate();

	const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

	const [changingStatus, setChangingStatus] = useState<string[]>([]);

	const [data, loading, error] = useFetch(useCallback(async () => {
		const companyService = new CompanyService();
		const response = await companyService.listCompanies(filters);
		return response.getResponseData() as CompaniesApiResponse;
	}, [filters]));

	const toggleCompanyStatus = async (id: string, status: boolean, toggleStatus: Function) => {
		try {
			setChangingStatus([...changingStatus, id]);
			let response = await (await new CompanyService().toggleCompantStatus(id, status)).getResponseData();
			if (response.success) {
				setChangingStatus(changingStatus.filter((item) => item !== id));
				toggleStatus(status);
			} else {
				setChangingStatus(changingStatus.filter((item) => item !== id));
			}
		} catch (error: any) {
			setChangingStatus(changingStatus.filter((item) => item !== id));
		}
	};

	const deleteCompany = async (id: string) => {
		let response = (await new CompanyService().deleteCompany(id)).getResponseData();
		if (response.success) {
			toast.success('Usuario eliminado correctamente');
			updateFilters({ ...filters });
		}
	};

	return (
		<Fragment>
			<SubHeader>
				<SubHeaderLeft>
					<Fragment>
						<CardTitle>Listado de Organizaciones</CardTitle>
						<SubheaderSeparator />
						<Button color="light" icon="Add" isLight onClick={() => { navigate("create") }}>
							Añadir Organización
						</Button>
					</Fragment>
				</SubHeaderLeft>
				<SubHeaderRight>
					<CompanyFilters updateFilters={updateFilters} resetFilters={resetFilters} filters={filters} />
				</SubHeaderRight>
			</SubHeader>

			<Page container="fluid">
				<Card stretch={false}>
					{error && <ErrorMessage />}

					{data
						? (
							<CustomTable
								data={data?.companies ? data.companies : null}
								pagination={true}
								defaultLimit={filters.limit || 50}
								defaultOrder={filters.filter_order || undefined}
								paginationData={{
									pageSize: filters.limit,
									currentPage: filters.page,
									pageCount: data as CompaniesApiResponse ? data.lastPage : 1,
									totalCount: data.totalRegisters,
									handlePagination: updatePage,
									handlePerPage: updatePageSize,
								}}
								className={"table table-hover"}
								columns={[
									{
										name: "",
										keyValue: "name",
										render: (element: any) => {
											return (
												<div className="d-flex justify-content-center">
													<div className='user-avatar'>
														<AsyncImg id={element.logo?.id ? element.logo?.id : null} />
													</div>
												</div>
											);
										},
									},
									{
										name: "Nombre",
										keyValue: "name",
										sortable: true,
										sortColumn: updateFilterOrder,
										className: "text-center",
										render: (element: any) => {
											return (
												<div className="text-center" /* className="d-flex justify-content-center cursor-pointer text-primary fw-bold" onClick={() => { navigate(`${element.id}/profile`) }} */>
													{element.name}
												</div>
											)
										},
									},
									{
										name: "CIF",
										keyValue: "cif",
										sortable: true,
										sortColumn: updateFilterOrder,
										className: "text-center",
										cellClassName: "text-center",
									},
									{
										name: "Dirección",
										keyValue: "address",
										sortable: true,
										sortColumn: updateFilterOrder,
										className: "text-center",
										cellClassName: "text-center",
									},
									{
										name: "Fecha de creación",
										keyValue: "createdAt",
										sortable: true,
										sortColumn: updateFilterOrder,
										className: "text-center",
										render: (element: any) => {
											return (
												<div className={'text-center'}>
													<span className={'text-muted'}>{moment(element.createdAt.date).format('DD-MM-YYYY HH:mm')}</span>
												</div>
											)
										},
									},
									{
										name: "Última Modificación",
										keyValue: "updatedAt",
										sortable: true,
										sortColumn: updateFilterOrder,
										className: "text-center",
										render: (item: any) => {
											return <div className="text-center">{item.updatedAt ? moment(item.updatedAt.date).format('DD/MM/YYYY') : 'Sin actualizar'}</div>;
										}
									},
									{
										name: "Estado",
										keyValue: "active",
										sortable: true,
										sortColumn: updateFilterOrder,
										className: "text-center",
										render: (element: any) => {
											return (
												<div className={'text-center'}>
													<StatusDropdown
														disabled={changingStatus.includes(element.id)}
														itemId={element.id}
														status={element.active}
														change={toggleCompanyStatus}
													/>
												</div>
											);
										},
									},
									{ name: "Acciones", className: "min-w-100px text-end", isActionCell: true }
								]}
								actions={[
									{
										title: "Editar",
										buttonType: 'icon',
										iconColor: 'text-info',
										iconPath: '/media/icons/duotune/general/gen055.svg',
										additionalClasses: 'text-primary',
										description: "Editar perfil del organización",
										callback: (item: any) => {
											navigate(`${item.id}/edit`);
										},
									},
									{
										title: "Eliminar",
										buttonType: 'icon',
										iconColor: 'text-danger',
										iconPath: '/media/icons/duotune/general/gen027.svg',
										additionalClasses: 'text-danger',
										description: "Eliminar organización",
										callback: (item: any) => {
											handleConfirmationAlert({
												title: "Eliminar organización",
												text: "¿Estás seguro de que deseas eliminar la organización?",
												icon: "warning",
												onConfirm: () => {
													deleteCompany(item.id);
												}
											})
										},
									},
								]}
							/>
						)
						: !error && <Loader />
					}
				</Card>
			</Page>
		</Fragment>
	)
}

export default CompanyList;