export const MEASUREMENT_UNITS = [
    { value: 'kg', label: 'Kilogramos (kg)' },
    { value: 'g', label: 'Gramos (g)' },
    { value: 'mg', label: 'Miligramos (mg)' },
    { value: 'L', label: 'Litros (L)' },
    { value: 'mL', label: 'Mililitros (mL)' },
    { value: 'u', label: 'Unidades (u)' },
];

export const MEASUREMENT_UNITS_V2 =
{
    'volume': [
        { value: 'L', label: 'Litros (L)' },
        { value: 'mL', label: 'Mililitros (mL)' },
    ],
    'mass': [
        { value: 'kg', label: 'Kilogramos (kg)' },
        { value: 'g', label: 'Gramos (g)' },
        { value: 'mg', label: 'Miligramos (mg)' },
    ],
    'unit': [
        { value: 'u', label: 'Unidades (u)' },
    ],
};