import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { SalesApiResponse } from '../../../type/sales-type';
import { SalesService } from '../../../services/sales/salesService';
import { toast } from 'react-toastify';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import Page from '../../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Card, { CardTitle } from '../../../components/bootstrap/Card';
import ErrorMessage from "../../../components/ErrorMessage";
import SalesFilters from './sales-options/SalesFilters';
import moment from 'moment';
import { Loader } from '../../../components/bootstrap/SpinnerLogo';
import { FixNumber } from '../../../utils/fixNumber';
import Button from '../../../components/bootstrap/Button';
import PriceDropdown from '../../../components/PriceDropdown';
import CreateSaleModal from '../components/CreateSaleModal';
import SaleLinesTable from '../components/SaleLinesTable';
import SalesTable from '../components/SalesTable';

interface SalesListProps { }

const SalesList: React.FC<SalesListProps> = ({ }) => {

    const service = new SalesService();

    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();

    const [count, setCount] = useState(0);
    const [showAddBtn, setShowAddBtn] = useState(false);
    const [timeLeft, setTimeLeft] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const [listLines, setListLines] = useState<boolean>(true);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const apiCall = listLines ? service.getSalesByLines(filters) : service.getSales(filters);
        const response = await apiCall;
        return response.getResponseData() as SalesApiResponse;
    }, [filters, listLines]));

    /**
     * Export the sales list to an Excel file.
     * 
     * @returns {Promise<void>}
    */
    const handleExport = async () => {
        if (
            filters.filter_filters?.date?.from === undefined
            || filters.filter_filters?.date?.to === undefined
            || filters.filter_filters?.date?.from === ''
            || filters.filter_filters?.date?.to === ''
        ) {
            toast.info('Debes seleccionar un rango de fechas para exportar');
            return;
        };

        try {
            const response = await service.exportSales({ ...filters, limit: 9999999 });
            if (response.getResponseData()) {
                const fileData = response.getResponseData();
                const blob = new Blob([fileData]);
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ventas-${moment().subtract(10, 'days').calendar()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error('Error al exportar el listado de ventas');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    /**
     * Delete a sale with the given ID.
     *
     * @param {string} id - The ID of the sale to be deleted.
     */
    const deleteSale = async (id: string) => {
        let response = (await service.deleteSale(id)).getResponseData();
        if (response.success) {
            toast.success("Venta eliminada correctamente");
            refetch();
        }
    };

    /**
     * Handle the click event of the button. It increments the count and resets the timer.
     * 
     * @returns {void}
     */
    const handleClick = () => {
        setCount(count + 1);
        setTimeLeft(1); // Reset the timer

        if (count === 4) {
            setShowAddBtn(true);
            setCount(0);
        }
    };

    // Timer to hide the add button after 2 seconds
    useEffect(() => {
        const timer = setInterval(() => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
            } else {
                setCount(0);
                setShowAddBtn(false);
            }
        }, 2000);
        return () => clearInterval(timer);
    }, [timeLeft, count]);

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <CardTitle onClick={handleClick}>Listado de Ventas</CardTitle>
                    <SubheaderSeparator />
                    <CardTitle><span className="text-muted fs-5">Total ventas: </span>{FixNumber(data?.totalSales) || 0} €</CardTitle>
                    <SubheaderSeparator />
                    <PriceDropdown filters={filters} updateFilters={updateFilters} />
                    <CardTitle><span className="text-muted fs-5">Total costes: </span>{FixNumber(data?.totalCosts) || 0} €</CardTitle>
                    <SubheaderSeparator />
                    <Button color="light" isLight title="Exportar" icon="Download" onClick={handleExport}>
                        Exportar
                    </Button>
                    <SubheaderSeparator />
                    <Button color="light" isLight icon="List" onClick={() => setListLines(!listLines)}>
                        {listLines ? 'Mostrar ventas' : 'Mostrar por líneas'}
                    </Button>
                    {showAddBtn && (
                        <>
                            <SubheaderSeparator />
                            <Button color="light" isLight title="Exportar" icon="Add" onClick={() => setIsOpen(true)}>
                                Añadir venta
                            </Button>
                        </>
                    )}
                </SubHeaderLeft>
                <SubHeaderRight>
                    <SalesFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </SubHeaderRight>
            </SubHeader>

            <Page container="fluid">
                <Card stretch={false}>
                    {error && <ErrorMessage />}

                    {(data && (data?.sales || data?.salesHasDishes))
                        ? data.sales
                            ? <SalesTable data={data} filters={filters} updatePage={updatePage} updatePageSize={updatePageSize} updateFilterOrder={updateFilterOrder} deleteSale={deleteSale} />
                            : data.salesHasDishes && <SaleLinesTable data={data} filters={filters} updatePage={updatePage} updatePageSize={updatePageSize} updateFilterOrder={updateFilterOrder} />
                        : !error && <Loader />
                    }
                </Card>

                {isOpen && <CreateSaleModal isOpen={isOpen} setIsOpen={setIsOpen} refetch={refetch} />}
            </Page>
        </Fragment>
    );
};

export default SalesList;