import { useCallback, useEffect, useState } from 'react';
import { IngredientService } from '../services/ingredients/ingredientService';
import { userIsSuperAdmin } from '../utils/userIsSuperAdmin';
import useFilters from './useFilters';
import useHandleErrors from './useHandleErrors';

//------------------------------------------------------------------------
/**
* 
* EN: Hook to manage ingredients categories fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de categorias de ingredientes.
*
*/
//----------------------------------------------------------------------
const useIngredientsCategories = () => {

  const { handleErrors } = useHandleErrors();
  const { filters } = useFilters({}, [], 1, 1000);

  const [ingredientsCategories, setIngredientsCategories] = useState<any>([]);

  const fetchIngredientsCategories = useCallback(async (companyId?: string) => {
    try {
      if (userIsSuperAdmin() && !companyId) return [];
      const response = await (new IngredientService()).getIngredientsCategories(filters);
      const fetchedData = response.getResponseData() as any;

      if (fetchedData && fetchedData.data.ingredientCategories) {
        const mappedData = fetchedData.data.ingredientCategories.map((entity: { id: string; name: string; company: { id: string; }; }) => ({
          value: entity.id,
          label: entity.name,
          key: entity.id,
          companyid: entity.company.id
        }));
        setIngredientsCategories(mappedData);
      } else {
        handleErrors(response);
      }

    } catch (error) {
      console.log('Error fetching roles:', error);
    }
  }, [filters]);

  useEffect(() => {
    fetchIngredientsCategories();
  }, [])

  const getIngredientsCategoriesList = () => {
    return ingredientsCategories;
  }

  return { ingredientsCategories, fetchIngredientsCategories, getIngredientsCategoriesList };
}

export default useIngredientsCategories;