import Button from "../../components/bootstrap/Button";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Select from "../../components/bootstrap/forms/Select";
import Spinner from "../../components/bootstrap/Spinner";
import AsyncImg from "../../components/AsyncImg";
import { useFormik } from "formik";
import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { CardBody, CardFooter, CardTitle } from "../../components/bootstrap/Card";
import { UserService } from "../../services/users/userService";
import { toast } from "react-toastify";
import { Schema } from "yup";
import { userIsSuperAdmin } from "../../utils/userIsSuperAdmin";
import useCompanies from "../../hooks/useCompanies";
import useFetch from "../../hooks/useFetch";
import useFilters from "../../hooks/useFilters";
import * as yup from "yup";
import 'react-phone-input-2/lib/style.css'

interface CreateFormProps {
    isLoading: boolean;
    submit: Function;
    userData?: any;
}

export interface IUserForm {
    id?: string;
    name?: string;
    lastName?: string;
    email?: string;
    roleId?: string;
    password?: string | null;
    re_password?: string | null;
    company?: string;
    office?: string;
    active?: boolean;
}

const UserEditSchema = yup.object({
    name: yup.string().min(1, 'Demasido Corto').max(100, 'Demasiado Largo').required('Campo Obligatorio'),
    lastName: yup.string().min(1, 'Demasido Corto').max(100, 'Demasiado Largo').required('Campo Obligatorio'),
    email: yup.string().email('Correo Invalido').required('Campo Obligatorio'),
    roleId: yup.string().required('Debes elegir un rol de la organización para poder editar un usuario'),
    password: yup.string().min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número').nullable(),
    re_password: yup.string().when('password', {
        is: (val: string | null | undefined) => val !== null && val !== undefined && val.length > 0,
        then: (schema: Schema) => schema.required('Confirmacion de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
        otherwise: (schema: Schema) => schema.nullable(),
    }).nullable(),
});

const UserCreateSchema = UserEditSchema.concat(
    yup.object({
        roleId: yup.string().required('Debes elegir un rol de la organización para poder crear un usuario'),
        password: yup.string().required('Contraseña Obligatoria').min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número'),
        re_password: yup.string().required('Confirmacion de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
    })
);

const UserForm: FC<CreateFormProps> = ({ isLoading, submit, userData }) => {

    const { getCompanyList } = useCompanies();
    const { filters, updateFilters } = useFilters();
    const mode = userData ? 'Editar' : 'Crear';

    const [selectedImage, setSelectedImage] = useState<string | null>(userData?.profileImg?.id || null);
    const [uploadImage, setuploadImage] = useState<any>(null);
    const profileImgInput = useRef<HTMLInputElement>(null);

    const userInitialValues: any = {
        name: userData?.name,
        lastName: userData?.lastName,
        email: userData?.email,
        active: userData?.active || true,
        company: userData?.company,
        roleId: userData?.userRoles[0]?.role?.id,
        password: null,
        re_password: null,
    };

    const formik = useFormik({
        initialValues: userInitialValues,
        validationSchema: (mode === 'Editar') ? UserEditSchema : UserCreateSchema,
        onSubmit: values => {
            values = {
                ...userData,
                ...values
            }
            delete values.userPermissions;
            delete values.userRoles;
            submit(values)
        },
    });

    const [roleList] = useFetch(useCallback(async () => {
        const userService = new UserService();
        const response = await userService.getUserRoles(filters);
        return response.getResponseData();
    }, [filters]));

    const getRolesList = () => {
        if (roleList) {
            return roleList.roles.map((role: any) => {
                return { value: role.id, label: role.name }
            })
        }
        return [];
    };

    const handleImageUpload = (event: React.ChangeEvent<any>) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setuploadImage(reader.result);
        };
        reader.readAsDataURL(file);
        formik.setFieldValue('logo', event.currentTarget.files[0])
    };

    const clearImage = async () => {
        if (userData?.id && userData?.profileImg !== undefined) {
            const response = (await (new UserService()).deleteProfileImage(userData?.id)).getResponseData()
            if (!response.success) {
                toast.error(response.message);
                return;
            }
            toast.success('Imagen eliminada');
        }
        setSelectedImage(null);
        setuploadImage(null);
        userData.profileImage = null;
        if (profileImgInput.current) profileImgInput.current.value = '';
        formik.setFieldValue('profileImg', null);
        formik.setFieldValue('logo', null);
    };

    const verifyClass = (inputFieldID: keyof IUserForm) => { return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? 'is-invalid' : '' };

    const showErrors = (inputFieldID: keyof IUserForm) => {
        // @ts-ignore
        return (formik.touched[inputFieldID] && formik.errors[inputFieldID]) ? <div className="invalid-feedback">{formik.errors[inputFieldID]}</div> : <></>;
    };

    useEffect(() => {
        updateFilters({ companyId: userData?.company });
    }, [userData]);

    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <CardBody isScrollable={false}>
                    {mode === "Editar" && (
                        <div className="row nowrap">
                            <div className='col-12'>
                                <CardBody>
                                    <div className="row align-items-center">
                                        <div className='col-auto'>
                                            {uploadImage
                                                ? <img width={100} height={100} src={uploadImage} className="rounded" style={{ objectFit: 'cover' }} alt="uploaded image" />
                                                : <AsyncImg width="100px" height="100px" styles="rounded" defaultAvatarSize={100} id={selectedImage} />
                                            }
                                        </div>
                                        <div className='col-lg-9'>
                                            <div className='row g-4'>
                                                <div className='col-8'>
                                                    <Input ref={profileImgInput} type='file' onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)}
                                                        autoComplete='photo' />
                                                </div>
                                                <div className='col-4'>
                                                    <Button color='dark' isLight icon='Delete' onClick={clearImage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </div>
                        </div>
                    )}

                    <CardTitle>Información general</CardTitle>
                    <div className="row g-3 mt-3">
                        <FormGroup requiredInputLabel label='Nombre' className='col-md-6'>
                            <Input id='name' required onChange={formik.handleChange} value={formik.values.name || ''}
                                onBlur={formik.handleBlur} className={verifyClass('name')} />
                            {showErrors('name')}
                        </FormGroup>

                        <FormGroup requiredInputLabel label='Apellidos' className='col-md-6'>
                            <Input id='lastName' required onChange={formik.handleChange} value={formik.values.lastName || ''}
                                onBlur={formik.handleBlur} className={verifyClass('lastName')} />
                            {showErrors('lastName')}
                        </FormGroup>

                        <FormGroup requiredInputLabel label='Email' className='col-md-6'>
                            <Input id='email' required type='email' onChange={formik.handleChange}
                                value={formik.values.email || ''} onBlur={formik.handleBlur}
                                className={verifyClass('email')} />
                            {showErrors('email')}
                        </FormGroup>

                        {userIsSuperAdmin() && (
                            <FormGroup label='Organización' className='col-md-6'>
                                <Select id='company' onChange={formik.handleChange} className={verifyClass('company')}
                                    value={formik.values.company || ''} ariaLabel='Default select example'
                                    placeholder='Elegir organización...'
                                    list={getCompanyList()}
                                />
                                {showErrors('company')}
                            </FormGroup>
                        )}

                        <FormGroup id='roleId' requiredInputLabel label='Rol' className='col-md-6'>
                            <Select id='roleId' className={verifyClass('roleId')} ariaLabel='Default select example'
                                placeholder='Elegir rol...'
                                onChange={formik.handleChange} value={formik.values.roleId ? formik.values.roleId.toString() : ''}
                                list={getRolesList()}
                            />
                            {showErrors('roleId')}
                        </FormGroup>
                    </div>

                    <div className="row g-3 mt-2">
                        <FormGroup label='Contraseña' className='col-md-6' requiredInputLabel={mode === 'Crear' ? true : false} >
                            <Input id='password' type={'password'} autoComplete="new-password" onChange={formik.handleChange}
                                value={formik.values.password || ''} onBlur={formik.handleBlur}
                                className={verifyClass('password')} />
                            {showErrors('password')}
                        </FormGroup>

                        <FormGroup label='Confirmar Contraseña' className='col-md-6' requiredInputLabel={mode === 'Crear' ? true : false} >
                            <Input id='re_password' type={'password'} autoComplete="new-password" onChange={formik.handleChange}
                                value={formik.values.re_password || ''} onBlur={formik.handleBlur}
                                className={verifyClass('re_password')} />
                            {showErrors('re_password')}
                        </FormGroup>
                    </div>
                </CardBody>

                <CardFooter className="d-flex justify-content-center">
                    <Button type="submit" size='lg' color='primary'>
                        {isLoading ? <Spinner isSmall /> : `${mode} Usuario`}
                    </Button>
                </CardFooter>
            </form>
        </Fragment>
    )
}

export default UserForm;