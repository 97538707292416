import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';
import { FilterOptions } from '../../hooks/useFilters';
import { EditSaleFieldsModel } from '../../type/sales-type';

const SALES_ENDPOINT = '/sales';

export class SalesService extends RestServiceConnection {

    /**
     * ------------------------------------------------------------------------
     * EN: SALES REQUEST SERVICE
     * ES: SERVICIO DE VENTAS
     * ------------------------------------------------------------------------
     */

    getSales = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SALES_ENDPOINT + '/list',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getSalesByLines = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SALES_ENDPOINT + '/list-lines',
            data: { ...filters },
        }, true) as AxiosResponse;
        return this;
    }

    getSaleById = async (saleId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SALES_ENDPOINT + '/get',
            data: { saleId }
        }, true);
        return this;
    }

    createSale = async (values: EditSaleFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SALES_ENDPOINT + '/create',
            data: values,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    editSale = async (values: EditSaleFieldsModel) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SALES_ENDPOINT + '/edit',
            data: values,
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteSale = async (saleId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SALES_ENDPOINT + '/delete',
            data: { saleId }
        }, true);
        return this;
    }

    exportSales = async (filters?: FilterOptions) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: SALES_ENDPOINT + '/export',
            responseType: 'arraybuffer', // define el tipo de respuesta que se espera
            data: { filters }
        }, true);
        return this;
    }

}