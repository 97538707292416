import React, { useState } from "react";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import Spinner from "../../../components/bootstrap/Spinner";
import { useFormik } from 'formik';
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import {toast} from "react-toastify";
import { RemoveRedEye } from "../../../components/icon/material-icons";
import PasswordHide from "../../../components/icon/material-icons/PasswordHide";

interface LoginResetPasswordFormProps {
    isLoading: boolean;
    submit: Function;
    errorsBool: boolean;
}

const ResetPasswordSchema = yup.object({
    password: yup.string().required('Contraseña Obligatoria').min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número'
      ),
    password_confirmation: yup.string().required('Confirmacion de contraseña obligatoria').oneOf([yup.ref('password'), ''], 'Contraseñas no coinciden'),
});

export const LoginResetPasswordForm: React.FC<LoginResetPasswordFormProps> = ({isLoading = false, submit, errorsBool}) => {
    
    const userToken = new URLSearchParams(useLocation().search).get('token');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

    const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			password: '',
            password_confirmation: ''
		},
        validationSchema: ResetPasswordSchema,
		validateOnChange: false,
		onSubmit: (values) => {
            if(userToken && userToken.length > 10) {
                submit(userToken, values.password, values.password_confirmation );
            } else {
                toast.error('Token invalido o nulo.');
            }
		},
	});

    return(
        <form onSubmit={formik.handleSubmit} className='row g-4'>
        <div className='col-12'>
        <div className="d-flex flex-row flex-nowrap gap-2">
            <FormGroup id='password' isFloating label='Contraseña' className="w-100">
                <Input
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                isTouched={formik.touched.password}
                invalidFeedback={formik.errors.password}
                validFeedback='¡Correcto!'
                isValid={formik.isValid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />
            </FormGroup>
            <Button isOutline color='primary' onClick={() => setShowPassword(!showPassword)} className="w-auto d-flex justify-content-center align-items-center p-3">
                {!showPassword? <RemoveRedEye fontSize={'1.5em'}></RemoveRedEye> : <PasswordHide fontSize={'1.5em'}></PasswordHide>}
            </Button>
        </div>

        <div className="d-flex flex-row flex-nowrap mt-3 gap-2">
            <FormGroup
                id='password_confirmation'
                isFloating
                label='Confirmar contraseña'
                className='w-100'
            >
                <Input
                type={showPasswordConfirmation ? 'text' : 'password'}
                value={formik.values.password_confirmation}
                isTouched={formik.touched.password_confirmation}
                invalidFeedback={formik.errors.password_confirmation}
                validFeedback='¡Correcto!'
                isValid={formik.isValid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                />

            </FormGroup>
            <Button isOutline color='primary' onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}>
                {!showPasswordConfirmation? <RemoveRedEye fontSize={'1.5em'}></RemoveRedEye> : <PasswordHide fontSize={'1.5em'}></PasswordHide>}
            </Button>
        </div>
    
        </div>
        <div className='col-12'>
            <Button color='warning' className='w-100 py-3' type='submit'  isDisable={isLoading}>
                {isLoading ? <Spinner/> : 'Resetar contraseña'}
            </Button>
        </div>
    </form>
    ) 
}