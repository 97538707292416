import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SupplierForm from "../SupplierForm";
import { SupplierService } from "../../../services/suppliers/suppliersService";

const CreateSupplier = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);

    const handleCreation = async (values: any) => {
        setLoading(true);
        try {
            const response = await (await (new SupplierService()).createSupplier(values)).getResponseData();
            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success('Proveedor creado correctamente');
                }, 100);
            } else if (response.data.errors) {
                response.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            } else {
                toast.error(response.message);
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    return <SupplierForm submit={handleCreation} isLoading={loading} />;
}

export default CreateSupplier;