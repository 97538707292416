import { FC, Fragment, useCallback, useContext, useState } from 'react'
import Icon from '../../../../components/icon/Icon'
import CustomSearchInput from "../../../../components/bootstrap/forms/CustomSearchInput";
import { FilterOptions } from '../../../../hooks/useFilters';
import useCompanies from '../../../../hooks/useCompanies'
import useFetch from '../../../../hooks/useFetch'
import { UserService } from '../../../../services/users/userService'
import { UserApiResponse } from '../../../../type/user-type'
import { PrivilegeContext } from '../../../../components/priviledge/PriviledgeProvider'

interface ICategoryFiltersProps {
  updateFilters: (filters: any) => void
  resetFilters: (limit: any) => void
  filters: FilterOptions
}

const CategoryFilters: FC<ICategoryFiltersProps> = ({ updateFilters, filters, resetFilters }) => {

  const { userCan } = useContext(PrivilegeContext);
  const { getCompanyList } = useCompanies();

  const [filterMenu, setFilterMenu] = useState(false);

  const [users] = useFetch(useCallback(async () => {
    const response = await (new UserService).getUsers();
    return response.getResponseData() as UserApiResponse;
  }, []));

  const getUserList = () => {
    if (users?.users) {
      return users.users.map((user: any) => {
        return {
          value: user.id,
          label: user.name + (user.firstName ? (" " + user.firstName) : '') + (user.lastName ? (" " + user.lastName) : ''),
        }
      })
    }
    return [];
  };

  const handleTextChange = (search: string) => {
    updateFilters({ search_array: search });
  };

  return (
    <Fragment>
      <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
        <Icon icon='Search' size='2x' color='primary' />
      </label>
      <CustomSearchInput placeholder={'Buscar'} onSearch={handleTextChange} defaultValue={filters.filter_filters?.search_text || ''}></CustomSearchInput>

      {/* <Button color='primary' isLight icon='Filter' onClick={() => { setFilterMenu(true) }}>
        Filtros
      </Button>

      <OffCanvas setOpen={setFilterMenu} isOpen={filterMenu} titleId='userFilters'>
        <OffCanvasHeader>
          <OffCanvasTitle id='userFilters'>Filtros de Categorías</OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          {userIsSuperAdmin() && (
            <FormGroup label='Compañía:' className='col-md-12'>
              <Select
                id='company'
                list={getCompanyList()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ company: e.target.value })}
                value={filters.filter_filters?.company || ''}
                ariaLabel='Default select example' placeholder='Elegir Compañia...'
              />
            </FormGroup>
          )}

          {userCan('list', 'user') && (
            <FormGroup label='Usuario:' className='col-md-12'>
              <Select
                id='user'
                list={getUserList()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilters({ user: e.target.value })}
                value={filters.filter_filters?.user || ''}
                placeholder='Elegir usuario ...'
                ariaLabel='user select'
              />
            </FormGroup>
          )}

          <div className='col-md-12 text-end'>
            <Button
              className='mt-4'
              color="storybook"
              isLight
              onClick={() => resetFilters(50)}
            >
              Resetear
            </Button>
          </div>
        </OffCanvasBody>
      </OffCanvas> */}
    </Fragment>
  )
}

export default CategoryFilters;