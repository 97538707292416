import { FC, Fragment, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IngredientForm from "../IngredientForm";
import useFetch from "../../../hooks/useFetch";
import { toast } from 'react-toastify';
import useHandleErrors from "../../../hooks/useHandleErrors";
import { Inventory2 } from "../../../components/icon/material-icons";
import { IngredientService } from "../../../services/ingredients/ingredientService";
import { EditIngredientFieldsModel, Ingredient } from "../../../type/ingredient-type";
import Card, { CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import Page from "../../../layout/Page/Page";

const IngredientEdit: FC = () => {

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { handleErrors } = useHandleErrors();

  const [loading, setLoading] = useState<boolean>(false);

  const [entity] = useFetch(useCallback(async () => {
    const response = await (new IngredientService()).getIngredientById(id as string);
    return response.getResponseData() as Ingredient;
  }, [id]));

  // Function to save entity data
  const handleUpdate = async (values: EditIngredientFieldsModel) => {
    setLoading(true);
    const editData = {
      ingredientId: id,
      ingredientCategoryId: values.ingredientCategoryId,
      name: values.name,
      averagePriceRange: values.averagePriceRange,
      unitMeasureType: values.unitMeasureType,
      visualizationUnitMeasure: values.visualizationUnitMeasure,
      purchaseUnitMeasure: values.purchaseUnitMeasure,
      supplierCodes: values.supplierCodes,
      company: values.company,
    }
    //Update entity information
    try {
      let response = await (await (new IngredientService()).editIngredient(editData)).getResponseData();
      if (response.success) {
        toast.success(response.message);
        navigate(-1);
      } else {
        handleErrors(response);
      }
    } catch (error: any) {
      toast.error('Error al editar ingrediente');
    } finally {
      setLoading(false);
    }
  };

  const getContent = () => {
    if (loading) return <Loader />;

    if (entity !== null) {
      const entityData = {
        ...entity,
        company: entity.company?.id || null,
        ingredientCategoryId: entity.ingredientCategories?.id,
        supplierCodes: entity.ingredientHasSupplierCode?.map((supplier: any) => ({
          code: supplier.code,
          id: supplier.id,
          supplierId: supplier.supplier?.id,
          supplierName: supplier.supplier?.name,
          hasUnits: supplier.hasUnits,
          equivalentQuantity: supplier.equivalentQuantity,
          equivalentUM: supplier.equivalentUM,
        }))
      };

      return (
        <Fragment>
          <CardHeader borderSize={1} className="d-flex justify-content-start">
            <Inventory2 fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
            <CardTitle className="fs-3 mb-0 ms-3">Editar Ingrediente</CardTitle>
          </CardHeader>
          <IngredientForm isLoading={loading} submit={handleUpdate} entityData={entityData} />
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <SubHeader>
        <SubHeaderLeft>
          <Button color="primary" isLink icon="ArrowBack" onClick={() => navigate(-1)} />
        </SubHeaderLeft>
      </SubHeader>
      <Page container="fluid">
        <Card stretch={true} className="col-md-9 m-auto">
          <>{getContent()}</>
        </Card>
      </Page>
    </Fragment>
  );
};

export default IngredientEdit;