import React, { useState } from "react";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Button from "../../components/bootstrap/Button";
import { useFormik } from 'formik';
import Spinner from "../../components/bootstrap/Spinner";
import { Link } from "react-router-dom";
import { RemoveRedEye } from "../../components/icon/material-icons";
import PasswordHide from "../../components/icon/material-icons/PasswordHide";

interface LoginFormProps {
    isLoading: boolean;
    submit: Function;
    errorsBool: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({ isLoading = false, submit, errorsBool }) => {

    const [showPassword, setShowPassword] = useState(false);

    //-----------------------------------
    /**
     *  EN: Form used to login users validation
     *  ES: Formulario usado para el login de usuarios con validación
     */
    //-----------------------------------
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            loginUsername: '',
            loginPassword: '',
        },
        validate: (values) => {
            const errors: { loginUsername?: string; loginPassword?: string } = {};

            if (!values.loginUsername) {
                errors.loginUsername = 'Required';
            }

            if (!values.loginPassword) {
                errors.loginPassword = 'Required';
            }

            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => {
            submit(values.loginUsername, values.loginPassword);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className='row g-4'>
            <div className='col-12'>
                <FormGroup id='loginUsername' isFloating label='Correo electrónico'>
                    <Input
                        autoComplete='username'
                        value={formik.values.loginUsername}
                        isTouched={formik.touched.loginUsername}
                        invalidFeedback={
                            formik.errors.loginUsername
                        }
                        isValid={formik.isValid}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onFocus={() => {
                            formik.setErrors({});
                        }}
                    />
                </FormGroup>
                <div className="d-flex flex-row flex-nowrap mt-3 position-relative gap-2">
                    <FormGroup id='loginPassword' isFloating label='Contraseña' className="w-100">
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            autoComplete='current-password'
                            value={formik.values.loginPassword}
                            isTouched={formik.touched.loginPassword}
                            invalidFeedback={
                                formik.errors.loginPassword
                            }
                            validFeedback='!Correcto!'
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FormGroup>
                    <Button
                        onTouchStart={() => setShowPassword(true)}
                        onMouseDown={() => setShowPassword(true)}
                        onMouseUp={() => setShowPassword(false)}
                        onMouseLeave={() => setShowPassword(false)}
                        isOutline
                        color='primary'
                        id='button-addon2'>
                        {!showPassword ? <RemoveRedEye fontSize={'1.5em'}></RemoveRedEye> : <PasswordHide fontSize={'1.5em'}></PasswordHide>}
                    </Button>
                </div>
            </div>
            <Link to='/forgot-password' className='col-12 text-center text-primary fw-bold'>¿Has olvidado tu contraseña?</Link>
            <div className='col-12'>
                <Button color='dark' className='w-100 py-3' type='submit' isDisable={isLoading}>
                    {isLoading ? <Spinner /> : 'Iniciar Sesión'}
                </Button>
            </div>
        </form>
    )
}