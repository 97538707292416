import React from 'react';
import { CardBody, CardTitle } from '../../../components/bootstrap/Card';
import { FixNumber } from '../../../utils/fixNumber';
import Icon from '../../../components/icon/Icon';

interface IngredientInfoProps {
    data: any;
}

const IngredientInfo: React.FC<IngredientInfoProps> = ({ data }) => {

    const transformMeasureUnit = (unit: string) => {
        switch (unit) {
            case 'kg':
                return 'Kilogramos';
            case 'g':
                return 'Gramos';
            case 'l':
                return 'Litros';
            case 'ml':
                return 'Mililitros';
            case 'u':
                return 'Unidades';
            default:
                return unit;
        }
    }

    return (
        <>
            <CardTitle style={{ marginTop: '11px' }}>Información</CardTitle>

            <CardBody className='px-0 pt-5'>
                <div className="row border rounded mx-3 p-3 fs-5 g-3 d-flex justify-content-between">
                    <h5 className="mt-2">Precios:</h5>
                    <span className="col-md-8">Último precio:</span>
                    <div className='col-md-4 text-end'><span className='py-1 px-2 bg-warning rounded'>{FixNumber(data?.lastPrice, 2)} €</span></div>

                    <span className="col-md-8">Precio medio:</span>
                    <div className='col-md-4 text-end'><span className='py-1 px-2 bg-warning rounded'>{FixNumber(data?.averagePrice, 2)} €</span></div>

                    <span className="col-md-8">Precio medio ponderado:</span>
                    <div className='col-md-4 text-end'><span className='py-1 px-2 bg-warning rounded'>{FixNumber(data?.weightedAveragePrice, 2)} €</span></div>

                    <span className="col-md-8"> Últimas compras:</span>
                    <span className='col-md-4 text-end'>{data?.averagePriceRange}</span>
                </div>

                <div className="row border rounded mx-3 p-3 fs-5 g-3 mt-4">
                    <h5 className="mt-2">General:</h5>
                    <span className='col-md-8'>Categoría:</span>
                    <span className='col-md-4 text-end'>{data?.ingredientCategories?.name}</span>
                    <span className="col-md-8">Unidad de visualización:</span>
                    <span className="col-md-4 text-end">{transformMeasureUnit(data?.visualizationUnitMeasure)}</span>
                    <span className="col-md-8">Unidad de compra:</span>
                    <span className="col-md-4 text-end">{transformMeasureUnit(data?.purchaseUnitMeasure)}</span>
                </div>

                <div className="row border rounded m-3 mt-4 p-3">
                    <h5 className="mt-2">Proveedores:</h5>
                    <div className="row d-flex align-items-end fs-5 mb-2">
                        {data?.ingredientHasSupplierCode?.map((supplier: any) => (
                            <div key={supplier.supplier.id} className="col-md-12 d-flex justify-content-between">
                                <span>{supplier.supplier.name}: <span className='text-muted'>{supplier.code}</span> - {FixNumber(supplier.lastPrice) || 0} €</span>
                                {supplier.hasUnits && <span className='text-end'>1 u. <Icon icon='ArrowRightAlt' /> {supplier.equivalentQuantity} {supplier.equivalentUM}</span>}
                            </div>
                        ))}
                    </div>
                </div>
            </CardBody>
        </>
    );
};

export default IngredientInfo;