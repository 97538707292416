import { Fragment, useCallback, useState } from "react";
import Button from "../../../components/bootstrap/Button";
import Card, { CardHeader, CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import SubHeader, { SubHeaderLeft } from "../../../layout/SubHeader/SubHeader";
import { useNavigate, useParams } from "react-router-dom";
import { EditSaleFieldsModel, SaleApiResponse } from "../../../type/sales-type";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { financialMenu } from "../../../menu";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { SalesService } from "../../../services/sales/salesService";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import SaleForm from "../SaleForm";
import { PointOfSale } from "../../../components/icon/material-icons";

const EditSale = () => {

    const { id = '' } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { handleErrors } = useHandleErrors();
    const service = new SalesService();

    const [loading, setLoading] = useState<boolean>(false);

    const [data] = useFetch(useCallback(async () => {
        const response = await service.getSaleById(id as string);
        return response.getResponseData() as SaleApiResponse;
    }, [id]));

    const handleUpdate = async (values: EditSaleFieldsModel) => {
        setLoading(true);
        try {
            let response = (await service.editSale({ ...values, saleId: id })).getResponseData();
            if (response.success) {
                toast.success(response.message);
                navigate(financialMenu.sales.path, { replace: true });
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            toast.error('Error al editar la venta');
        } finally {
            setLoading(false);
        }
    };

    const getContent = () => {
        if (loading) return <Loader />;

        if (data !== null) {
            const dishData = {
                ...data,
                saleDate: data?.saleDate?.date.split(' ')[0],
                saleDishes: data?.salesHasDishes?.map((dish: any) => {
                    return {
                        id: dish?.id,
                        dishId: dish?.dishes?.id,
                        price: dish?.price,
                        quantity: dish?.quantity,
                    };
                }, []),
                company: null,
            };

            return (
                <Fragment>
                    <CardHeader borderSize={1} className="d-flex justify-content-start">
                        <PointOfSale fontSize={'30px'} color="rgba(0, 0, 0, 0.3)" />
                        <CardTitle className="fs-3 mb-0 ms-3">Editar Venta</CardTitle>
                    </CardHeader>
                    <SaleForm isLoading={loading} submit={handleUpdate} data={dishData} />
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color="primary"
                        isLink
                        icon="ArrowBack"
                        onClick={() => navigate(-1)}
                    />
                </SubHeaderLeft>
            </SubHeader>
            <Page container="xxl">
                <div className="row justify-content-center h-100">
                    <Card stretch={true} className="col-md-7">
                        <>{getContent()}</>
                    </Card>
                </div>
            </Page>
        </Fragment>
    )
}

export default EditSale;