import { useCallback, useEffect, useState } from 'react';
import { userIsSuperAdmin } from '../utils/userIsSuperAdmin';
import { DishService } from '../services/dishes/dishService';

//------------------------------------------------------------------------
/**
* 
* EN: Hook to manage dish ingredients and elaborations fetching and transformation logic.
* ES: Hook para gestionar la lógica de obtención y transformación de los ingredientes y elaboraciones de los platos.
*
*/
//------------------------------------------------------------------------

const useDishIngredients = () => {

    const [dishIngredients, setDishIngredients] = useState<any>([]);
    const [dishElaborations, setDishElaborations] = useState<any>([]);

    const fetchDishIngredients = useCallback(async (companyId?: string) => {
        try {
            if (userIsSuperAdmin() && !companyId) return [];
            const response = await (new DishService()).listDishIngredients((companyId) ? { filter_filters: { companyId: companyId } } : undefined);
            const fetchedData = response.getResponseData() as any;

            if (fetchedData && fetchedData.data?.ingredients) {
                const mappedData = fetchedData.data.ingredients.map((entity: { id: string; name: string; company: { id: string; }; }) => ({
                    value: entity.id,
                    label: entity.name,
                    key: entity.id,
                    companyid: entity.company.id,
                    type: 'ingredient'
                }));
                setDishIngredients(mappedData);
            }
        } catch (error) {
            console.log('Error fetching ingredients:', error);
        }
    }, []);

    const fetchDishElaborations = useCallback(async (companyId?: string) => {
        try {
            if (userIsSuperAdmin() && !companyId) return [];
            const response = await (new DishService()).listDishElaborations((companyId) ? { filter_filters: { companyId: companyId } } : undefined);
            const fetchedData = response.getResponseData() as any;

            if (fetchedData && fetchedData.data?.elaborations) {
                const mappedData = fetchedData.data.elaborations.map((entity: { id: string; name: string; company: { id: string; }; }) => ({
                    value: entity.id,
                    label: entity.name,
                    key: entity.id,
                    companyid: entity.company.id,
                    type: 'elaboration'
                }));
                setDishElaborations(mappedData);
            }
        } catch (error) {
            console.log('Error fetching elaborations:', error);
        }
    }, []);

    useEffect(() => {
        fetchDishIngredients();
        fetchDishElaborations();
    }, []);

    const getDishingredientsList = () => {
        return dishIngredients;
    };

    const getDishElaborationsList = () => {
        return dishElaborations;
    };

    const getDishIngredientsElaborationsList = () => {
        // order by name regardless of uppercase or lowercase
        const orderedList = dishIngredients.concat(dishElaborations);
        orderedList.sort((a: any, b: any) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
            if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
            return 0;
        });
        return orderedList;
    };

    return { dishIngredients, fetchDishIngredients, fetchDishElaborations, getDishingredientsList, getDishElaborationsList, getDishIngredientsElaborationsList };
}

export default useDishIngredients;