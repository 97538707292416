import { Fragment, useCallback, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { CardTitle } from "../../../components/bootstrap/Card";
import Page from "../../../layout/Page/Page";
import Button from "../../../components/bootstrap/Button";
import { handleConfirmationAlert } from "../../../utils/ConfirmationAlert";
import { toast } from "react-toastify";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { dishesMenu } from "../../../menu";
import { Elaboration } from "../../../type/elaboration-type";
import { DishService } from "../../../services/dishes/dishService";
import { Loader } from "../../../components/bootstrap/SpinnerLogo";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import GraphsView from "../../../components/graphs/GraphsView";
import useFilters from "../../../hooks/useFilters";
import moment from "moment";
import GraphFilters from "../../../components/graphs/GraphFilters";
import DishInfo from "../components/DishInfo";

interface IChartsFilters {
    dish_id: string;
    date: {
        from: string;
        to: string;
        mode: string;
    }
    show_by: string;
}

const initialFilters: IChartsFilters = {
    dish_id: "",
    date: {
        from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        mode: "days",
    },
    show_by: 'weightedAveragePrice'
}

const DishView = () => {

    const { id = "", tabURL = "" } = useParams<{ id: string, tabURL: string }>();
    const navigate = useNavigate();
    const { userCan } = useContext(PrivilegeContext);
    const { handleErrors } = useHandleErrors();
    const service = new DishService();

    const { filters, updateFilters, resetFilters } = useFilters(initialFilters, [], 1, 9999999);

    const [data, loading] = useFetch(useCallback(async () => {
        const response = await service.getDishById(id as string);
        return response.getResponseData() as Elaboration;
    }, [id]));

    const [salesData, loadingSales] = useFetch(useCallback(async () => {
        if (filters.filter_filters?.dish_id === "") return;
        const response = await service.getPricesGraph(filters);
        return response.getResponseData();
    }, [filters]));

    const [costsData, loadingCosts] = useFetch(useCallback(async () => {
        if (filters.filter_filters?.dish_id === "") return;
        const response = await service.getCostsGraph(filters);
        return response.getResponseData();
    }, [filters]));

    const _handleDelete = async () => {
        try {
            const response = (await service.deleteDish(id)).getResponseData();
            if (response.success) {
                navigate(-1);
                setTimeout(() => {
                    toast.success("Plato eliminado correctamente");
                }, 100);
            } else {
                handleErrors(response);
            }
        } catch (error: any) {
            handleErrors(error);
        }
    };

    useEffect(() => {
        if (id) updateFilters({ dish_id: id });
    }, [id]);

    if (loading) return <Loader />;
    if (!data) return null;

    return (
        <Fragment>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='primary' isLink icon='ArrowBack' onClick={() => navigate(-1)} />
                    <SubheaderSeparator />
                    <CardTitle className="me-4 fs-4">{data?.name}</CardTitle>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <GraphFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                    {userCan('edit', 'dishes') && <Button color='primary' isLink icon='Edit' onClick={() => navigate(`${dishesMenu.dishes.path}/${id}/edit`)} />}
                    {userCan('edit', 'dishes') && userCan('delete', 'dishes') && <SubheaderSeparator />}
                    {userCan('delete', 'dishes') && (
                        <Button
                            color='primary' isLink icon='Delete'
                            onClick={() => {
                                handleConfirmationAlert({
                                    title: "Eliminar plato",
                                    text: "Esta acción es irreversible. ¿Estás seguro de que quieres eliminar este plato?",
                                    icon: "warning",
                                    onConfirm: _handleDelete
                                })
                            }}
                        />
                    )}
                </SubHeaderRight>
            </SubHeader>

            <Page container='fluid'>
                <div className="row">
                    <div className="col-md-3">
                        <DishInfo data={data} />
                    </div>

                    <div className="col-md-9">
                        <GraphsView data={salesData} loading={loadingSales} title='Ventas' />

                        <GraphsView data={costsData} loading={loadingCosts} title='Costes' className="mt-3" />
                    </div>
                </div>
            </Page>
        </Fragment>
    )
}

export default DishView;